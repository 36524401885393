import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import Table from '../../components/Table/Table'
import TableBody from '../../components/Table/TableBody'
import TableHead from '../../components/Table/TableHead'
import TableCell from '../../components/Table/TableCell'
import Pagination from '../../components/Pagination/Pagination'
import useSorting from '../../services/sorting'
import NoItemsMessage from '../../components/NoItemsMessage/NoItemsMessage'
import { useAppContext } from '../../components/App/AppContext'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import apiService from '../../services/api-service'
import ClearBtn from '../../components/ActionButtons/ClearBtn/ClearBtn'
import Status from '../../components/Status/Status'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  tableContainer: {
    height: (props) => (props.withoutPagination ? 'calc(100% - 8px)' : 'calc(100% - 80px)'),
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
}))

const InputTransfersTable = ({
  account,
  status,
  data,
  page,
  pageCount,
  refreshData,
  handleChangePage,
  ...props
}) => {
  const classes = useStyles(props)

  const {
    language,
    fullScreenModal: { buffer },
  } = useAppContext()

  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')

  const handleDeleteRequest = (requestId) => {
    return apiService
      .delete(`/virtual-matrix/transfer-requests/${requestId}`)
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  const headCells = [
    [
      {
        id: 'name',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.name[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      { id: 'input', numeric: false, label: `${t.input[language]}` },
      { id: 'recipient', numeric: false, label: `${t.recipient[language]}` },
      { id: 'request', numeric: false, label: `${t.request[language]}` },
      { id: 'validFrom', numeric: false, label: `${t.validFrom[language]}` },
      { id: 'validTill', numeric: false, label: `${t.validTill[language]}` },
      { id: 'status', numeric: false, label: `${t.status[language]}` },
      {
        id: 'buttons',
        numeric: false,
        label: <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellRight)} />,
        props: {
          width: 140,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  return (
    <>
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheRequest[language]}
        onOk={handleDeleteRequest.bind(this, buffer?.id)}
        id={`deleteTransferRequest${account}`}
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.root} stickyHeader>
          <TableHead
            headCells={headCells}
            createSortHandler={createSortHandler}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    <Link to={`/input-transfers/${account}/${row.input?.id}`}>
                      {row.transfer_request_event?.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/inputs/${account}/${row.input?.id}`}>{row.input?.name}</Link>
                  </TableCell>
                  <TableCell>{row.recipient?.name}</TableCell>
                  <TableCell>{row.transfer_request?.title}</TableCell>
                  <TableCell>
                    {row.start_time ? format(new Date(row.start_time), 'dd/MM/yyyy HH:mm') : ''}
                  </TableCell>
                  <TableCell>
                    {row.end_time ? format(new Date(row.end_time), 'dd/MM/yyyy HH:mm') : ''}
                  </TableCell>
                  <TableCell>
                    <Status status={row.status} />
                  </TableCell>
                  <TableCell>
                    <div className={classes.buttonTdContainer}>
                      {!!row.active_connections && (
                        <Status
                          status={row.active_connections.toString()}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <ClearBtn />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <NoItemsMessage data={data} message={t.noInputTransfersYet[language]} status={status} />
      </TableContainer>
      {!props.withoutPagination && (
        <Pagination count={pageCount} page={page} onChange={handleChangePage} />
      )}
    </>
  )
}

export default InputTransfersTable
