import { forwardRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'

import DialogContent from '@mui/material/DialogContent'
import { useAppContext } from '../App/AppContext'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    '& .MuiToolbar-root': {
      minHeight: 70,
      paddingLeft: 60,
    },
    '&.MuiPaper-elevation4': {
      boxShadow: 'none',
    },
    borderBottom: '1px solid #232c48',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      backgroundColor: theme.palette.background.default,
      // backgroundImage: theme.palette.layout.main,
      // backgroundImage: 'linear-gradient(to bottom, #374570, #1c2749)',
      '&::-webkit-scrollbar': {
        width: '0.75em',
        height: '0.75em',
        // backgroundColor: '#34426C',
        backgroundColor: 'transparent',
      },
      // width
      '& *::-webkit-scrollbar': {
        width: '0.50em',
        height: '0.75em',
        // backgroundColor: '#34426C',
        backgroundColor: 'transparent',
      },
      // Track
      '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
        // box-shadow: inset 0 0 5px grey
        borderRadius: '5px',
        // backgroundColor: '#34426C',
        backgroundColor: 'inherit',
      },
      // Handle
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        // backgroundColor: '#6565cf',
        borderRadius: '5px',
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
      },
    },
  },
  close: {
    '&.MuiIconButton-root': {
      borderRadius: '5px',
      padding: 5,
    },
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const FullScreenModal = ({ id, children, title }) => {
  const classes = useStyles()

  const { fullScreenModal, closeFullScreenModal } = useAppContext()

  const onClose = () => {
    closeFullScreenModal()
  }

  return (
    <Dialog
      keepMounted={false}
      className={classes.root}
      scroll='paper'
      fullScreen
      open={fullScreenModal.open && fullScreenModal.id === id}
      onClose={onClose}
      TransitionComponent={Transition}
      transitionDuration={400}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.close}
            edge='start'
            color='inherit'
            onClick={onClose}
            aria-label='close'
            size='large'
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h5' className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default FullScreenModal
