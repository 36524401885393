import { useRef, useState } from 'react'
import clsx from 'clsx'

import { useDashboardContext } from '../Dashboard'
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog'
import LinePopper from '../LinePopper/LinePopper'
import { useAppContext } from '../../App/AppContext'
import apiService from '../../../services/api-service'
import './SvgGraph.css'
import { translations as t } from '../../../services/translations'

function SvgGraph({ refreshDashboard }) {
  const { fullScreenModal, openFullScreenModal, language } = useAppContext()

  const { nodes, setNodes, coords, relations } = useDashboardContext()
  const lineRef = useRef(null)
  const [popper, setPopper] = useState(false)
  const [popperContent, setPopperContent] = useState({})

  const hoverLineHandler = (line, event) => {
    lineRef.current = setTimeout(() => {
      setPopperContent({
        x: event.clientX,
        y: event.clientY,
        from: line.start_time,
        till: line.end_time,
      })
      setPopper(true)
    }, 330)
    const nodeUpd = nodes.map((el) => {
      return {
        ...el,
        selected: el.id === line.source || el.id === line.target,
      }
    })
    setNodes(nodeUpd)
  }

  const leaveLineHandler = () => {
    clearTimeout(lineRef.current)
    setPopper(false)
    setPopperContent({})
    const nodeUpd = nodes.map((el) => {
      return {
        ...el,
        selected: false,
      }
    })
    setNodes(nodeUpd)
  }

  const handleDeleteConnection = (buffer) => {
    let targetAccount = nodes.find((el) => el.id === buffer.target).accountId
    let target = buffer.id
    if (target.slice(target.length - 3) === 'ext') {
      target = target.slice(0, target.length - 3)
      targetAccount = nodes.find((el) => el.id === buffer.target).owner.id
    }
    return apiService.delete(`/virtual-matrix/connections/${target}`, targetAccount).then((res) => {
      setPopper(false)
      refreshDashboard()
    })
  }

  return (
    <>
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheConnection[language]}
        onOk={handleDeleteConnection.bind(this, fullScreenModal.buffer)}
        id='deleteConnection'
      />
      <LinePopper show={popper} content={popperContent} />
      <svg className='SvgGraph__root'>
        {relations.map((r) => {
          const coordsSource = coords.current[r.source]
          const coordsTarget = coords.current[r.target]
          if (!coordsSource || !coordsTarget) return null

          const { color, shift } = nodes.find((el) => el.id === r.source)

          const leftSource = coordsSource.leftPoint
          const rightSource = coordsSource.rightPoint
          const leftTarget = coordsTarget.leftPoint
          const rightTarget = coordsTarget.rightPoint

          let start
          let end
          let direction
          const middle1 = { y: leftSource.y }
          const middle2 = { y: leftTarget.y }

          if (leftSource.x - leftTarget.x > 0) {
            direction = 'left'
            start = leftSource
            const distance = leftSource.x - rightTarget.x
            middle1.x = middle2.x = rightTarget.x + distance / 3 + shift * 8
            end = rightTarget
          } else {
            direction = 'right'
            start = rightSource
            const distance = leftTarget.x - rightSource.x
            middle1.x = middle2.x = rightSource.x + (distance / 3) * 2 - shift * 8
            end = leftTarget
          }

          const locked = nodes.find((el) => el.id === r.target).is_locked

          return (
            <g
              key={r.id}
              className='SvgGraph__group'
              onMouseEnter={hoverLineHandler.bind(this, r)}
              onMouseLeave={leaveLineHandler}
            >
              <path
                className={clsx('SvgGraph__line', r.selected && 'Selected')}
                d={`M ${start.x} ${start.y} L ${middle1.x} ${middle1.y} L ${middle2.x} ${middle2.y} L ${end.x} ${end.y}`}
                stroke={color || '#fff'}
              />
              <path
                d={`M ${end.x} ${end.y} l ${direction === 'left' ? 10 : -10} -5 v 10 Z `}
                stroke='none'
                fill={color}
              />
              {!locked && (
                <g
                  onClick={() => openFullScreenModal('deleteConnection', r)}
                  className={clsx('SvgGraph__button', r.showButton && 'ShowButton')}
                >
                  <circle
                    cx={direction === 'left' ? end.x + 11 : end.x - 11}
                    cy={end.y}
                    r='10'
                    stroke={color}
                  />
                  <path
                    d={`M ${direction === 'left' ? end.x + 11 - 4 : end.x - 11 - 4} ${
                      end.y - 4
                    } l 8 8 m 0 -8 l -8 8`}
                    stroke='#ed2d5b'
                  />
                </g>
              )}
            </g>
          )
        })}
      </svg>
    </>
  )
}

export default SvgGraph
