import IconButton from '@mui/material/IconButton'
import CreateIcon from '@mui/icons-material/Create'

function EditBtn(props) {
  return (
    <IconButton color='inherit' {...props} size='large'>
      <CreateIcon />
    </IconButton>
  )
}

export default EditBtn
