import clsx from 'clsx'
import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import Table from '../../components/Table/Table'
import TableBody from '../../components/Table/TableBody'
import TableHead from '../../components/Table/TableHead'
import TableCell from '../../components/Table/TableCell'
import Pagination from '../../components/Pagination/Pagination'
import useSorting from '../../services/sorting'
import AddBtn from '../../components/AddBtn/AddBtn'
import EditBtn from '../../components/ActionButtons/EditBtn/EditBtn'
import NoItemsMessage from '../../components/NoItemsMessage/NoItemsMessage'
import { useAppContext } from '../../components/App/AppContext'
// import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
// import AddTransferRequest from './AddTransferRequest/AddTransferRequest'
// import EditTransferRequest from './OutboundTransferRequest/EditTransferRequest'
// import apiService from '../../services/api-service'
import Status from '../../components/Status/Status'
import ClearBtn from '../../components/ActionButtons/ClearBtn/ClearBtn'
import Connection from '../../components/Connection/Connection'
import { Link } from 'react-router-dom'
import AddConnection from '../../components/AddConnection/AddConnection'
import apiService from '../../services/api-service'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
    buttonTdContainer: {
      display: 'flex',
    },
  },
  tableContainer: {
    height: (props) => (props.withoutPagination ? 'calc(100% - 8px)' : 'calc(100% - 80px)'),
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
}))

const ConnectionsTable = ({
  data,
  status,
  page,
  refreshData,
  pageCount,
  handleChangePage,
  account,
  ...props
}) => {
  const classes = useStyles(props)

  const {
    language,
    openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')

  const handleDeleteConnection = (buffer) => {
    return apiService
      .delete(`/virtual-matrix/connections/${buffer.id}`, account)
      .then((res) => {
        // console.log(res)
        refreshData()
      })
      .catch(console.log)
  }

  const headCells = [
    [
      {
        id: 'source',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.source[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      { id: 'output', numeric: false, label: `${t.output[language]}` },
      { id: 'created', numeric: false, label: `${t.created[language]}` },
      { id: 'validFrom', numeric: false, label: `${t.validFrom[language]}` },
      { id: 'validTill', numeric: false, label: `${t.validTill[language]}` },
      { id: 'status', numeric: false, label: `${t.status[language]}` },
      {
        id: 'add',
        numeric: false,
        label: (
          <AddBtn onClick={openFullScreenModal.bind(this, `addConnection${account}`, account)} />
        ),
        props: {
          align: 'right',
          width: 140,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  return (
    <>
      <AddConnection accountId={account} onOk={refreshData} />
      {/*<EditTransferRequest accountId={account} />*/}
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheConnection[language]}
        onOk={handleDeleteConnection.bind(this, buffer)}
        id={`deleteConnection${account}`}
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.root} stickyHeader>
          <TableHead
            headCells={headCells}
            createSortHandler={createSortHandler}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row) => {
              // const link = row.type === 'input' ? `/inputs/${account}/${row.input.id}` : row.type === 'preset' ? `/presets/${account}/${row.presets.id}`: ''
              const link = row.type === 'input' ? `/inputs/${account}/${row.input.id}` : ''
              return (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    <Connection
                      name={
                        row.input?.name || row.preset?.name || row.transfer?.input?.name || row.url
                      }
                      type={row.type}
                      link={link}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`/outputs/${account}/${row.output.id}`}>{row.output.name}</Link>
                  </TableCell>
                  <TableCell>{format(new Date(row.created_at), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>{format(new Date(row.start_time), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>
                    {row.stop_time
                      ? format(new Date(row.stop_time), 'dd/MM/yyyy HH:mm')
                      : 'permanent'}
                  </TableCell>
                  <TableCell>
                    <Status status={row.status} />
                  </TableCell>
                  <TableCell>
                    {!(row.status === 'disconnected' || row.status === 'rejected') && (
                      <div className={classes.buttonTdContainer}>
                        <EditBtn
                          onClick={() => {}}
                          disabled
                          // onClick={() => openFullScreenModal(`editTransferRequest${account}`, row)}
                        />
                        <ClearBtn
                          onClick={() => openFullScreenModal(`deleteConnection${account}`, row)}
                        />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <NoItemsMessage
          data={data}
          message={t.noInternalConnectionsYet[language]}
          status={status}
        />
      </TableContainer>
      {!props.withoutPagination && (
        <Pagination count={pageCount} page={page} onChange={handleChangePage} />
      )}
    </>
  )
}

export default ConnectionsTable
