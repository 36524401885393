import makeStyles from '@mui/styles/makeStyles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useAppContext } from '../App/AppContext'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    '&.MuiPaper-root': {
      borderRadius: 16,
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      margin: '12px 0',
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
      padding: '0px 24px',
      '&.Mui-expanded': {
        minHeight: 48,
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginLeft: 12,
      color: '#fff',
    },
    '& .MuiAccordionDetails-root': {
      padding: 24,
    },
  },
  heading: {
    fontSize: 18,
    fontWeight: 600,
  },
}))

function MyAccordion({ children }) {
  const { language } = useAppContext()
  const classes = useStyles()

  return (
    <Accordion classes={{ root: classes.root }} TransitionProps={{ timeout: 660 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' className={classes.heading}>
          {t.filters[language]}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default MyAccordion
