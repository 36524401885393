import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import TextFieldMui from '@mui/material/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    '& label.MuiFormLabel-root': {
      color: theme.palette.text.dark,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
    '& .MuiInputBase-multiline': {
      padding: '3px 0 7px',
    },
    '& div': {
      '&:before': {
        borderColor: theme.palette.text.dark,
      },
      '&:after': {
        borderColor: theme.palette.text.dark,
      },
      '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
        '-webkit-text-fill-color': theme.palette.text.primary,
        '-webkit-box-shadow': '0 0 0px 1000px rgba(255, 255, 255, 0.05) inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
    '& .MuiInputBase-inputMultiline': {
      lineHeight: 1.4,
    },
  },
  error: {
    '& div': {
      '&:before': {
        borderColor: theme.palette.error.main,
      },
      '&:after': {
        borderColor: theme.palette.error.main,
      },
      '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
        '-webkit-text-fill-color': theme.palette.error.main,
        '-webkit-box-shadow': '0 0 0px 1000px rgba(255, 0, 0, 0.1) inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
  margin: {
    marginBottom: 8,
  },
}))

export const TextField = (props) => {
  const classes = useStyles()

  return (
    <TextFieldMui
      {...props}
      classes={{ root: classes.margin }}
      className={clsx(classes.root, props.error && classes.error, props.className)}
    />
  )
}

export default TextField
