import { useState } from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import makeStyles from '@mui/styles/makeStyles'

import close from '../../assets/img/close.svg'
import resize from '../../assets/img/resize-arrows.svg'

const useTabsStyles = makeStyles((theme) => ({
  info: {
    position: 'relative',
    height: '100%',
    background: theme.palette.layout.main,
    borderRadius: '12px',
    overflow: 'hidden',

    '&:after': {
      position: 'absolute',
      display: 'block',
      width: 'calc(100% - 0.75em)',
      bottom: '0px',
      height: '20px',
      content: '""',
      background: theme.palette.background.color1,
      borderRadius: '4px',
    },
  },
  info__header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '54px',
    margin: '0',
    padding: '20px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  info__content: {
    display: 'flex',
    alignContent: 'space-between',
    height: 'calc(100% - 54px)',
    padding: '20px 26px',
    overflowX: 'auto',
    borderRadius: '5px',
  },
  additional: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    height: '100%',
    width: '100%',
    transition: '0.5s',
    '&.fullScreen': {
      height: 'calc(100vh - 86px)',
      transition: '0.5s',
    },
    '& .additional__header': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '36px',
      padding: '0 52px',
      paddingRight: '44px',
    },
    '& .additional__handler': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      width: '40px',
      // backgroundColor: theme.palette.background.color2,
      backgroundColor: '#29355A',
      borderRadius: '5px 5px 0 0',
      cursor: 'pointer',
      '& > img': {
        height: '20px',
        width: '20px',
      },
    },
    '& .additional__content': {
      height: '100%',
      // overflowY: 'auto',
      maxHeight: 'calc(100% - 36px)',
      padding: 0,
      paddingLeft: 52,
      borderRadius: '5px 5px 0 0',
      // backgroundColor: theme.palette.background.color2,
      backgroundColor: '#29355A',
    },

    '& .tabLinks': {
      minHeight: 'unset',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    '& .tabLinks__item': {
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      minHeight: 'unset',
      minWidth: 'unset',
      marginRight: '28px',
      padding: '0 20px',
      textTransform: 'unset',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      color: theme.palette.text.primary,
      // backgroundColor: theme.palette.background.default,
      // backgroundColor: '#3E4B73',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      opacity: '1',
      borderRadius: '5px 5px 0 0',
      '&.Mui-selected': {
        backgroundColor: '#29355A',
        // backgroundColor: theme.palette.background.color2,
      },
    },
    '& .tabContent': {
      height: '100%',
      overflow: 'hidden',
      paddingTop: '28px',
      opacity: 1,
      transition: 'opacity 0.5s ease-in-out',
      '&.appear': {
        opacity: 0.2,
        transition: 'opacity 0s ease-in-out',
      },
    },
    '& .tabContent__header': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 26px',
      fontSize: '18px',
      lineHeight: '21px',
      marginBottom: '20px',
    },
  },
}))

const Tabs = ({ data, resizable }) => {
  const classes = useTabsStyles()

  const [tab, setTab] = useState(0)
  const [openTable, handleOpenTable] = useState(false)
  const [newStyle, setStyle] = useState('tabContent')

  const handleChange = (event, newTab) => {
    if (newTab !== tab) {
      setTab(newTab)
      setStyle('tabContent appear')
      setTimeout(() => setStyle('tabContent'), 40)
    }
  }

  return (
    <div className={clsx(classes.additional, openTable && 'fullScreen')}>
      <div className='additional__header'>
        <MuiTabs className='tabLinks' value={tab} onChange={handleChange}>
          {data.map((tabData, idx) => (
            <MuiTab key={idx} label={tabData.label} className='tabLinks__item' />
          ))}
        </MuiTabs>
        {resizable && (
          <div
            className='additional__handler'
            onClick={() => {
              handleOpenTable(!openTable)
            }}
          >
            <img src={openTable ? close : resize} alt='resize-icon' />
          </div>
        )}
      </div>
      <div className='additional__content'>
        {data.map((tabData, idx) => {
          return (
            <div className={newStyle} hidden={tab !== idx} key={idx}>
              {tabData.contentTitle && (
                <Typography className='tabContent__header'>{tabData.contentTitle}</Typography>
              )}
              {tabData.contentBody}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
