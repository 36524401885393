import { useEffect, useState } from 'react'

const usePagination = (fetchDataFn, accountId, initLimit = 10) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState('loading')
  const [error, setError] = useState(null)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(initLimit)
  const [pageCount, setPageCount] = useState(1)

  const getData = (offset) => {
    setStatus('loading')
    fetchDataFn(accountId, offset, limit)
      .then((res) => {
        const pageCount = Math.ceil(res.headers['x-pagination-total'] / limit) || 1
        setPageCount(pageCount)
        setData(res.data)
        setStatus('success')
      })
      .catch((e) => {
        console.log(e)
        setStatus('error')
        setError(e)
      })
  }

  useEffect(() => {
    const offset = (page - 1) * limit
    getData(offset)
    // eslint-disable-next-line
  }, [page])

  const refreshData = () => {
    setPage(1)
    if (page === 1) {
      getData(0)
    }
  }

  const handleChangePage = (_, value) => {
    setPage(value)
  }

  return {
    data,
    setData,
    status,
    error,
    page,
    pageCount,
    setLimit,
    refreshData,
    handleChangePage,
  }
}

export default usePagination
