import makeStyles from '@mui/styles/makeStyles'
import { Link } from 'react-router-dom'
import EditBtn from '../../../components/ActionButtons/EditBtn/EditBtn'
import RefreshBtn from '../../../components/ActionButtons/RefreshBtn/RefreshBtn'
import { useAppContext } from '../../../components/App/AppContext'
import TableRow from '../../../components/DragAndDropTable/TableRow'
import Status from '../../../components/Status/Status'
import TableCellField from '../../../components/Table/TableCellField'
import {
  capitalizeFirstLetter,
  getDeviceAttributesForInputsOutputsForTable,
} from '../../../services/helpers'
import EditInput from './EditInput'

const useStyles = makeStyles((theme) => ({
  row: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'grabbing',
    },
    '& .item': {
      display: 'flex',
      alignItems: 'center',
      height: 60,
      fontSize: 14,
      fontWeight: 500,
      padding: '0 16px',
      '& a': {
        fontSize: 14,
        fontWeight: 500,
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: '#4770bc',
        },
      },
      '&.order': {
        paddingLeft: 30,
        width: 90,
      },
      '&.name': {
        width: '18%',
      },
      '&.type': {
        width: '7%',
      },
      '&.device': {
        width: '9%',
      },
      '&.formatType': {
        width: '9%',
      },
      '&.locationAddress': {
        width: '18%',
        height: '100%',
      },
      '&.status': {
        flexGrow: 1,
      },
      '&.refresh': {
        width: 110,
      },
    },
  },
  doubleFieldSmall: {
    minWidth: '120px',
    height: '100%',
    // '& > div:nth-child(1)': {
    //   margin: '5px 0px',
    // },
    '& div': {
      margin: '5px 0px',
    },
  },
}))

const Row = ({ item, index, contextAccountId, refreshData }) => {
  const classes = useStyles()

  const {
    openFullScreenModal,
    // fullScreenModal: { buffer },
  } = useAppContext()

  const device_attributes = getDeviceAttributesForInputsOutputsForTable(item)

  return (
    <TableRow item={item} index={index} key={item.id}>
      {(isDragging) => (
        <div
          className={classes.row}
          style={{
            boxShadow: isDragging && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            border: isDragging && '1px solid rgba(255,255,255,0.3)',
            backgroundColor: isDragging && '#375792',
          }}
        >
          <div className='item order'>{index + 1}</div>
          <div className='item name'>
            <Link to={`/inputs/${contextAccountId}/${item.id}`}>{item.name}</Link>
          </div>
          <div className='item type'>{item.input_type}</div>
          <div className='item device'>{capitalizeFirstLetter(item?.type)}</div>
          <div className='item formatType'>{item.stream_type}</div>
          <div className='item locationAddress'>
            <div className={classes.doubleFieldSmall}>
              <TableCellField>{item?.location?.name}</TableCellField>
              <TableCellField>{item?.address}</TableCellField>
            </div>
          </div>
          <div className='item locationAddress'>
            <div className={classes.doubleFieldSmall}>
              <TableCellField>{device_attributes.current_bitrate_mbps}</TableCellField>
              <TableCellField>{device_attributes.width_x_height_s_f}</TableCellField>
            </div>
          </div>
          <div className='item status'>
            <Status status={item.status} />
          </div>
          <div className='item refresh' style={{ marginRight: 10, width: '120px !important' }}>
            {item.status !== 'unknown' && item.status !== 'offline' ? (
              <RefreshBtn onClick={() => openFullScreenModal(`reboot${contextAccountId}`, item)} />
            ) : (
              <div style={{ marginRight: 47, width: '100%' }} />
            )}
            <EditBtn
              onClick={openFullScreenModal.bind(this, `editInput${contextAccountId}`, item)}
            />
            <EditInput contextAccountId={contextAccountId} onOk={refreshData} />
          </div>
        </div>
      )}
    </TableRow>
  )
}

export default Row
