export const translations = {
  //STREAM-ONLINE
  dashboard: {
    uk: 'Головна',
    en: 'Dashboard',
  },
  onlinePlayers: {
    uk: 'Онлайн плеєри',
    en: 'Online players',
  },
  onlinePlayer: {
    uk: 'Онлайн плеєр',
    en: 'Online player',
  },
  vodPlayer: {
    uk: 'VOD плеєр',
    en: 'VOD player',
  },
  vodPlayers: {
    uk: 'VOD плеєри',
    en: 'VOD players',
  },
  customBroadcasts: {
    uk: 'Спеціальні трансляції',
    en: 'Custom broadcasts',
  },
  customBroadcastStream: {
    uk: 'Стрім спеціальних трансляцій',
    en: 'Custom Broadcast Stream',
  },
  ad: {
    uk: 'AD',
    en: 'AD',
  },
  adModules: {
    uk: 'AD модулі',
    en: 'AD modules',
  },
  adModule: {
    uk: 'AD модуль',
    en: 'AD module',
  },
  adStatus: {
    uk: 'AD статус',
    en: 'AD status',
  },
  adReport: {
    uk: 'AD звіт',
    en: 'AD report',
  },
  mediaFiles: {
    uk: 'Медіафайли',
    en: 'Media files',
  },
  mediaItem: {
    uk: 'Медіаелемент',
    en: 'Media Item',
  },
  vodMedia: {
    uk: 'VOD медіа',
    en: 'VOD Media',
  },
  streamer: {
    uk: 'Стрімер',
    en: 'Streamer',
  },
  streamers: {
    uk: 'Стрімери',
    en: 'Streamers',
  },
  streamPublishing: {
    uk: 'Публікація стріму',
    en: 'Stream publishing',
  },
  transcoder: {
    uk: 'Транскодер',
    en: 'Transcoder',
  },
  transcoders: {
    uk: 'Транскодери',
    en: 'Transcoders',
  },
  visits: {
    uk: 'Відвідування',
    en: 'Visits',
  },
  visitor: {
    uk: 'Відвідувач',
    en: 'Visitor',
  },
  visitors: {
    uk: 'Відвідувачі',
    en: 'Visitors',
  },
  monitoring: {
    uk: 'Моніторинг',
    en: 'Monitoring',
  },
  traffic: {
    uk: 'Трафік',
    en: 'Traffic',
  },
  liveEvent: {
    uk: 'Онлайн подія',
    en: 'Live event',
  },
  event: {
    uk: 'Подія',
    en: 'Event',
  },
  inactive: {
    uk: 'Неактивний',
    en: 'Inactive',
  },
  player: {
    uk: 'Плеєр',
    en: 'Player',
  },
  players: {
    uk: 'Плеєри',
    en: 'Players',
  },
  settings: {
    uk: 'Налаштування',
    en: 'Settings',
  },
  servers: {
    uk: 'Сервери',
    en: 'Servers',
  },
  logo: {
    uk: 'Лого',
    en: 'Logo',
  },
  logout: {
    uk: 'Вийти',
    en: 'Logout',
  },
  type: {
    uk: 'Тип',
    en: 'Type',
  },
  onlineVisitors: {
    uk: 'Відвідувачі онлайн',
    en: 'Online visitors',
  },
  summary: {
    uk: 'Сумарно',
    en: 'Summary',
  },
  visitsOverTime: {
    uk: 'Відв. за період',
    en: 'Visits over time',
  },
  visitsPerDay: {
    uk: 'Відвідування за день',
    en: 'Visits per day',
  },
  duration: {
    uk: 'Тривалість',
    en: 'Duration',
  },
  averageDuration: {
    uk: 'Середня тривалість',
    en: 'Average duration',
  },
  dateFrom: {
    uk: 'Дата з',
    en: 'Date from',
  },
  dateTill: {
    uk: 'Дата до',
    en: 'Date till',
  },
  period: {
    uk: 'Період',
    en: 'Period',
  },
  week: {
    uk: 'Тиждень',
    en: 'Week',
  },
  month: {
    uk: 'Місяць',
    en: 'Month',
  },
  months6: {
    uk: '6 місяців',
    en: '6 months',
  },
  year: {
    uk: 'Рік',
    en: 'Year',
  },
  date: {
    uk: 'Дата',
    en: 'Date',
  },
  confirm: {
    uk: 'Підтвердити',
    en: 'Confirm',
  },
  customPeriod: {
    uk: 'Нестандартний',
    en: 'Custom period',
  },
  mediaItems: {
    uk: 'Медіаелементи',
    en: 'Media items',
  },
  filters: {
    uk: 'Фільтри',
    en: 'Filters',
  },
  apply: {
    uk: 'Застосувати',
    en: 'Apply',
  },
  reset: {
    uk: 'Скинути',
    en: 'Reset',
  },
  title: {
    uk: 'Назва',
    en: 'Title',
  },
  total: {
    uk: 'Всього',
    en: 'Total',
  },
  status: {
    uk: 'Статус',
    en: 'Status',
  },
  stream: {
    uk: 'Стрім',
    en: 'Stream',
  },
  streams: {
    uk: 'Стріми',
    en: 'Streams',
  },
  image: {
    uk: 'Зображення',
    en: 'Image',
  },
  images: {
    uk: 'Зображення',
    en: 'Images',
  },
  video: {
    uk: 'Відео',
    en: 'Video',
  },
  geoBan: {
    uk: 'Гео блокування',
    en: 'Geo ban',
  },
  blackIP: {
    uk: 'Заборонений ІР',
    en: 'Black IP',
  },
  viewerBan: {
    uk: 'Бан глядача',
    en: 'Viewer ban',
  },
  reqLogin: {
    uk: 'Обов. логін',
    en: 'Req. login',
  },
  optLogin: {
    uk: 'Опц. логін',
    en: 'Opt. login',
  },
  poster: {
    uk: 'Постер',
    en: 'Poster',
  },
  editPlayer: {
    uk: 'Редагувати плеєр',
    en: 'Edit player',
  },
  active: {
    uk: 'Активний',
    en: 'Active',
  },
  online: {
    uk: 'онлайн',
    en: 'online',
  },
  awaiting: {
    uk: 'В очікуванні',
    en: 'Awaiting',
  },
  pending: {
    uk: 'Призупинено',
    en: 'Pending',
  },
  offline: {
    uk: 'Офлайн',
    en: 'Offline',
  },
  new: {
    uk: 'Новий',
    en: 'New',
  },
  canceled: {
    uk: 'Скасовано',
    en: 'Canceled',
  },
  rejected: {
    uk: 'Відхилено',
    en: 'Rejected',
  },
  expired: {
    uk: 'Закінчився',
    en: 'Expired',
  },
  banned: {
    uk: 'Заборонений',
    en: 'Banned',
  },
  closed: {
    uk: 'Зачинено',
    en: 'Closed',
  },
  close: {
    uk: 'Закрити',
    en: 'Close',
  },
  registeredFrom: {
    uk: 'Зареєстровано з',
    en: 'Registered from',
  },
  registeredTill: {
    uk: 'Зареєстровано до',
    en: 'Registered till',
  },
  activeOnly: {
    uk: 'Тільки активні',
    en: 'Active only',
  },
  passwords: {
    uk: 'Паролі',
    en: 'Passwords',
  },
  backpad: {
    uk: 'Фон',
    en: 'Backpad',
  },
  currentEvent: {
    uk: 'Поточна подія',
    en: 'Current event',
  },
  accessType: {
    uk: 'Тип доступу',
    en: 'Access type',
  },
  rotation: {
    uk: 'Ротації',
    en: 'Rotation',
  },
  mediaImages: {
    uk: 'Медіа/Зображення',
    en: 'Media/Images',
  },
  scheduledEvents: {
    uk: 'Заплановані події',
    en: 'Scheduled events',
  },
  actualEvents: {
    uk: 'Актуальні події',
    en: 'Actual events',
  },
  editEvent: {
    uk: 'Редагувати подію',
    en: 'Edit event',
  },
  newEvent: {
    uk: 'Нова подія',
    en: 'New event',
  },
  index: {
    uk: 'Індекс',
    en: 'Index',
  },
  from: {
    uk: 'Від',
    en: 'From',
  },
  till: {
    uk: 'До',
    en: 'Till',
  },
  state: {
    uk: 'Стан',
    en: 'State',
  },
  media: {
    uk: 'Медіа',
    en: 'Media',
  },
  warning: {
    uk: 'Увага!',
    en: 'Warning!',
  },
  areYouSureYouWantToRemoveTheMedia: {
    uk: 'Ви впевнені, що хочете видалити медіа?',
    en: 'Are you sure you want to remove the media?',
  },
  areYouSureYouWantToRemoveTheStream: {
    uk: 'Ви впевнені, що хочете видалити стрім?',
    en: 'Are you sure you want to remove the stream?',
  },
  broadcastAlreadyHas5activeStreams: {
    uk: 'У трансляції вже 5 активних стрімів!',
    en: 'Broadcast already has 5 active streams!',
  },
  areYouSureYouWantToRemoveTheEvent: {
    uk: 'Ви впевнені, що хочете видалити подію?',
    en: 'Are you sure you want to remove the event?!',
  },
  areYouSureYouWantToChangeStatus: {
    uk: 'Ви впевнені, що хочете змінити статус?',
    en: 'Are you sure you want to change status?',
  },
  areYouSureYouWantToUpdateTheSettings: {
    uk: 'Ви впевнені, що бажаєте оновити налаштування?',
    en: 'Are you sure you want to update the settings?',
  },
  areYouSureYouWantToDeleteTheSettings: {
    uk: 'Ви впевнені, що хочете видалити налаштування?',
    en: 'Are you sure you want to delete the settings?',
  },
  areYouSureYouWantToDeleteTheServer: {
    uk: 'Ви впевнені, що хочете видалити сервер?',
    en: 'Are you sure you want to delete the server?',
  },
  areYouSureYouWantToEnableTheServer: {
    uk: 'Ви впевнені, що хочете ввімкнути сервер?',
    en: 'Are you sure you want to enable the server?',
  },
  areYouSureYouWantToDisableTheServer: {
    uk: 'Ви впевнені, що бажаєте вимкнути сервер?',
    en: 'Are you sure you want to disable the server?',
  },
  areYouSureYouWantToApplyTheNewDeviceURL: {
    uk: 'Ви впевнені, що бажаєте застосувати нову URL-адресу пристрою?',
    en: 'Are you sure you want to apply the new device URL?',
  },
  noStreamsYet: {
    uk: 'Стріми відсутні',
    en: 'No streams',
  },
  noFilesYet: {
    uk: 'Файли відсутні',
    en: 'No files',
  },
  noPlayersYet: {
    uk: 'Плеєри відсутні',
    en: 'No players',
  },
  noVisitorsYet: {
    uk: 'Відвідувачі відсутні',
    en: 'No visitors',
  },
  noTranscodersYet: {
    uk: 'Транскодери відсутні',
    en: 'No transcoders',
  },
  noStreamersYet: {
    uk: 'Стримери відсутні',
    en: 'No streamers',
  },
  noIpAddressesYet: {
    uk: 'IP-адреси відсутні',
    en: 'No IP addresses',
  },
  noMediaYet: {
    uk: 'Медіа відсутні',
    en: 'No media',
  },
  noStatsYet: {
    uk: 'Статистика відсутня',
    en: 'No stats',
  },
  noItemsYet: {
    uk: 'Елементи відсутні',
    en: 'No items',
  },
  noEventsYet: {
    uk: 'Події відсутні',
    en: 'No events',
  },
  noAdYet: {
    uk: 'Реклама відсутня',
    en: 'No AD',
  },
  noAdModulesYet: {
    uk: 'Рекламні модулі відсутні',
    en: 'No AD modules',
  },
  noCustomBroadcastsYet: {
    uk: 'Спеціальні трансляції відсутні',
    en: 'No custom broadcasts',
  },
  created: {
    uk: 'Створено',
    en: 'Created',
  },
  preview: {
    uk: 'Прев’ю',
    en: 'Preview',
  },
  username: {
    uk: "Ім'я користувача",
    en: 'Username',
  },
  notes: {
    uk: 'Примітки',
    en: 'Notes',
  },
  registrationDate: {
    uk: 'Дата Реєстрації',
    en: 'Registration date',
  },
  keepOriginal: {
    uk: 'Зберегти оригінал',
    en: 'Keep original!',
  },
  selectVodMedia: {
    uk: 'Виберіть VOD медіа',
    en: 'Select VOD media',
  },
  newItem: {
    uk: 'Новий елемент',
    en: 'New item',
  },
  edit: {
    uk: 'Редагувати',
    en: 'Edit',
  },
  editItem: {
    uk: 'Редагувати елемент',
    en: 'Edit item',
  },
  editMedia: {
    uk: 'Редагувати медіа елемент',
    en: 'Edit media',
  },
  newMedia: {
    uk: 'Новий медіа елемент',
    en: 'New media item',
  },
  createdFrom: {
    uk: 'Створено з',
    en: 'Created from',
  },
  createdTill: {
    uk: 'Створено до',
    en: 'Created till',
  },
  error: {
    uk: 'Помилка',
    en: 'Error',
  },
  completed: {
    uk: 'Завершено',
    en: 'Completed',
  },
  chooseAtype: {
    uk: 'Виберіть тип',
    en: 'Choose a type',
  },
  typeIpAddress: {
    uk: 'Введіть IP-адресу',
    en: 'Type IP address',
  },
  url: {
    uk: 'Посилання',
    en: 'Url',
  },
  logoImage: {
    uk: 'Зображення лого',
    en: 'Logo image',
  },
  editCustomBroadcastStream: {
    uk: 'Редагувати cтрім спеціальних трансляцій',
    en: 'Edit custom broadcast stream',
  },
  input: {
    uk: 'Вхід',
    en: 'Input',
  },
  externalInput: {
    uk: 'Зовнішній вхід',
    en: 'External input',
  },
  preset: {
    uk: 'Пресет',
    en: 'Preset',
  },
  link: {
    uk: 'Посилання',
    en: 'Link',
  },
  channel: {
    uk: 'Канал',
    en: 'Channel',
  },
  free: {
    uk: 'Вільний',
    en: 'Free',
  },
  singlePass: {
    uk: 'Разовий пропуск', //unused
    en: 'Single pass',
  },
  personalPassword: {
    uk: 'Персональний пароль',
    en: 'Personal password',
  },
  multiPass: {
    uk: 'Груповий пароль',
    en: 'Group password',
  },
  optionalReg: {
    uk: "Необов'язкова авторизація",
    en: 'Optional reg',
  },
  requiredReg: {
    uk: "Обов'язкова авторизація",
    en: 'Required auth',
  },
  requiredField: {
    uk: "Обов'язкове поле",
    en: 'Required field',
  },
  fieldIsRequired: {
    uk: "Поле обов'язкове",
    en: 'Field is required',
  },
  availableSites: {
    uk: 'Доступні сайти',
    en: 'Available sites',
  },
  actualOnly: {
    uk: 'Тільки актуальні',
    en: 'Actual only',
  },
  description: {
    uk: 'Опис',
    en: 'Description',
  },
  externalService: {
    uk: 'Зовнішня служба',
    en: 'External service',
  },
  externalServiceLink: {
    uk: 'Посилання на зовнішню службу',
    en: 'External service link',
  },
  preRollQuantity: {
    uk: 'Pre-roll кількість',
    en: 'Pre-roll quantity',
  },
  midRollQuantity: {
    uk: 'Mid-roll кількість',
    en: 'Mid-roll quantity',
  },
  externalLink: {
    uk: 'Зовнішнє посилання',
    en: 'External link',
  },
  midRollIntervalSec: {
    uk: 'Mid-roll інтервал (сек)',
    en: 'Mid-roll interval (sec)',
  },
  rotationIndex: {
    uk: 'Індекс ротації',
    en: 'Rotation index',
  },
  rollType: {
    uk: 'Тип реклами',
    en: 'Roll type',
  },
  skipDuration: {
    uk: 'Skip інтервал (сек)',
    en: 'Skip interval (sec)',
  },
  expireIn: {
    uk: 'Обмеження кількості (не підтримується)',
    en: 'Expire qty (unsupported)',
  },
  expireQtyUnsupported: {
    uk: 'Обмеження кількості (не підтримується)',
    en: 'Expire qty (unsupported)',
  },
  adTitle: {
    uk: 'Промо назва',
    en: 'Promo title',
  },
  adLink: {
    uk: 'Промо посилання',
    en: 'Promo link',
  },
  group: {
    uk: 'Група',
    en: 'Group',
  },
  source: {
    uk: 'Джерело',
    en: 'Source',
  },
  sourceType: {
    uk: 'Тип джерела',
    en: 'Source type',
  },
  onlineBandwidth: {
    uk: 'Пропускна здатність онлайн',
    en: 'Online bandwidth',
  },
  vodBandwidth: {
    uk: 'Пропускна здатність VOD',
    en: 'VOD bandwidth',
  },
  region: {
    uk: 'Регіон',
    en: 'Region',
  },
  selectBroadcastType: {
    uk: 'Виберіть тип трансляції',
    en: 'Select broadcast type',
  },
  cautionThisProcessMayTakeUpTo2Minutes: {
    uk: 'Увага: цей процес може тривати до 2 хвилин.',
    en: 'Caution: This process may take up to 2 minutes.',
  },
  quality: {
    uk: 'Якість',
    en: 'Quality',
  },
  sort: {
    uk: 'Сортувати',
    en: 'Sort',
  },
  skip_count: {
    uk: 'Пропущено',
    en: 'Skip',
  },
  play_count_: {
    uk: 'Показано',
    en: 'Play',
  },
  click_count_: {
    uk: 'Переходи',
    en: 'Click',
  },
  today: {
    uk: 'Сьогодні',
    en: 'Today',
  },
  yesterday: {
    uk: 'Вчора',
    en: 'Yesterday',
  },
  weeks2: {
    uk: '2 тижні',
    en: '2 Weeks',
  },
  year1: {
    uk: '1 рік',
    en: '1 Year',
  },
  periodFrom: {
    uk: 'Період від',
    en: 'Period from',
  },
  periodTill: {
    uk: 'Період до',
    en: 'Period till',
  },
  preRoll: {
    uk: 'Pre-roll',
    en: 'Pre-roll',
  },
  midRoll: {
    uk: 'Mid-roll',
    en: 'Mid-roll',
  },
  uploadDateStart: {
    uk: 'Дата початку завантаження',
    en: 'Upload date start',
  },
  uploadDateEnd: {
    uk: 'Дата закінчення завантаження',
    en: 'Upload date end',
  },
  uploadDate: {
    uk: 'Дата завантаження',
    en: 'Upload Date',
  },
  size: {
    uk: 'Розмір',
    en: 'Size',
  },
  areYouSureYouWantToRemoveTheFile: {
    uk: 'Ви впевнені, що хочете видалити файл?',
    en: 'Are you sure you want to remove the file?',
  },
  regions: {
    uk: 'Регіони',
    en: 'Regions',
  },
  days2: {
    uk: '2 дні',
    en: '2 days',
  },
  days1: {
    uk: '1 день',
    en: '1 day',
  },
  hours12: {
    uk: '12 годин',
    en: '12 hours',
  },
  hours3: {
    uk: '3 години',
    en: '3 hours',
  },
  mainOnly: {
    uk: 'Тільки основні',
    en: 'Main only',
  },
  visitorCard: {
    uk: 'Картка відвідувача',
    en: 'Visitor card',
  },
  bannedIpAddresses: {
    uk: 'Заборонені IP-адреси',
    en: 'Banned IP addresses',
  },
  banIpAddress: {
    uk: 'Заборонити IP-адресу',
    en: 'Ban IP address',
  },
  qualities: {
    uk: 'Якості',
    en: 'Qualities',
  },
  edgeStreamer: {
    uk: 'Edge стрімер',
    en: 'Edge streamer',
  },
  requiredLogin: {
    uk: "Обов'язкова авт.",
    en: 'Required auth',
  },
  optionalLogin: {
    uk: "Необов'язкова авт.",
    en: 'Optional auth',
  },
  mediaFile: {
    uk: 'Медіафайл',
    en: 'Media file',
  },
  deviceType: {
    uk: 'Тип пристрою',
    en: 'Device type',
  },
  osVersion: {
    uk: 'ОС версія',
    en: 'OS version',
  },
  browser: {
    uk: 'Браузер',
    en: 'Browser',
  },
  playList: {
    uk: 'Плейлист',
    en: 'Playlist',
  },
  blackList: {
    uk: 'Заборонені ІР',
    en: 'Black list',
  },
  whiteList: {
    uk: 'Дозволені ІР',
    en: 'White list',
  },
  ip: {
    uk: 'IP',
    en: 'IP',
  },
  editTranscoder: {
    uk: 'Редагувати транскодер',
    en: 'Edit transcoder',
  },
  editCustomBroadcast: {
    uk: 'Редагувати спеціальну трансляцію',
    en: 'Edit custom broadcast',
  },
  editAD: {
    uk: 'Редагувати AD',
    en: 'Edit AD',
  },
  editAdUnit: {
    uk: 'Редагувати AD блок',
    en: 'Edit AD unit',
  },
  newAD: {
    uk: 'Новий AD',
    en: 'New AD',
  },
  newAdUnit: {
    uk: 'Новий AD блок',
    en: 'New AD unit',
  },
  editIpAddress: {
    uk: 'Редагувати IP-адрес',
    en: 'Edit IP address',
  },
  addIpAddress: {
    uk: 'Додати IP-адрес',
    en: 'Add IP address',
  },
  ipAddress: {
    uk: 'IP-адрес',
    en: 'IP address',
  },
  areYouSureYouWantToRemoveTheIAddress: {
    uk: 'Ви впевнені, що хочете видалити IP-адресу?',
    en: 'Are you sure you want to remove the IP address?',
  },
  areYouSureYouWantToRemoveTheModule: {
    uk: 'Ви впевнені, що хочете видалити модуль?',
    en: 'Are you sure you want to remove the module?',
  },
  areYouSureYouWantToRemoveTheAD: {
    uk: 'Ви впевнені, що хочете видалити оголошення?',
    en: 'Are you sure you want to remove the AD?',
  },
  areYouSureYouWantToRemoveTheRequest: {
    uk: 'Ви впевнені, що хочете видалити запит?',
    en: 'Are you sure you want to remove the request?',
  },
  areYouSureYouWantToPlayTheEvent: {
    uk: 'Ви впевнені, що хочете відтворити подію?',
    en: 'Are you sure you want to play the event?',
  },
  areYouSureYouWantToRemoveTheMediaItem: {
    uk: 'Ви впевнені, що хочете видалити мультимедійний елемент?',
    en: 'Are you sure you want to remove the media item?',
  },
  areYouSureYouWantToStartTheTranscoder: {
    uk: 'Ви впевнені, що хочете запустити транскодер?',
    en: 'Are you sure you want to start the transcoder?',
  },
  areYouSureYouWantToRestartTheTranscoder: {
    uk: 'Ви впевнені, що хочете перезапустити транскодер?',
    en: 'Are you sure you want to restart the transcoder?',
  },
  areYouSureYouWantToStopTheTranscoder: {
    uk: 'Ви впевнені, що хочете зупинити транскодер?',
    en: 'Are you sure you want to stop the transcoder?',
  },
  areYouSureYouWantToStopTheEvent: {
    uk: 'Ви впевнені, що хочете зупинити подію?',
    en: 'Are you sure you want to stop the event?',
  },
  areYouSureYouWantToRemoveTheAddressFromBanList: {
    uk: 'Ви впевнені, що хочете видалити адресу зі списку заборонених?',
    en: 'Are you sure you want to remove the address from ban list?',
  },
  areYouSureYouWantToCancelBanTheVisitor: {
    uk: 'Ви впевнені, що хочете скасувати бан відвідувача?',
    en: 'Are you sure you want to cancel ban the visitor?',
  },
  areYouSureYouWantToBanTheVisitor: {
    uk: 'Ви впевнені, що хочете заблокувати відвідувача?',
    en: 'Are you sure you want to ban the visitor?',
  },
  adList: {
    uk: 'AD список',
    en: 'AD list',
  },
  adUnit: {
    uk: 'AD блок',
    en: 'AD unit',
  },
  newAdModule: {
    uk: 'Новий AD модуль',
    en: 'New AD module',
  },
  clickToSelect: {
    uk: 'Натисніть, щоб обрати ...',
    en: 'Click to select ...',
  },
  playlistMedia: {
    uk: 'Плейлист відтворення',
    en: 'Playlist media',
  },
  noOptions: {
    uk: 'Варіанти відсутні',
    en: 'No options',
  },
  VOD: {
    uk: 'VOD',
    en: 'VOD',
  },
  rotationType: {
    uk: 'Тип ротації',
    en: 'Rotation type',
  },
  optional: {
    uk: 'Опціональний',
    en: 'Optional',
  },
  requiredStart: {
    uk: "Обов'язковий (початок)",
    en: 'Required (start)',
  },
  requiredEnd: {
    uk: "Обов'язковий (кінець)",
    en: 'Required (end)',
  },
  //VIRTUAL-MATRIX
  inputs: {
    uk: 'Входи',
    en: 'Inputs',
  },
  externalInputs: {
    uk: 'Зовнішні входи',
    en: 'External inputs',
  },
  inputTransfers: {
    uk: 'Трансфери входу',
    en: 'Input transfers',
  },
  outputs: {
    uk: 'Виходи',
    en: 'Outputs',
  },
  presets: {
    uk: 'Пресети',
    en: 'Presets',
  },
  availableChannels: {
    uk: 'Доступні канали',
    en: 'Available channels',
  },
  internalConnections: {
    uk: "Внутрішні з'єднання",
    en: 'Internal connections',
  },
  externalConnections: {
    uk: "Зовнішні з'єднання",
    en: 'External connections',
  },
  inboundTransferRequests: {
    uk: 'Вхідні запити на трансфер',
    en: 'Inbound transfer requests',
  },
  noInputTransfersYet: {
    uk: 'Трансфери входу відсутні',
    en: 'No input transfers',
  },
  lock: {
    uk: 'Заблокувати',
    en: 'Lock',
  },
  unLock: {
    uk: 'Розблокувати',
    en: 'Unlock',
  },
  locked: {
    uk: 'Заблоковано',
    en: 'Locked',
  },
  unLocked: {
    uk: 'Розблоковано',
    en: 'Unlocked',
  },
  noExternalInputsYet: {
    uk: 'Зовнішні входи відсутні',
    en: 'No external inputs yet',
  },
  noAvailableChannelsYet: {
    uk: 'Доступні канали відсутні',
    en: 'No available channels',
  },
  NoExternalConnectionsYet: {
    uk: "Зовнішні з'єднання відсутні",
    en: 'No external connections',
  },
  selectInput: {
    uk: 'Виберіть вхід',
    en: 'Select input',
  },
  eventStart: {
    uk: 'Початок заходу',
    en: 'Event start',
  },
  newPreset: {
    uk: 'Новий пресет',
    en: 'New preset',
  },
  ok: {
    uk: 'Так',
    en: 'Ok',
  },
  permanent: {
    uk: 'Постійний',
    en: 'Permanent',
  },
  save: {
    uk: 'Зберегти',
    en: 'Save',
  },
  byCompany: {
    uk: 'За компанією',
    en: 'By company',
  },
  location: {
    uk: 'Розташування',
    en: 'Location',
  },
  address: {
    uk: 'Адреса',
    en: 'Address',
  },
  byLocation: {
    uk: 'За розташуванням',
    en: 'By location',
  },
  externalOutputs: {
    uk: 'Зовнішні виходи',
    en: 'External outputs',
  },
  request: {
    uk: 'Запит',
    en: 'Request',
  },
  request_: {
    uk: 'Заявка',
    en: 'Request',
  },
  owner: {
    uk: 'Власник',
    en: 'Owner',
  },
  validFrom: {
    uk: 'Дійсний з',
    en: 'Valid from',
  },
  validTill: {
    uk: 'Дійсний до',
    en: 'Valid till',
  },
  formatType: {
    uk: 'Тип формату',
    en: 'Format type',
  },
  bitrate: {
    uk: 'Бітрейт',
    en: 'Bitrate',
  },
  codec: {
    uk: 'Кодек',
    en: 'Codec',
  },
  resolution: {
    uk: 'Роздільна здатність',
    en: 'Resolution',
  },
  resolutionShort: {
    uk: 'Розд. здатність',
    en: 'Resolution',
  },
  currentBitrateMbps: {
    uk: 'Поточний бітрейт (Мбіт/с)',
    en: 'Current bitrate (mbps)',
  },
  height: {
    uk: 'Висота',
    en: 'Height',
  },
  width: {
    uk: 'Ширина',
    en: 'Width',
  },
  scan: {
    uk: 'Розгортка',
    en: 'Scan',
  },
  frameRate: {
    uk: 'Частота кадрів',
    en: 'Frame rate',
  },
  currentDeviceInfo: {
    uk: 'Поточна інформація про пристрій',
    en: 'Current device info',
  },
  createConnection: {
    uk: "Створити з'єднання",
    en: 'Create connection',
  },
  closeConnections: {
    uk: "Закрити з'єднання",
    en: 'Close connections',
  },
  recipient: {
    uk: 'Одержувач',
    en: 'Recipient',
  },
  recipients: {
    uk: 'Одержувачі',
    en: 'Recipients',
  },
  all: {
    uk: 'Всі',
    en: 'All',
  },
  start: {
    uk: 'Початок',
    en: 'Start',
  },
  end: {
    uk: 'Закінчення',
    en: 'End',
  },
  presents: {
    uk: 'Предустановки',
    en: 'Presents',
  },
  closeConnection: {
    uk: "Закрити з'єднання",
    en: 'Close connection',
  },
  transferRequest: {
    uk: 'Заявка на трансфер',
    en: 'Transfer request',
  },
  connections: {
    uk: "З'єднання",
    en: 'Connections',
  },
  excludedRecipients: {
    uk: 'Виключені одержувачі',
    en: 'Excluded recipients ',
  },
  selected: {
    uk: 'Обране',
    en: 'Selected',
  },
  selected_o: {
    uk: 'Обрано',
    en: 'Selected',
  },
  selectedRecipients: {
    uk: 'Обрані одержувачі',
    en: 'Selected recipients',
  },
  permanentTransfer: {
    uk: 'Постійний трансфер',
    en: 'Permanent transfer',
  },
  limitedTime: {
    uk: 'Обмежений час',
    en: 'Limited time',
  },
  eventEnd: {
    uk: 'Закінчення події',
    en: 'Event End',
  },
  addConnection: {
    uk: "Створити з'єднання",
    en: 'Add connection',
  },
  //IP-DELIVERY
  disconnected: {
    uk: 'Відключений',
    en: 'Disconnected',
  },
  disconnected_: {
    uk: 'Відключено',
    en: 'Disconnected',
  },
  freeCost: {
    uk: 'Безкоштовно',
    en: 'Free',
  },
  channels: {
    uk: 'Канали',
    en: 'Channels',
  },
  providers: {
    uk: 'Провайдери',
    en: 'Providers',
  },
  provider: {
    uk: 'Провайдер',
    en: 'Provider',
  },
  deliveryRequests: {
    uk: 'Заявки на доставку',
    en: 'Delivery Requests',
  },
  commonIssues: {
    uk: 'Інші заявки',
    en: 'Common Issues',
  },
  history: {
    uk: 'Історія',
    en: 'History',
  },
  connectedChannels: {
    uk: 'Підключені канали',
    en: 'Connected channels',
  },
  rightsholder: {
    uk: 'Правовласник',
    en: 'Rightsholder',
  },
  rightsholders: {
    uk: 'Правовласники',
    en: 'Rightsholders',
  },
  enterAs: {
    uk: 'Увійдіть як',
    en: 'Enter as',
  },
  addProvider: {
    uk: 'Додати провайдера',
    en: 'Add provider',
  },
  specs: {
    uk: 'Характеристики',
    en: 'Specs',
  },
  scheduledEnd: {
    uk: 'Заплановане завершення',
    en: 'Scheduled end',
  },
  scheduledStart: {
    uk: 'Запланований старт',
    en: 'Scheduled start',
  },
  subscribers: {
    uk: 'Підписників',
    en: 'Subscribers',
  },
  deliveryUntil: {
    uk: 'Доставка до',
    en: 'Delivery until',
  },
  broadcastLanguages: {
    uk: 'Мови трансляції',
    en: 'Broadcast languages',
  },
  allChannels: {
    uk: 'Всі канали',
    en: 'All channels',
  },
  message: {
    uk: 'Повідомлення',
    en: 'Message',
  },
  initiator: {
    uk: 'Ініціатор',
    en: 'Initiator',
  },
  country: {
    uk: 'Країна',
    en: 'Country',
  },
  website: {
    uk: 'Веб-сайт',
    en: 'Website',
  },
  participant: {
    uk: 'Учасник',
    en: 'Participant',
  },
  updated: {
    uk: 'Змінено',
    en: 'Updated',
  },
  channelDelivery: {
    uk: 'Доставка каналу',
    en: 'Channel delivery',
  },
  pleaseUseFilters: {
    uk: 'Будь ласка, використовуйте фільтри для отримання даних по графікам моніторингу.',
    en: 'Please use filters to get data for monitoring charts.',
  },
  deliveryRequest: {
    uk: 'Запит на доставку',
    en: 'Delivery request!',
  },
  yourDeliveryRequestWasSuccessfullySent: {
    uk:
      'Вашу заявку на доставку успішно відправлено. Дані будуть оновлені після того, як заявка буде розглянута та виконана.',
    en:
      'Your delivery request was successfully sent. The data will be updated after your request has been reviewed and fulfilled.',
  },
  channelVarieties: {
    uk: 'Різновиди каналу',
    en: 'Channel varieties',
  },
  add: {
    uk: 'Додати',
    en: 'Add',
  },
  sorryYouCurrentlyDoNotHavePermissionToAccessThisService: {
    uk: 'На жаль, наразі ви не маєте дозволу на доступ до цього сервісу.',
    en: 'Sorry, you currently do not have permission to access this service.',
  },
  newIssue: {
    uk: 'Нова заявка',
    en: 'New issue',
  },
  //BUTTONS
  applyCapitalized: {
    uk: 'ЗАСТОСУВАТИ',
    en: 'APPLY',
  },
  clear: {
    uk: 'Очистити',
    en: 'Clear all',
  },
  editChannel: {
    uk: 'Редагувати канал',
    en: 'Edit channel',
  },
  newChannel: {
    uk: 'Новий канал',
    en: 'New channel',
  },
  //LABELS
  sortBy: {
    uk: 'Сортувати за',
    en: 'Sort by',
  },
  channelsQtyFrom: {
    uk: 'Кількість каналів від',
    en: 'Channels qty from',
  },
  channelsQtyUpTo: {
    uk: 'Кількість каналів до',
    en: 'Channels qty up to',
  },
  channelName: {
    uk: 'Назва каналу',
    en: 'Channel name',
  },
  tags: {
    uk: 'Теги',
    en: 'Tags',
  },
  services: {
    uk: 'Сервіси',
    en: 'Services',
  },
  technologies: {
    uk: 'Технології',
    en: 'Technologies',
  },
  providerName: {
    uk: 'Назва провайдера',
    en: 'Provider name',
  },
  broadcastRegion: {
    uk: 'Регіон трансляції',
    en: 'Broadcast region',
  },
  broadcastType: {
    uk: 'Тип трансляції',
    en: 'Broadcast type',
  },
  deliveryType: {
    uk: 'Тип доставки',
    en: 'Delivery type',
  },
  rightsholderName: {
    uk: 'Назва правовласника',
    en: 'Rightsholder name',
  },
  languages: {
    uk: 'Мови',
    en: 'Languages',
  },
  minimalWarranty: {
    uk: 'Мінімальна гарантія',
    en: 'Minimal warranty',
  },
  bidding: {
    uk: 'Торги',
    en: 'Bidding',
  },
  channelOwner: {
    uk: 'Власник каналу',
    en: 'Channel owner',
  },
  legalEntity: {
    uk: 'Юридична особа',
    en: 'Legal entity',
  },
  genres: {
    uk: 'Жанри',
    en: 'Genres',
  },
  channelsLogo: {
    uk: 'Логотип каналу',
    en: "Channel's logo",
  },
  multicast: {
    uk: 'Multicast',
    en: 'Multicast',
  },
  onlyImageWithMaxSizeOf500kb: {
    uk: 'Тільки зображення максимального розміру 500 Кб',
    en: 'Only image with max size of 500 KB',
  },
  city: {
    uk: 'Місто',
    en: 'City',
  },
  contactPerson: {
    uk: 'Контактна особа',
    en: 'Contact person',
  },
  phone: {
    uk: 'Телефон',
    en: 'Phone',
  },
  email: {
    uk: 'Е-пошта',
    en: 'E-mail',
  },
  typeOfContentReceiving: {
    uk: 'Тип отримання контенту',
    en: 'Type of content receiving',
  },
  additionalInfo: {
    uk: 'Додаткова інформація',
    en: 'Additional info',
  },
  deliverySpecs: {
    uk: 'Характеристики доставки',
    en: 'Delivery specs',
  },
  connected: {
    uk: 'Підключено',
    en: 'Connected',
  },
  //VALUES
  yes: {
    uk: 'Так',
    en: 'Yes',
  },
  no: {
    uk: 'Ні',
    en: 'No',
  },
  connectedAsc: {
    uk: 'Підключено (зрост.)',
    en: 'Connected (asc)',
  },
  connectedDesc: {
    uk: 'Підключено (спад.)',
    en: 'Connected (desc)',
  },
  disconnectedAsc: {
    uk: 'Відключено (зрост.)',
    en: 'Disconnected (asc)',
  },
  disconnectedDesc: {
    uk: 'Відключено (спад.)',
    en: 'Disconnected (desc)',
  },
  channelNameAsc: {
    uk: 'Назва каналу (зрост.)',
    en: 'Channel name (asc)',
  },
  channelNameDesc: {
    uk: 'Назва каналу (спад.)',
    en: 'Channel name (desc)',
  },
  rightsholderNameAsc: {
    uk: 'Назва правовласника (зрост.)',
    en: 'Rightsholder name (asc)',
  },
  rightsholderNameDesc: {
    uk: 'Назва правовласника (спад.)',
    en: 'Rightsholder name (desc)',
  },
  changedAsc: {
    uk: 'Змінено (зрост.)',
    en: 'Changed (asc)',
  },
  changedDesc: {
    uk: 'Змінено (спад.)',
    en: 'Changed (desc)',
  },
  createdAsc: {
    uk: 'Створено (зрост.)',
    en: 'Created (asc)',
  },
  createdDesc: {
    uk: 'Створено (спад.)',
    en: 'Created (desc)',
  },
  statusAsc: {
    uk: 'Статус (зрост.)',
    en: 'Status (asc)',
  },
  statusDesc: {
    uk: 'Статус (спад.)',
    en: 'Status (desc)',
  },
  providersQtyAsc: {
    uk: 'Кількість провайдерів (зрост.)',
    en: 'Providers qty (asc)',
  },
  providersQtyDesc: {
    uk: 'Кількість провайдерів (спад.)',
    en: 'Providers qty (desc)',
  },
  providerNameAsc: {
    uk: 'Назва провайдера (зрост.)',
    en: 'Provider name (asc)',
  },
  providerNameDesc: {
    uk: 'Назва провайдера (спад.)',
    en: 'Provider name (desc)',
  },
  channelsQtyAsc: {
    uk: 'Кількість каналів (зрост.)',
    en: 'Channels qty (asc)',
  },
  channelsQtyDesc: {
    uk: 'Кількість каналів (спад.)',
    en: 'Channels qty (desc)',
  },
  connectedChannelsQtyAsc: {
    uk: 'Кількість підключених каналів (зрост.)',
    en: 'Connected channels qty (asc)',
  },
  connectedChannelsQtyDesc: {
    uk: 'Кількість підключених каналів (спад.)',
    en: 'Connected channels qty (desc)',
  },
  availableChannelsQtyAsc: {
    uk: 'Кількість доступних каналів (зрост.)',
    en: 'Available channels qty (asc)',
  },
  availableChannelsQtyDesc: {
    uk: 'Кількість доступних каналів (спад.)',
    en: 'Available channels qty (desc)',
  },
  processing: {
    uk: 'Обробка',
    en: 'Processing',
  },
  suspended: {
    uk: 'Призупинено',
    en: 'Suspended ',
  },
  fulfilled: {
    uk: 'Виконано',
    en: 'Fulfilled',
  },
  purchase: {
    uk: 'Платно',
    en: 'Purchase',
  },
  enabled: {
    uk: 'Дозволено',
    en: 'Enabled',
  },
  disabled: {
    uk: 'Заборонено',
    en: 'Disabled',
  },
  connection: {
    uk: 'Підключення',
    en: 'Connection',
  },
  shutdown: {
    uk: 'Відключення',
    en: 'Shutdown',
  },
  newProvider: {
    uk: 'Підключення (Новий провайдер)',
    en: 'Connection (New provider)',
  },
  financial: {
    uk: 'Фінансові проблеми',
    en: 'Financial problems',
  },
  technical: {
    uk: 'Технічні проблеми',
    en: 'Technical problems',
  },
  other: {
    uk: 'Інший',
    en: 'Other',
  },
  general: {
    uk: 'Загальний',
    en: 'General',
  },
  isOwnedYes: {
    uk: 'Так',
    en: 'Yes',
  },
  isOwnedNo: {
    uk: 'Ні',
    en: 'No',
  },
  events: {
    uk: 'Події',
    en: 'Events',
  },
  eventName: {
    uk: 'Назва події',
    en: 'Event name',
  },
  types: {
    uk: 'Типи',
    en: 'Types',
  },
  companyAccount: {
    uk: 'Профіль компанії',
    en: 'Company account',
  },
  approved: {
    uk: 'Затверджено',
    en: 'Approved',
  },
  //CONFIRMATION
  confirmation: {
    uk: 'Підтвердження',
    en: 'Confirmation',
  },
  confirmationRequired: {
    uk: 'Потрібне підтвердження',
    en: 'Confirmation required',
  },
  areYouSureYouWantToRemoveTheConnection: {
    uk: 'Ви впевнені, що хочете видалити з’єднання?',
    en: 'Are you sure you want to remove the connection?',
  },
  areYouSureYouWantToRemoveThePreset: {
    uk: 'Ви впевнені, що хочете видалити пресет?',
    en: 'Are you sure you want to remove the preset?',
  },
  areYouSureYouWantToSwitchTheQuality: {
    uk: 'Ви впевнені, що хочете змінити якість?',
    en: 'Are you sure you want to switch the quality?',
  },
  areYouSureYouWantToRebootIt: {
    uk: 'Ви впевнені, що хочете перезавантажити пристрій?',
    en: 'Are you sure you want to reboot the device?',
  },
  areYouSureYouWantToCloseThisConnection: {
    uk: "Ви впевнені, що хочете закрити це з'єднання?",
    en: 'Are you sure you want to close this connection?',
  },
  areYouSureYouWantToUnlockIt: {
    uk: 'Ви впевнені, що хочете розблокувати вихід?',
    en: 'Are you sure you want to unlock it?',
  },
  areYouSureYouWantToLockIt: {
    uk: 'Ви впевнені, що хочете заблокувати вихід?',
    en: 'Are you sure you want to lock it?',
  },
  areYouSureYouWantToRemoveEvent: {
    uk: 'Ви впевнені, що хочете видалити подію?',
    en: 'Are you sure you want to remove the event?!',
  },
  areYouSureYouWantToRemoveModule: {
    uk: 'Ви впевнені, що хочете видалити модуль?',
    en: 'Are you sure you want to remove the module?',
  },
  //NOT FOUND
  noDataFound: {
    uk: 'Даних не знайдено',
    en: 'No data found',
  },
  noProvidersFound: {
    uk: 'Провайдери не знайдені',
    en: 'No providers found',
  },
  noChannelsFound: {
    uk: 'Канали не знайдені',
    en: 'No channels found',
  },
  noRequestsFound: {
    uk: 'Заявки не знайдені',
    en: 'No requests found',
  },
  noOtherRequestsFound: {
    uk: 'Інші заявки не знайдені',
    en: 'No other applications were found',
  },
  noHistoryFound: {
    uk: 'Історії не знайдені',
    en: 'No history found',
  },
  //COMMON
  accepted: {
    uk: 'Прийнято',
    en: 'Accepted',
  },
  noInternalConnectionsYet: {
    uk: "Внутрішніх зв'язків ще немає",
    en: 'No internal connections yet',
  },
  noPresetsYet: {
    uk: 'Пресети відсутні',
    en: 'No presets',
  },
  addStLeastOneEvent: {
    uk: 'Додайте хоча б одну подію',
    en: 'Add at least one event',
  },
  viewInfo: {
    uk: 'Переглянути інформацію',
    en: 'View info',
  },
  name: {
    uk: 'Назва',
    en: 'Name',
  },
  editTransferRequest: {
    uk: 'Редагувати запит на трансфер',
    en: 'Edit transfer request',
  },
  editPreset: {
    uk: 'Редагувати пресет',
    en: 'Edit preset',
  },
  doYouWantToConfirmThisAction: {
    uk: 'Ви хочете підтвердити цю дію',
    en: 'Do you want to confirm this action',
  },
  output: {
    uk: 'Вихід',
    en: 'Output',
  },
  inputTransfer: {
    uk: 'Трансфер входу',
    en: 'Input transfer',
  },
  transfers: {
    uk: 'Трансфери',
    en: 'Transfers',
  },
  availableChannel: {
    uk: 'Доступний канал',
    en: 'Available channel',
  },
  outboundTransferRequest: {
    uk: 'Вихідний запит на трансфер',
    en: 'Outbound transfer requests',
  },
  outboundTransferRequests: {
    uk: 'Вихідні запити на трансфер',
    en: 'Outbound transfer requests',
  },
  inboundTransferRequest: {
    uk: 'Вхідний запит на трансфер',
    en: 'Inbound transfer request',
  },
  connectionType: {
    uk: 'Тип підключення',
    en: 'Connection type',
  },
  reboot: {
    uk: 'Перезавантаження',
    en: 'Reboot',
  },
  newTransferRequest: {
    uk: 'Новий запит на трансфер',
    en: 'New transfer request',
  },
  noInputsYet: {
    uk: 'Входи відсутні',
    en: 'No inputs',
  },
  noOutputsYet: {
    uk: 'Виходи відсутні',
    en: 'No outputs',
  },
  device: {
    uk: 'Пристрій',
    en: 'Device',
  },
  noRequestsYet: {
    uk: 'Запити відсутні',
    en: 'No requests',
  },
  select: {
    uk: 'Виберіть',
    en: 'Select',
  },
  cancel: {
    uk: 'Скасувати',
    en: 'Cancel',
  },
  pleaseUseFiltersToGetDataForMonitoringCharts: {
    uk: 'Будь ласка, використовуйте фільтри для отримання даних по графікам моніторингу.',
    en: 'Please use filters to get data for monitoring charts.',
  },
  noDataAvailablePleaseSelectAnotherChannelsOrProviders: {
    uk: 'Дані відсутні, будь ласка оберіть інші канали або інших провайдерів.',
    en: 'No data available, please select another channels or providers.',
  },
  youDoesntHaveNotificationsYet: {
    uk: 'У вас ще немає сповіщень',
    en: "You doesn't have notifications yet",
  },
  youDontHaveNotificationsYet: {
    uk: 'Сповіщення відсутні',
    en: 'You do not have any notifications yet',
  },
  cosmonova: {
    uk: 'Космонова',
    en: 'Cosmonova',
  },
  newest50mediaItems: {
    uk: 'Найновіші 50 медіаелементів',
    en: 'Newest 50 media items',
  },
  allMediaItems: {
    uk: 'Всі медіаелементи',
    en: 'All media items',
  },
  newSettings: {
    uk: 'Нові налаштування',
    en: 'New settings',
  },
  editSettings: {
    uk: 'Редагувати налаштування',
    en: 'Edit settings',
  },
  areYouSureYouWantToActivateTheSettings: {
    uk: 'Ви впевнені, що бажаєте активувати налаштування?',
    en: 'Are you sure you want to activate the settings?',
  },
  newServer: {
    uk: 'Новий сервер',
    en: 'New server',
  },
  editServer: {
    uk: 'Редагувати сервер',
    en: 'Edit server',
  },
  copyToClipboard: {
    uk: 'Копіювати в буфер обміну',
    en: 'Copy to clipboard',
  },
  protocol: {
    uk: 'Протокол',
    en: 'Protocol',
  },
  playpath: {
    uk: 'Шлях відтворення',
    en: 'Playpath',
  },
  streamKey: {
    uk: 'Ключ потоку',
    en: 'Stream key',
  },
  iPAddressOrDomainName: {
    uk: 'IP-адреса або доменне ім’я',
    en: 'IP address or domain name',
  },
  port: {
    uk: 'Порт',
    en: 'Port',
  },
  connectTimeoutMs: {
    uk: 'Час очікування підключення (мс)',
    en: 'Connect timeout (ms)',
  },
  retryDurationMs: {
    uk: 'Тривалість повторної спроби (мс)',
    en: 'Retry duration (ms)',
  },
  latencyMs: {
    uk: 'Затримка (мс)',
    en: 'Latency (ms)',
  },
  bandwidthProccent: {
    uk: 'Пропускна здатність (%)',
    en: 'Bandwidth (%)',
  },
  streamId: {
    uk: 'ID потоку',
    en: 'Stream ID',
  },
  encryption: {
    uk: 'Шифрування',
    en: 'Encryption',
  },
  encryptionPassphrase: {
    uk: 'Пароль для шифрування',
    en: 'Encryption passphrase',
  },
}
