import { translations as t } from './translations'

export const getGroups = (array, field, labelKey = 'id', labelGroup = 'data') => {
  const res = {}
  array.forEach((el) => {
    res[el[field]] ? res[el[field]].push(el) : (res[el[field]] = [el])
  })
  return Object.keys(res).map((key) => ({
    [labelKey]: key,
    [labelGroup]: res[key],
  }))
}

export const getInitialValues = (location, defaultValues) => {
  const queryStr = new URLSearchParams(location.search)
  const entries = queryStr.entries()

  for (let value of entries) {
    let result = value[0].match(/filter\[(\w+)]/i)
    let key = result ? result[1] : value[0]
    let defValue = value[1]
    if (
      [
        'sort',
        'name',
        'statuses',
        'types',
        'input_types',
        'output_types',
        'stream_types',
        'address',
        'locations',
        'qualities',
        'is_locked',
      ].some((el) => el === key)
    ) {
      const defValueArr = defValue.split(',')
      defValue = defValueArr.length > 1 ? defValueArr : [defValue]
    }
    defaultValues[key] = defValue
  }

  const { page, ...rest } = defaultValues
  return rest
}

export const lineColors = [
  '#4770bc',
  '#f4a416',
  '#62c673',
  '#c25fff',
  '#a5c5fd',
  '#17becf',
  '#f5e71f',
  '#ffffff',
]

export const getLineColor = () => {
  let i = 0
  return () => {
    if (i >= lineColors.length) {
      i = 0
    }
    const color = lineColors[i]
    i++
    return color
  }
}

export const dateFormat = (dateStr) => {
  if (dateStr === null) return
  const date = dateStr ? new Date(dateStr) : new Date()
  return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.${
    date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  }.${date.getFullYear()}`
}

export const timeFormat = (dateStr) => {
  if (dateStr === null) return
  const date = dateStr ? new Date(dateStr) : new Date()
  return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  }`
}

export const sourceTypes = {
  uk: [
    { value: 'input', label: t.input.uk },
    { value: 'externalInput', label: t.externalInput.uk },
    // { value: 'channel', label: 'Channel' },
    { value: 'preset', label: t.preset.uk },
    { value: 'link', label: t.link.uk },
  ],
  en: [
    { value: 'input', label: t.input.en },
    { value: 'externalInput', label: t.externalInput.en },
    // { value: 'channel', label: 'Channel' },
    { value: 'preset', label: t.preset.en },
    { value: 'link', label: t.link.en },
  ],
}

export const formatBps = (value, round = false, k = 1) => {
  if (value / 1_000_000 < 1000) {
    return (
      (round ? Math.round((value / 1_000_000) * (k * 10)) / (k * 10) : value / 1_000_000) + ' Mbps'
    )
  }
  return (
    (round ? Math.round((value / 1_000_000_000) * (k * 10)) / (k * 10) : value / 1_000_000_000) +
    ' Gbps'
  )
}

export const isPrepareDeviceAttributes = (device_attributes) => {
  if (!!device_attributes) {
    return (
      device_attributes.hasOwnProperty('status') ||
      device_attributes.hasOwnProperty('param') ||
      device_attributes['live-status']?.live.find((item) => !!(item.result || item['cur-bps'])) ||
      Object.keys(device_attributes['video-info']).some(
        (item) =>
          item === 'width' ||
          item === 'height' ||
          item === 'scan' ||
          item === 'field-rate' ||
          item === 'ind',
      )
    )
  }
  return false
}

export const prepareDeviceAttributes = (device_attributes) => {
  if (device_attributes.hasOwnProperty('param')) {
    const attributes = []
    for (const item of device_attributes.param) {
      if (
        item['@attributes'].name === 'playback_state' ||
        item['@attributes'].name === 'playback_current_bitrate' ||
        item['@attributes'].name === 'playback_video_width' ||
        item['@attributes'].name === 'playback_video_height'
      ) {
        attributes.push(item['@attributes'])
      }
    }
    // DUNE
    return {
      dune: attributes,
      status: null,
      live_status: {
        live: {
          isLiveProperty: false,
        },
      },
      video_info: {
        isVideoInfoProperty: false,
      },
    }
  } else {
    return {
      status: device_attributes.hasOwnProperty('status') ? device_attributes.status : null,
      dune: [],
      live_status: {
        live: {
          isLiveProperty:
            device_attributes.hasOwnProperty('live-status') &&
            Array.isArray(device_attributes['live-status'].live)
              ? device_attributes['live-status']?.live.find(
                  (item) => !!(item.result || item['cur-bps']),
                )
              : false,
          result:
            device_attributes.hasOwnProperty('live-status') &&
            Array.isArray(device_attributes['live-status'].live) &&
            device_attributes['live-status']?.live[0]?.hasOwnProperty('result')
              ? device_attributes['live-status']?.live[0].result
              : null,
          cur_bps:
            device_attributes.hasOwnProperty('live-status') &&
            Array.isArray(device_attributes['live-status'].live) &&
            device_attributes['live-status']?.live[0]?.hasOwnProperty('cur-bps')
              ? device_attributes['live-status']?.live[0]['cur-bps']
              : null,
        },
      },
      video_info: {
        isVideoInfoProperty: device_attributes.hasOwnProperty('video-info')
          ? Object.keys(device_attributes['video-info']).some(
              (item) =>
                item === 'width' ||
                item === 'height' ||
                item === 'scan' ||
                item === 'field-rate' ||
                item === 'ind',
            )
          : false,
        width:
          device_attributes.hasOwnProperty('video-info') &&
          device_attributes['video-info'].hasOwnProperty('width')
            ? device_attributes['video-info'].width
            : null,
        height:
          device_attributes.hasOwnProperty('video-info') &&
          device_attributes['video-info'].hasOwnProperty('height')
            ? device_attributes['video-info'].height
            : null,
        scan:
          device_attributes.hasOwnProperty('video-info') &&
          device_attributes['video-info'].hasOwnProperty('scan')
            ? device_attributes['video-info'].scan
            : null,
        field_rate:
          device_attributes.hasOwnProperty('video-info') &&
          device_attributes['video-info'].hasOwnProperty('field-rate')
            ? device_attributes['video-info']['field-rate']
            : null,
      },
    }
  }
}

export const prepareLabelDeviceAttributes = (code) => {
  let cod = ''

  switch (Number(code)) {
    case 0:
      cod = 'Playing'
      break
    case 22:
      cod = 'Playing'
      break
    case 23:
      cod = 'Сonnecting'
      break
    case 24:
      cod = 'Waiting'
      break
    case 25:
      cod = 'Authorizing'
      break
    case 28:
      cod = 'Resolving DNS'
      break
    case 30:
      cod = 'Authentication error'
      break
    default:
      cod = 'Code ' + code
  }

  return cod
}

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const numberFromFormatToMbps = (number, specific) => {
  if (specific === 'dune') {
    const findDot = Number((number / 1000000).toString().indexOf('.'))
    return Number((number / 1000000).toString().slice(0, findDot + 2))
  }
  if (specific === 'ultra') {
    const findDot = Number(((number / 1000000) * 8).toString().indexOf('.'))
    return Number(((number / 1000000) * 8).toString().slice(0, findDot + 2))
  }
  return number
}

export const getDeviceAttributesForInputsOutputsForTable = (item) => {
  let current_bitrate_mbps = ''
  let width_x_height_s_f = ''
  let width = ''
  let height = ''
  let scan = ''
  let fieldRate = ''

  if (item.device_attributes !== null) {
    const dev_att = JSON.parse(item.device_attributes)

    if (item.type === 'dune') {
      if ('param' in dev_att) {
        dev_att.param.forEach((item) => {
          if (item['@attributes'].name === 'playback_current_bitrate') {
            current_bitrate_mbps = `${numberFromFormatToMbps(
              item['@attributes'].value,
              'dune',
            )} mbps`
          } else if (item['@attributes'].name === 'playback_video_width') {
            width = item['@attributes'].value
          } else if (item['@attributes'].name === 'playback_video_height') {
            height = item['@attributes'].value
          } else if (item['@attributes'].name === 'scan') {
            scan = item['@attributes'].value[0].toLowerCase()
          } else if (item['@attributes'].name === 'field-rate') {
            fieldRate = item['@attributes'].value
          }
        })
        width_x_height_s_f = `${width ? width : ''}${width && height ? 'x' : ''}${
          height ? height : ''
        } ${scan ? scan[0].toLowerCase() : ''} ${fieldRate ? fieldRate : ''}`
      }
    } else {
      current_bitrate_mbps = `${
        'live-status' in dev_att
          ? 'live' in dev_att['live-status'] && dev_att['live-status'].live[0]
            ? numberFromFormatToMbps(dev_att['live-status'].live[0]['cur-bps'], 'ultra') + ' mbps'
            : 'no'
          : ''
      }`
      scan =
        'video-info' in dev_att && 'scan' in dev_att['video-info'] ? dev_att['video-info'].scan : ''
      fieldRate =
        'video-info' in dev_att && 'field-rate' in dev_att['video-info']
          ? dev_att['video-info']['field-rate']
          : ''
      width =
        'video-info' in dev_att && dev_att['video-info'].width ? dev_att['video-info'].width : ''
      height =
        'video-info' in dev_att && dev_att['video-info'].height ? dev_att['video-info'].height : ''
      width_x_height_s_f = `${width ? width : ''}${width && height ? 'x' : ''}${
        height ? height : ''
      } ${scan ? scan[0].toLowerCase() : ''} ${fieldRate ? fieldRate : ''}`
    }
  }

  return {
    current_bitrate_mbps: current_bitrate_mbps.trim(),
    width_x_height_s_f: width_x_height_s_f.trim(),
    scan: scan.trim(),
    fieldRate,
    width,
    height,
  }
}

export const defaultSortOptions = (a, b) =>
  a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1
