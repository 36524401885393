import { Form, Formik } from 'formik'
import { array, mixed, object, string } from 'yup'
import makeStyles from '@mui/styles/makeStyles'
import { Grid } from '@mui/material'

import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'
import Select from '../../../forms/Select'
import TextField from '../../../forms/TextField'
import apiService from '../../../services/api-service'
import { useAppContext } from '../../../components/App/AppContext'
import SelectList from '../../../forms/SelectList'
import SubmitButton from '../../../forms/SubmitButton'
import CheckBox from '../../../forms/CheckBox'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Switcher from '../../../forms/Switcher'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 1100,
    margin: '0 auto',
    padding: '48px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 20,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
}))

const EditTransferRequest = ({ onOk, accountId }) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { open, id, buffer },
  } = useAppContext()

  const [data, setData] = useState(null)
  const [recipients, setRecipients] = useState([])

  const isAll = data?.includes?.length === 0

  const initialValues = {
    title: data?.title || '',
    description: data?.description || '',
    isConfirmation: data?.with_confirmation,
    isAll,
    recipients: isAll ? data?.excludes.map((el) => el.id) : data?.includes.map((el) => el.id),
  }

  useEffect(
    () => {
      if (open && id === `editTransferRequest${accountId}`) {
        Promise.all([
          apiService.get(
            `/virtual-matrix/transfer-requests/outbound/${buffer.id}`,
            buffer.contextId,
          ),
          apiService.get('/virtual-matrix/accounts'),
        ])
          .then(([res1, res2]) => {
            setData(res1.data)
            setRecipients(res2.data.filter((el) => el.id !== accountId))
          })
          .catch(console.log)
      }
    },
    // eslint-disable-next-line
    [open],
  )

  if (!data) return null

  return (
    <FullScreenModal id={`editTransferRequest${accountId}`} title={t.editTransferRequest[language]}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const request = {
            title: values.title,
            description: values.description,
            with_confirmation: values.isConfirmation,
            [values.isAll ? 'excludes' : 'includes']: values.recipients,
          }
          return apiService
            .patch(`/virtual-matrix/transfer-requests/${data?.id}`, request)
            .then(() => {
              closeFullScreenModal()
              if (onOk) {
                onOk()
              } else {
                history.push(`/outbound-transfer-requests/${accountId}/${data?.id}`)
              }
            })
            .catch((e) => {
              closeFullScreenModal()
              console.log(e)
            })
        }}
        validationSchema={object({
          title: string().required(t.fieldIsRequired[language]),
          recipients: mixed().when('isAll', {
            is: false,
            then: array().min(1),
          }),
        })}
      >
        {({ values, isSubmitting }) => (
          <Form noValidate className={classes.formContainer}>
            <Grid container spacing={5} style={{ minHeight: '600px' }}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <TextField label={t.title[language]} name='title' required />
                <TextField multiline label={t.description[language]} name='description' />
              </Grid>
              {/*END - первая колонка*/}

              {/* Средняя колонка*/}
              <Grid item xs={4} style={{ paddingTop: 48, paddingLeft: 45 }}>
                <CheckBox name='isConfirmation' label={t.confirmationRequired[language]} />
                <Switcher
                  label={t.recipients[language]}
                  upperTitle={t.all[language]}
                  lowerTitle={t.selected[language]}
                  name='isAll'
                />
              </Grid>
              {/* END - Средняя колонка*/}

              {/* Третья колонка*/}
              <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  label={
                    values.isAll ? t.excludedRecipients[language] : t.selectedRecipients[language]
                  }
                  name='recipients'
                  required={!values.isAll}
                  fullWidth
                  multiple
                >
                  {recipients.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                </Select>
                <SelectList name='recipients' withoutIcon listOfValues={recipients} />
              </Grid>
              {/* END - Третья колонка*/}
            </Grid>
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
            {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default EditTransferRequest
