import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'

const useStyles = makeStyles(() => ({
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
  addBtn: {
    '& > button': {
      height: 32,
      width: 32,
      minWidth: 32,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      marginRight: 12,
    },
    justifyContent: 'flex-end',
    paddingRight: 30,
  },
}))

const AddBtn = ({ onClick, ...props }) => {
  const classes = useStyles()
  return (
    <div
      {...props}
      className={clsx(
        classes.borderRadiusCell,
        classes.borderRadiusCellRight,
        classes.addBtn,
        props.className,
      )}
    >
      <Button
        variant='contained'
        color='primary'
        startIcon={<AddIcon />}
        onClick={onClick}
        disabled={props.addBtnDis}
      />
    </div>
  )
}

export default AddBtn
