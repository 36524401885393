import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import makeStyles from '@mui/styles/makeStyles'

import { useAppContext } from '../../../components/App/AppContext'
import Tabs from '../../../components/Tabs/Tabs'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
import Status from '../../../components/Status/Status'
import CardBody from '../../../components/Card/CardBody'
import CardItem from '../../../components/Card/CardItem'
import RefreshBtn from '../../../components/ActionButtons/RefreshBtn/RefreshBtn'
import LockBtn from '../../../components/ActionButtons/LockBtn/LockBtn'
import Quality from '../../../components/ActionButtons/Quality/Quality'
import apiService from '../../../services/api-service'
import InternalConnectionsTab from './InnerTabs/InternalConnectionsTab'
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog'
import LocationMapGoogleShow from '../../../forms/LocationMapGoogleShow'
import { translations as t } from '../../../services/translations'
import {
  isPrepareDeviceAttributes,
  prepareDeviceAttributes,
  numberFromFormatToMbps,
  capitalizeFirstLetter,
  prepareLabelDeviceAttributes,
} from '../../../services/helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: 'calc(50% - 15px)',
    minHeight: 250,
  },
  tabsContainer: {
    marginTop: 20,
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
  cardItemValue: {
    width: '430px',
    display: 'flex',
    justifyContent: 'end',
    overflowWrap: 'anywhere',
    '& span': {
      textAlign: 'start',
    },
  },
}))

const Output = () => {
  const classes = useStyles()
  const [deviceAttributes, setDeviceAttributes] = useState({
    dune: [],
    status: null,
    live_status: {
      live: {
        isLiveProperty: false,
      },
    },
    video_info: {
      isVideoInfoProperty: false,
    },
  })

  const {
    setCurrentPage,
    language,
    openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const { contextId, outputId } = useParams()

  const [data, setData] = useState({})

  const getData = () => {
    apiService
      .get(`/virtual-matrix/outputs/${outputId}`, contextId)
      .then((res) => {
        setData(res.data)

        const dev_att = JSON.parse(res.data?.device_attributes)
        if (isPrepareDeviceAttributes(dev_att)) {
          setDeviceAttributes(prepareDeviceAttributes(dev_att))
        }
      })
      .catch(console.log)
  }
  useEffect(() => {
    apiService.get(`/virtual-matrix/outputs/${outputId}`, contextId).then((res) => {
      setCurrentPage(
        <span>
          {t.output[language]} <span style={{ margin: '0 17px' }}>|</span> {res.data.name}
        </span>,
      )
    })
    return () => setCurrentPage('')

    // eslint-disable-next-line
  }, [language])

  useEffect(() => {
    getData()

    return () =>
      setDeviceAttributes({
        dune: [],
        status: null,
        live_status: {
          live: {
            isLiveProperty: false,
          },
        },
        video_info: {
          isVideoInfoProperty: false,
        },
      })
    // eslint-disable-next-line
  }, [])

  const handleSwitchQuality = (item) => {
    return apiService
      .patch(
        `/virtual-matrix/outputs/${item.id}`,
        { quality: item.quality === 'HD' ? 'SD' : 'HD' },
        contextId,
      )
      .then((res) => {
        getData()
      })
      .catch(console.log)
  }

  const handleLock = (item) => {
    return apiService
      .patch(`/virtual-matrix/outputs/${item.id}`, { is_locked: !item.is_locked }, contextId)
      .then((res) => {
        getData()
      })
      .catch(console.log)
  }

  const handleReboot = (item) => {
    return apiService
      .post(`/virtual-matrix/outputs/${item.id}/reboot`, null, contextId)
      .then((res) => {
        getData()
      })
      .catch(console.log)
  }

  const tabs = [
    {
      label: `${t.internalConnections[language]}`,
      contentTitle: '',
      contentBody: <InternalConnectionsTab />,
    },
  ]

  return (
    <motion.div
      className={classes.root}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToSwitchTheQuality[language]}
        onOk={handleSwitchQuality.bind(this, buffer)}
        id={`switchQuality${contextId}`}
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={
          buffer?.is_locked
            ? t.areYouSureYouWantToUnlockIt[language]
            : t.areYouSureYouWantToLockIt[language]
        }
        onOk={handleLock.bind(this, buffer)}
        id={`lock${contextId}`}
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRebootIt[language]}
        onOk={handleReboot.bind(this, buffer)}
        id={`reboot${contextId}`}
      />
      <div className={classes.cardRow}>
        <div className={classes.cardContainer}>
          <Card>
            <CardTitle
              title={data.name}
              startActionButton={
                <Quality
                  quality={data.quality}
                  onChange={() => openFullScreenModal(`switchQuality${contextId}`, data)}
                />
              }
            >
              <Status status={data.status} style={{ marginRight: 6 }} />
              <LockBtn
                isLocked={data.is_locked}
                onClick={() => openFullScreenModal(`lock${contextId}`, data)}
                style={{ marginRight: -10 }}
              />
              <RefreshBtn
                onClick={() => openFullScreenModal(`reboot${contextId}`, data)}
                style={{ marginRight: -14 }}
              />
            </CardTitle>
            <CardBody>
              <CardItem
                label={t.owner[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data.owner?.name}</span>
                  </span>
                }
              />
              <CardItem label={t.type[language]} value={data?.output_type} />
              <CardItem label={t.device[language]} value={capitalizeFirstLetter(data?.type)} />
              <CardItem
                label={t.location[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data?.location?.name}</span>
                  </span>
                }
              />
              <CardItem
                label={t.address[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data?.address}</span>
                  </span>
                }
              />
              {data?.location?.id && (
                <LocationMapGoogleShow
                  coordinates={{ lat: data?.location.lat, lng: data?.location.lng }}
                  mapContainerStyle={{
                    minWidth: '250px',
                    width: '100%',
                    height: '180px',
                  }}
                />
              )}
            </CardBody>
          </Card>
        </div>
        {deviceAttributes?.live_status?.isLiveProperty ||
        deviceAttributes?.video_info?.isVideoInfoProperty ? (
          <div className={classes.cardContainer}>
            <Card>
              <CardTitle title={t.currentDeviceInfo[language]} />
              <CardBody>
                {deviceAttributes?.live_status?.live?.result !== null && (
                  <CardItem
                    label={t.state[language]}
                    value={prepareLabelDeviceAttributes(deviceAttributes?.live_status.live.result)}
                  />
                )}
                {deviceAttributes?.status !== null &&
                  typeof deviceAttributes?.status === 'number' && (
                    <CardItem
                      label={t.state[language]}
                      value={prepareLabelDeviceAttributes(deviceAttributes?.status)}
                    />
                  )}
                {deviceAttributes?.live_status?.live?.cur_bps !== null &&
                  typeof deviceAttributes?.live_status?.live?.cur_bps === 'number' && (
                    <CardItem
                      label={t.currentBitrateMbps[language]}
                      value={numberFromFormatToMbps(
                        deviceAttributes?.live_status.live.cur_bps,
                        'ultra',
                      )}
                    />
                  )}
                {deviceAttributes?.video_info?.width !== null &&
                  typeof deviceAttributes?.video_info?.width === 'number' && (
                    <CardItem
                      label={t.width[language]}
                      value={deviceAttributes?.video_info?.width}
                    />
                  )}
                {deviceAttributes?.video_info?.height !== null &&
                  typeof deviceAttributes?.video_info?.height === 'number' && (
                    <CardItem
                      label={t.height[language]}
                      value={deviceAttributes?.video_info?.height}
                    />
                  )}
                {deviceAttributes?.video_info?.scan !== null && (
                  <CardItem
                    label={t.scan[language]}
                    value={capitalizeFirstLetter(deviceAttributes?.video_info?.scan)}
                  />
                )}
                {deviceAttributes?.video_info?.field_rate !== null &&
                  typeof deviceAttributes?.video_info?.field_rate === 'number' && (
                    <CardItem
                      label={t.frameRate[language]}
                      value={deviceAttributes?.video_info?.field_rate}
                    />
                  )}
              </CardBody>
            </Card>
          </div>
        ) : deviceAttributes.dune && deviceAttributes?.dune.length ? (
          <div className={classes.cardContainer}>
            <Card>
              <CardTitle title={t.currentDeviceInfo[language]} />
              <CardBody>
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_state') && (
                    <CardItem
                      label={t.state[language]}
                      value={capitalizeFirstLetter(
                        deviceAttributes.dune.find((item) => item.name === 'playback_state').value,
                      )}
                    />
                  )}
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_state') && (
                    <CardItem
                      label={t.currentBitrateMbps[language]}
                      value={numberFromFormatToMbps(
                        deviceAttributes.dune.find(
                          (item) => item.name === 'playback_current_bitrate',
                        ).value,
                        'dune',
                      )}
                    />
                  )}
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_state') && (
                    <CardItem
                      label={t.width[language]}
                      value={
                        deviceAttributes.dune.find((item) => item.name === 'playback_video_width')
                          .value
                      }
                    />
                  )}
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_video_height') && (
                    <CardItem
                      label={t.height[language]}
                      value={
                        deviceAttributes.dune.find((item) => item.name === 'playback_video_height')
                          .value
                      }
                    />
                  )}
              </CardBody>
            </Card>
          </div>
        ) : null}
      </div>
      <div className={classes.tabsContainer}>
        <Tabs resizable data={tabs} />
      </div>
    </motion.div>
  )
}

export default Output
