import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    overflow: 'hidden',
    height: '100%',
  },
}))

function Card({ children, ...props }) {
  const classes = useStyles()

  return <div className={clsx(classes.root, props.className)}>{children}</div>
}

export default Card
