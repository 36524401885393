import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { number, object, string } from 'yup'

import Button from '@mui/material/Button'
import { useAppContext } from '../../../components/App/AppContext'
import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'
import Select from '../../../forms/Select'
import SubmitButton from '../../../forms/SubmitButton'
import TextField from '../../../forms/TextField'
import apiService from '../../../services/api-service'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 1320,
    margin: '0 auto',
    padding: '20px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 36,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
  posterItem: {
    marginBottom: 26,
    marginRight: 20,
  },
  urlStr: {
    border: '1px solid rgba(255,255,255,0.3)',
    padding: 10,
    borderRadius: 10,
    marginTop: 30,
    marginBottom: 20,
    lineBreak: 'anywhere',
  },
}))

const EditSettings = ({ onOk, inputId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const initialValues = {
    name: buffer?.name || '',
    type: buffer ? buffer.type : '',
    // RTMP
    protocol: buffer ? buffer.url?.slice(0, buffer.url.indexOf(':') + 3) : '',
    playpath: buffer ? buffer.url?.slice(buffer.url.indexOf(':') + 3) : '',
    stream_key: buffer?.stream_key || '',
    // SRT Caller
    address: buffer?.address || '',
    port: buffer?.port || '',
    connect_timeout: buffer?.connect_timeout || 3000,
    retry_timeout: buffer?.retry_timeout || 10000,
    latency: buffer?.latency || 120,
    bandwidth: buffer?.bandwidth || 25,
    stream_id: buffer?.stream_id || '',
    encryption: buffer ? buffer.encryption || 0 : 0,
    encryption_passphrase: buffer?.encryption_passphrase || '',
  }

  const createFullUrlStr = (protocol, playpath, streamKey) => {
    if (!streamKey) {
      return `${protocol}${playpath}`
    }
    return `${protocol}${playpath}${
      streamKey[0] !== '/' && playpath[playpath.length - 1] !== '/' ? '/' + streamKey : streamKey
    }`
  }

  const copyStr = (text) => {
    if (!navigator.clipboard) {
      return
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      },
    )
  }

  return (
    <FullScreenModal
      id='editServers'
      title={buffer ? t.editServer[language] : t.newServer[language]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          // RTMP
          let reqBody = {
            name: values.name,
            type: values.type,
            url: values.protocol + values.playpath,
            stream_key: values.stream_key,
          }
          if (values.type === 1) {
            // SRT Caller
            reqBody = {
              name: values.name,
              type: values.type,
              address: values.address,
              port: values.port,
              connect_timeout: values.connect_timeout,
              retry_timeout: values.retry_timeout,
              latency: values.latency,
              bandwidth: values.bandwidth,
              stream_id: values.stream_id,
              encryption: values.encryption,
              encryption_passphrase: values.encryption > 0 ? values.encryption_passphrase : null,
            }
          }
          const request = buffer
            ? apiService.put(`/virtual-matrix/inputs/magewell-ultra/servers/${buffer.id}`, reqBody)
            : apiService.post(`/virtual-matrix/inputs/magewell-ultra/${inputId}/servers`, reqBody)
          return request
            .then((res) => {
              onOk()
              closeFullScreenModal()
            })
            .catch((e) => {
              console.log(e)
              enqueueSnackbar(e.response?.data.error || e.message, {
                variant: 'error',
              })
            })
        }}
        validationSchema={object({
          name: string().required(t.fieldIsRequired[language]),
          type: number().required(t.fieldIsRequired[language]),

          protocol: string().when('type', {
            is: 0,
            then: string().required(t.fieldIsRequired[language]),
          }),
          playpath: string().when('type', {
            is: 0,
            then: string().required(t.fieldIsRequired[language]),
          }),
          stream_key: string().when('type', {
            is: 0,
            then: string().required(t.fieldIsRequired[language]),
          }),
          address: string().when('type', {
            is: 1,
            then: string().required(t.fieldIsRequired[language]),
          }),
          port: number().when('type', {
            is: 1,
            then: number().required(t.fieldIsRequired[language]),
          }),
          connect_timeout: number().required('Connect timeout (ms) is a required field'),
          retry_timeout: number().required('Retry duration (ms) is a required field'),
          latency: number().required('Latency (ms) is a required field'),
          bandwidth: number().required('Bandwidth (%) is a required field'),
          stream_id: string(),
          encryption: number().required('Encryption is a required field'),
          encryption_passphrase: string().when('encryption', {
            is: (encryption) => encryption === 0,
            then: string().notRequired(),
            otherwise: string().required('Encryption passphrase is a required field').min(10),
          }),
        })}
      >
        {({ isSubmitting, values }) => (
          <Form noValidate className={classes.formContainer}>
            <Grid container spacing={5} style={{ minHeight: '600px' }}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <TextField label={t.name[language]} name='name' required />
                <Select
                  label={t.type[language]}
                  name='type'
                  disabled={!!buffer}
                  fullWidth
                  required
                  clean
                >
                  {[
                    { value: 0, label: 'RTMP' },
                    { value: 1, label: 'SRT Caller' },
                  ]}
                </Select>
              </Grid>
              {/*END - первая колонка*/}
              {/* средняя колонка*/}
              <Grid item xs={4}>
                {values.type === 0 && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <Select
                        label={t.protocol[language]}
                        name='protocol'
                        style={{ width: language === 'en' ? 135 : 150, minWidth: 100 }}
                        fullWidth
                        required
                        clean
                      >
                        {[
                          { value: 'rtmp://', label: 'rtmp://' },
                          { value: 'rtmps://', label: 'rtmps://' },
                        ]}
                      </Select>
                      <TextField label={t.playpath[language]} name='playpath' required />
                    </div>
                    <TextField label={t.streamKey[language]} name='stream_key' required />
                    {values.protocol || values.playpath || values.stream_key ? (
                      <>
                        <p className={classes.urlStr}>
                          {createFullUrlStr(values.protocol, values.playpath, values.stream_key)}
                        </p>
                        <Button
                          onClick={() =>
                            copyStr(
                              createFullUrlStr(values.protocol, values.playpath, values.stream_key),
                            )
                          }
                          variant='outlined'
                          color='primary'
                          type='button'
                        >
                          {t.copyToClipboard[language]}
                        </Button>
                      </>
                    ) : null}
                  </>
                )}

                {values.type === 1 && (
                  <>
                    <TextField label={t.iPAddressOrDomainName[language]} name='address' required />
                    <TextField label={t.port[language]} name='port' type='number' required />
                    <TextField
                      label={t.connectTimeoutMs[language]}
                      name='connect_timeout'
                      type='number'
                      required
                    />
                    <TextField
                      label={t.retryDurationMs[language]}
                      name='retry_timeout'
                      type='number'
                      required
                    />
                    <TextField
                      label={t.latencyMs[language]}
                      name='latency'
                      type='number'
                      required
                    />
                  </>
                )}
              </Grid>
              {/*END - средняя колонка*/}
              {/* третья колонка*/}
              <Grid item xs={4}>
                {values.type === 1 && (
                  <>
                    <TextField
                      label={t.bandwidthProccent[language]}
                      name='bandwidth'
                      type='number'
                      required
                    />
                    <TextField label={t.streamId[language]} name='stream_id' />
                    <Select
                      label={t.encryption[language]}
                      name='encryption'
                      fullWidth
                      required
                      clean
                    >
                      {[
                        { value: 0, label: 'no encryption' },
                        { value: 16, label: 'AES-128' },
                        { value: 24, label: 'AES-192' },
                        { value: 32, label: 'AES-256' },
                      ]}
                    </Select>
                    {values.encryption > 0 && (
                      <TextField
                        label={t.encryptionPassphrase[language]}
                        name='encryption_passphrase'
                        required
                      />
                    )}
                  </>
                )}
              </Grid>
              {/*END - третья колонка*/}
            </Grid>
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default EditSettings
