import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
  },
  item: {
    margin: 2,
    border: `1px solid rgba(50,197,255,0.7)`,
    borderRadius: 4,
    padding: '0 4px',
    color: 'rgba(50,197,255,0.7)',
  },
}))

const Tags = ({ labels }) => {
  const classes = useStyles()

  return (
    <ul className={classes.root}>
      {labels.map((el) => (
        <li className={classes.item} key={el}>
          {el}
        </li>
      ))}
    </ul>
  )
}

export default Tags
