import makeStyles from '@mui/styles/makeStyles'
import { useAppContext } from '../../../components/App/AppContext'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    zIndex: 1000,
    backgroundColor: '#29355A',
    width: 'calc(100% - 43px)',
    '& .headingContainer': {
      backgroundColor: '#303B5F',
      borderRadius: '16px 16px 0 0',
      display: 'flex',
      alignItems: 'center',
      '& .item': {
        display: 'flex',
        alignItems: 'center',
        height: 60,
        fontSize: 14,
        fontWeight: 500,
        padding: '0 16px',
        '&.order': {
          paddingLeft: 30,
          width: 70,
        },
        '&.name': {
          width: '18%',
        },
        '&.type': {
          width: '8%',
        },
        '&.formatType': {
          width: '8%',
        },
        '&.locationAddress': {
          width: '18%',
          '& div p': {
            fontWeight: 500,
          },
        },
        '&.quality': {
          width: '10%',
        },
        '&.status': {
          flexGrow: 1,
        },
        '&.refresh': {
          width: 120,
        },
      },
    },
  },
}))

const Header = () => {
  const classes = useStyles()
  const { language } = useAppContext()

  return (
    <div className={classes.root}>
      <div className='headingContainer'>
        <div className='item order'>#</div>
        <div className='item name'>{t.name[language]}</div>
        <div className='item type'>{t.type[language]}</div>
        <div className='item formatType'>{t.device[language]}</div>
        <div className='item locationAddress'>
          <div>
            <p>{t.location[language]}</p>
            <p>{t.address[language]}</p>
          </div>
        </div>
        <div className='item locationAddress'>
          <div>
            <p>{t.bitrate[language]}</p>
            <p>{t.resolution[language]}</p>
          </div>
        </div>
        <div className='item quality'>{t.quality[language]}</div>
        <div className='item status'>{t.status[language]}</div>
        <div className='item refresh' />
      </div>
    </div>
  )
}

export default Header
