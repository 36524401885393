import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useAppContext } from '../App/AppContext'
import { translations as t } from '../../services/translations'
// import { AuthContext } from '../App/AuthContext'
// Menu Icons
import Dashboard from '@mui/icons-material/Dashboard'
import Sensors from '@mui/icons-material/Sensors'
import Output from '@mui/icons-material/Output'
// import PersonalVideo from '@mui/icons-material/PersonalVideoOutlined'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Layers from '@mui/icons-material/Layers'

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  active: {
    '& .MuiListItem-button': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      boxShadow: '0 0 10px 0 #00000026',
      '&.MuiListItem-root': {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
  listItem: {
    '&.MuiListItem-root': {
      paddingTop: '10px',
      paddingBottom: '10px',
      minHeight: 72,
      borderLeft: '2px solid transparent',
    },
    '&.MuiListItem-button:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      boxShadow: '0 0 10px 0 #00000026',
      // borderLeft: `2px solid #5d5fba`,
      //borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
    '&.MuiListItem-gutters': {
      paddingLeft: '20px',
    },
  },
  listIcon: {
    '&.MuiListItemIcon-root': {
      color: '#fff',
      minWidth: '48px',
    },
  },
  menuText: {
    fontSize: '14px',
  },
  animationBefore: {
    opacity: '0',
    transform: 'translateY(-50%) scaleY(0)',
    // transform: 'translateY(-100px)',
    transition: 'none',
    margin: 'auto',
  },
  animationAfter: {
    opacity: '1',
    transform: 'translateY(0) scaleY(1)',
    transition: 'all 0.7s ease-in-out',
    margin: 'auto 0',
  },
}))

const SideMenu = () => {
  const classes = useStyles()
  const { language } = useAppContext()

  const links = [
    { url: '/dashboard', label: `${t.dashboard[language]}`, Component: Dashboard },
    { url: '/inputs', label: `${t.inputs[language]}`, Component: ExitToApp },
    {
      url: '/external-inputs',
      label: `${t.externalInputs[language]}`,
      Component: ExitToApp,
    },
    // {
    //   url: '/input-transfers',
    //   label: `${t.inputTransfers[language]}`,
    //   Component: PersonalVideo,
    // },
    { url: '/outputs', label: `${t.outputs[language]}`, Component: Output },
    { url: '/presets', label: `${t.presets[language]}`, Component: Layers },
    // {
    //   url: '/available-channels',
    //   label: (
    //     <span>
    //       {t.availableChannels[language].split(' ')[0]}
    //       <br />
    //       {t.availableChannels[language].split(' ')[1]}
    //     </span>
    //   ),
    //   Component: PersonalVideo,
    // },
    {
      url: '/internal-connections',
      label: (
        <span>
          {t.internalConnections[language].split(' ')[0]}
          <br />
          {t.internalConnections[language].split(' ')[1]}
        </span>
      ),
      Component: Sensors,
    },
    {
      url: '/external-connections',
      label: (
        <span>
          {t.externalConnections[language].split(' ')[0]}
          <br />
          {t.externalConnections[language].split(' ')[1]}
        </span>
      ),
      Component: Sensors,
    },
    // {
    //   url: '/inbound-transfer-requests',
    //   label: (
    //     <span>
    //       {`${t.inboundTransferRequests[language].split(' ')[0]} ${
    //         t.inboundTransferRequests[language].split(' ')[1]
    //       }`}
    //       <br />
    //       {`${t.inboundTransferRequests[language].split(' ')[2]} ${
    //         language === 'uk' ? t.inboundTransferRequests[language].split(' ')[3] : ''
    //       }`}
    //     </span>
    //   ),
    //   Component: ExitToApp,
    // },
    // {
    //   url: '/outbound-transfer-requests',
    //   label: (
    //     <span>
    //       {`${t.outboundTransferRequests[language].split(' ')[0]} ${
    //         t.outboundTransferRequests[language].split(' ')[1]
    //       }`}
    //       <br />
    //       {`${t.outboundTransferRequests[language].split(' ')[2]} ${
    //         language === 'uk' ? ' ' + t.outboundTransferRequests[language].split(' ')[3] : ''
    //       }`}
    //     </span>
    //   ),
    //   Component: ExitToApp,
    // },
  ]

  // вытягиваем текущую роль юзера из контекста
  // const { role } = useContext(AuthContext)

  // блок для анимации мени при переключении кабинетов
  // состояние начла и окончания онимации
  const [animation, setAnimation] = useState(false)
  // при переключении роли сбрасывает состояние анимации на начальное
  // useEffect(() => {
  //   setAnimation(false)
  //   // }, [role])
  // }, [])
  // при обновлении состояния анимации переключает состояние на конечное
  useEffect(() => {
    // if (!animation) {
    // делаем это через таймаут, что бы обойти внутренние оптимизации реакта
    let timeout = null
    timeout = setTimeout(() => {
      setAnimation(true)
      clearTimeout(timeout)
    }, 10)
    // }
  }, [])

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      <List
        style={{ paddingTop: 0 }}
        className={clsx(classes.animationBefore, animation && classes.animationAfter)}
      >
        {links.map((link) => {
          return (
            <NavLink
              key={`key-${link.url}`}
              to={link.url}
              activeClassName={classes.active}
              className={classes.link}
            >
              <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.listIcon}>
                  <link.Component />
                </ListItemIcon>
                <ListItemText primary={link.label} classes={{ primary: classes.menuText }} />
              </ListItem>
            </NavLink>
          )
        })}
      </List>
    </div>
  )
}

export default SideMenu
