import IconButton from '@mui/material/IconButton'
import AutoRenewIcon from '@mui/icons-material/Autorenew'

function RefreshBtn(props) {
  return (
    <IconButton color='inherit' {...props} size='large'>
      <AutoRenewIcon />
    </IconButton>
  )
}

export default RefreshBtn
