import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import TextField from '../../../forms/TextField'
import CheckBox from '../../../forms/CheckBox'
import Select from '../../../forms/Select'
import SelectList from '../../../forms/SelectList'
import SubmitButton from '../../../forms/SubmitButton'
import DateTimePicker from '../../../forms/DateTimePicker'
import EventsTable from './EventsTable'
import { useAppContext } from '../../../components/App/AppContext'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 14,
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 28,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
}))

const EventsForm = ({ events, saveEvent, deleteEvent, error, tags, inputs }) => {
  const classes = useStyles()
  const { language } = useAppContext()

  const initialValues = {
    eventName: '',
    eventStart: null,
    selectInput: '',
    validFrom: null,
    eventEnd: null,
    isPermanent: true,
    validTill: null,
    tags: [],
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        saveEvent({
          ...values,
          validFrom: !values.isPermanent ? values.validFrom : null,
          validTill: !values.isPermanent ? values.validTill : null,
          id: values.id || Date.now().toString(),
        })
        helpers.resetForm()
      }}
      validationSchema={object({
        eventName: string().required(t.fieldIsRequired[language]),
        selectInput: string().required(t.fieldIsRequired[language]),
      })}
    >
      {({ values, setValues }) => {
        return (
          <Form noValidate>
            <Grid container spacing={3} className={classes.container}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <TextField label={t.eventName[language]} name='eventName' required />
                <DateTimePicker
                  name='eventStart'
                  label={t.eventStart[language]}
                  className={classes.dateTime}
                  clean
                />
                <Select label={t.selectInput[language]} name='selectInput' required fullWidth>
                  {inputs.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                </Select>
                {!values.isPermanent && (
                  <DateTimePicker
                    name='validFrom'
                    label={t.validFrom[language]}
                    className={classes.dateTime}
                    clean
                  />
                )}
              </Grid>
              {/*END - первая колонка*/}

              {/* Вторая колонка*/}
              <Grid item xs={4}>
                <DateTimePicker
                  clean
                  name='eventEnd'
                  label={t.eventEnd[language]}
                  className={classes.eventEnd}
                />
                <CheckBox
                  name='isPermanent'
                  label={t.permanentTransfer[language]}
                  className={classes.isPermanent}
                />
                {!values.isPermanent && (
                  <DateTimePicker
                    name='validTill'
                    label={t.validTill[language]}
                    className={classes.validTill}
                    clean
                  />
                )}
              </Grid>
              {/* END - Вторая колонка*/}

              {/* Третья колонка*/}
              <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                <Select label={t.tags[language]} name='tags' fullWidth multiple>
                  {tags.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                </Select>
                <SelectList name='tags' tags withoutIcon listOfValues={tags} />
                <div className={classes.buttonContainer}>
                  <SubmitButton color='primary'>
                    {values.id ? `${t.save[language]}` : `${t.add[language]}`}
                  </SubmitButton>
                </div>
              </Grid>
              {/* END - Третья колонка*/}
            </Grid>
            <EventsTable
              rows={events}
              inputs={inputs}
              tags={tags}
              onEdit={setValues}
              onDelete={deleteEvent}
              error={error}
            />
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )
      }}
    </Formik>
  )
}

export default EventsForm
