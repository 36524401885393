import makeStyles from '@mui/styles/makeStyles'
import { useAppContext } from '../../../components/App/AppContext'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
// import Tabs from '../../../components/Tabs/Tabs'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
// import Status from '../../../components/Status/Status'
import CardBody from '../../../components/Card/CardBody'
import CardItem from '../../../components/Card/CardItem'
// import RefreshBtn from '../../../components/ActionButtons/RefreshBtn/RefreshBtn'
// import InternalConnectionsTab from './ExternalInputCardTabs/InternalConnectionsTab'
import apiService from '../../../services/api-service'
import {
  capitalizeFirstLetter,
  isPrepareDeviceAttributes,
  numberFromFormatToMbps,
  prepareDeviceAttributes,
  prepareLabelDeviceAttributes,
} from '../../../services/helpers'
import { useParams } from 'react-router-dom'
import LocationMapGoogleShow from '../../../forms/LocationMapGoogleShow'
import { translations as t } from '../../../services/translations'
// import InputsTable from '../InputsTable/InputsTable'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: 'calc(50% - 15px)',
    minHeight: 250,
  },
  tabsContainer: {
    marginTop: 20,
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
}))

// const tabs = [
//   {
//     label: 'Internal connections',
//     contentTitle: '',
//     contentBody: <InternalConnectionsTab withoutPagination />,
//   },
// ]

const ExternalInputCard = () => {
  const { setCurrentPage, language } = useAppContext()
  const classes = useStyles()
  const { contextId, inputID } = useParams()

  const [data, setData] = useState({})
  const [deviceAttributes, setDeviceAttributes] = useState({
    status: null,
    live_status: {
      live: {
        isLiveProperty: false,
      },
    },
    video_info: {
      isVideoInfoProperty: false,
    },
  })

  const getData = () => {
    apiService
      .get(`/virtual-matrix/external-inputs/${inputID}`, contextId)
      .then((res) => {
        setData(res.data)

        const dev_att = JSON.parse(res.data?.device_attributes)
        if (isPrepareDeviceAttributes(dev_att)) {
          setDeviceAttributes(prepareDeviceAttributes(dev_att))
        }
      })
      .catch(console.log)
  }

  useEffect(() => setCurrentPage(t.externalInput[language]))

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <motion.div
      className={classes.root}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={classes.cardRow}>
        <div className={classes.cardContainer}>
          <Card>
            <CardTitle title={data?.transfer_request_event?.name}>
              {/*<Status status='Active' />*/}
              {/*<RefreshBtn style={{ marginRight: -18 }} />*/}
            </CardTitle>
            <CardBody>
              <CardItem label={t.request[language]} value={data?.transfer_request?.title} />
              <CardItem label={t.input[language]} value={data?.input?.name} />
              <CardItem label={t.owner[language]} value={data?.owner?.name} />
              <CardItem label={t.validFrom[language]} value={data?.start_time} />
              <CardItem label={t.validTill[language]} value={data?.end_time} />
              <CardItem label={t.type[language]} value={data?.input?.input_type} />
              <CardItem
                label={t.device[language]}
                value={capitalizeFirstLetter(data?.input?.type)}
              />
              <CardItem label={t.formatType[language]} value={data?.input?.stream_type} />
              <CardItem label={t.location[language]} value={data?.input?.location?.name} />
              <CardItem label={t.address[language]} value={data?.input?.address} />
              {data?.input?.location?.id && (
                <LocationMapGoogleShow
                  coordinates={{ lat: data?.input?.location?.lat, lng: data?.input?.location?.lng }}
                  mapContainerStyle={{
                    minWidth: '250px',
                    width: '100%',
                    height: '180px',
                  }}
                />
              )}
            </CardBody>
          </Card>
        </div>
        {deviceAttributes?.live_status?.isLiveProperty ||
        deviceAttributes?.video_info?.isVideoInfoProperty ? (
          <div className={classes.cardContainer}>
            <Card className={classes.cardContainerWithoutHeight}>
              <CardTitle title={'Current device info'}></CardTitle>
              <CardBody>
                {deviceAttributes?.live_status?.live?.result !== null &&
                  typeof deviceAttributes?.live_status?.live?.result === 'number' && (
                    <CardItem
                      label={t.state[language]}
                      value={prepareLabelDeviceAttributes(
                        deviceAttributes?.live_status.live.result,
                      )}
                    />
                  )}
                {deviceAttributes?.status !== null &&
                  typeof deviceAttributes?.status === 'number' && (
                    <CardItem
                      label={t.state[language]}
                      value={prepareLabelDeviceAttributes(deviceAttributes?.status)}
                    />
                  )}
                {deviceAttributes?.live_status?.live?.cur_bps !== null &&
                  typeof deviceAttributes?.live_status?.live?.cur_bps === 'number' && (
                    <CardItem
                      label={t.currentBitrateMbps[language]}
                      value={numberFromFormatToMbps(
                        deviceAttributes?.live_status.live.cur_bps,
                        'ultra',
                      )}
                    />
                  )}
                {deviceAttributes?.video_info?.width !== null &&
                  typeof deviceAttributes?.video_info?.width === 'number' && (
                    <CardItem
                      label={t.width[language]}
                      value={deviceAttributes?.video_info?.width}
                    />
                  )}
                {deviceAttributes?.video_info?.height !== null &&
                  typeof deviceAttributes?.video_info?.height === 'number' && (
                    <CardItem
                      label={t.height[language]}
                      value={deviceAttributes?.video_info?.height}
                    />
                  )}
                {deviceAttributes?.video_info?.scan !== null && (
                  <CardItem
                    label={t.scan[language]}
                    value={capitalizeFirstLetter(deviceAttributes?.video_info?.scan)}
                  />
                )}
                {deviceAttributes?.video_info?.field_rate !== null &&
                  typeof deviceAttributes?.video_info?.field_rate === 'number' && (
                    <CardItem
                      label={t.frameRate[language]}
                      value={deviceAttributes?.video_info?.field_rate}
                    />
                  )}
              </CardBody>
            </Card>
          </div>
        ) : null}
      </div>
      <div className={classes.tabsContainer}>{/*<Tabs data={tabs} resizable />*/}</div>
    </motion.div>
  )
}

export default ExternalInputCard
