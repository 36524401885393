import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'

import { useAppContext } from '../../../components/App/AppContext'
import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'
import SubmitButton from '../../../forms/SubmitButton'
import TextField from '../../../forms/TextField'
import apiService from '../../../services/api-service'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 1100,
    margin: '0 auto',
    padding: '48px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 20,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
}))

const EditInput = ({ contextAccountId, onOk }) => {
  const classes = useStyles()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const initialValues = {
    name: buffer?.name || '',
    url: buffer?.url || '',
    address: buffer?.address || '',
  }

  return (
    <FullScreenModal id={`editInput${contextAccountId}`} title={`${t.edit[language]}`}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const reqBody = {
            name: values.name,
            address: values.address,
            // sort: 0,
            // is_technical: true,
            url: values.url,
          }

          const request = apiService.patch(
            `/virtual-matrix/inputs/${buffer.id}`,
            reqBody,
            contextAccountId,
          )

          return request
            .then(() => {
              onOk()
              closeFullScreenModal()
            })
            .catch(console.log)
        }}
        validationSchema={object({
          name: string().required(`${t.fieldIsRequired[language]}`),
          // address: string().required(`${t.fieldIsRequired[language]}`),
          url: string().required(`${t.fieldIsRequired[language]}`),
        })}
      >
        {({ isSubmitting }) => (
          <Form noValidate className={classes.formContainer}>
            <Grid container spacing={5} /*style={{ minHeight: '600px' }}*/>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <TextField label={t.name[language]} name='name' required multiline />
              </Grid>
              {/*END - первая колонка*/}

              {/* Средняя колонка*/}
              <Grid item xs={8}>
                <TextField label={t.address[language]} name='address' multiline />
              </Grid>
              {/* END - Средняя колонка*/}

              {/* Третья колонка*/}
              <Grid item xs={14}>
                <TextField label={t.link[language]} name='url' required multiline />
              </Grid>
              {/* END - Третья колонка*/}
            </Grid>
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
            {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default EditInput
