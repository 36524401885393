import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

const TableRow = ({ item, index, children }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            {children(snapshot.isDragging)}
          </div>
        )
      }}
    </Draggable>
  )
}

export default TableRow
