import clsx from 'clsx'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'

import TableBody from '../../../components/Table/TableBody'
import TableCell from '../../../components/Table/TableCell'
import TableHead from '../../../components/Table/TableHead'
import Table from '../../../components/Table/Table'
import Tags from '../../../components/Tags/Tags'
import Dates from '../../../components/Dates/Dates'
import useSorting from '../../../services/sorting'
import AddBtn from '../../../components/AddBtn/AddBtn'
import EditBtn from '../../../components/ActionButtons/EditBtn/EditBtn'
import DeleteBtn from '../../../components/ActionButtons/DeleteBtn/DeleteBtn'
import { useAppContext } from '../../../components/App/AppContext'
import Status from '../../../components/Status/Status'
import AcceptBtn from '../../../components/ActionButtons/AcceptBtn/AcceptBtn'
import ClearBtn from '../../../components/ActionButtons/ClearBtn/ClearBtn'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: (props) => (props.large ? '#303B5F' : '#2b375a'),
    },
    minWidth: 500,
    '& tr>th.MuiTableCell-root': {
      paddingTop: 11,
      paddingBottom: 11,
    },
    '& .doubleLine > p': {
      lineHeight: '18px',
      fontWeight: 500,
      whiteSpace: 'nowrap,',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noEvents: {
    display: 'grid',
    placeItems: 'center',
    flexGrow: 1,
    fontSize: 36,
    fontWeight: 500,
    color: 'rgba(255,255,255,0.2)',
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  tableContainer: {
    overflowY: 'scroll',
    paddingRight: (props) => (props.large ? 36 : 16),
    display: 'flex',
    flexDirection: 'column',
    height: (props) => (props.large ? '100%' : 220),
    '&::-webkit-scrollbar': {
      width: '0.50em',
      height: '0.75em',
      backgroundColor: 'transparent',
    },
    '& *::-webkit-scrollbar': {
      width: '0.75em',
      height: '0.75em',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: 'inherit',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: 'inherit',
    },
  },
  borderRadiusCell: {
    backgroundColor: (props) => (props.large ? '#303B5F' : '#2b375a'),
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
}))

const EventsTable = ({ rows, inputs, tags, onAccept, onEdit, onDelete, error, ...props }) => {
  const classes = useStyles(props)
  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')
  const { openFullScreenModal, language } = useAppContext()

  const startEnd = (
    <div className='doubleLine'>
      <p>{t.start[language]}</p>
      <p>{t.end[language]}</p>
    </div>
  )
  const validFromValidTill = (
    <div className='doubleLine'>
      <p>{t.validFrom[language]}</p>
      <p>{t.validTill[language]}</p>
    </div>
  )

  const headCells = [
    [
      {
        id: 'eventName',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.event[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: props.large ? '#29355A' : '#242f53' } },
      },
      { id: 'startEnd', numeric: false, label: startEnd },
      { id: 'input', numeric: false, label: `${t.input[language]}` },
      { id: 'validFromValidTill', numeric: false, label: validFromValidTill },
      { id: 'tags', numeric: false, label: `${t.tags[language]}` },
      { id: 'status', numeric: false, label: `${t.status[language]}` },
      {
        id: 'editRemove',
        numeric: false,
        label:
          props.large && !props.inbound ? (
            <AddBtn onClick={() => openFullScreenModal('addEvent')} />
          ) : (
            <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellRight)} />
          ),
        props: {
          width: props.inbound ? 70 : 140,
          style: { padding: 0, backgroundColor: props.large ? '#29355A' : '#242f53' },
        },
      },
    ],
  ]

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.root} stickyHeader>
        <TableHead
          headCells={props.inbound ? headCells : [headCells[0].filter((el) => el.id !== 'status')]}
          createSortHandler={createSortHandler}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell component='th' scope='row'>
                  {row.eventName || row.name}
                </TableCell>
                <TableCell>
                  <Dates
                    upperDate={row.eventStart || row.event_start_time}
                    lowerDate={row.eventEnd || row.event_end_time}
                  />
                </TableCell>
                <TableCell>
                  {inputs ? inputs.find((el) => el.id === row.selectInput)?.name : row.input.name}
                </TableCell>
                <TableCell>
                  <Dates
                    upperDate={row.validFrom || row.start_time}
                    lowerDate={row.validTill || row.end_time}
                  />
                </TableCell>
                <TableCell>
                  <Tags
                    labels={
                      tags
                        ? row.tags.map((tagId) => tags.find((el) => tagId === el.id)?.name)
                        : row.tags.map((tag) => tag.name)
                    }
                  />
                </TableCell>
                {props.inbound && (
                  <TableCell>
                    <Status status={row.status} />
                  </TableCell>
                )}
                <TableCell>
                  <div className={classes.buttonTdContainer}>
                    {props.inbound ? (
                      row.status !== 'accepted' && <AcceptBtn onClick={onAccept.bind(this, row)} />
                    ) : (
                      <EditBtn onClick={onEdit.bind(this, row)} />
                    )}
                    {props.inbound ? (
                      <ClearBtn />
                    ) : (
                      <DeleteBtn onClick={onDelete.bind(this, row)} />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {!rows.length && (
        <h4 className={clsx(classes.noEvents, error && 'error')}>
          {error ? t.addStLeastOneEvent[language] : t.noEventsYet[language]}
        </h4>
      )}
    </TableContainer>
  )
}

export default EventsTable
