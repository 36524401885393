import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    padding: '3px 10px',
    fontSize: 13,
    borderRadius: 12,
    textTransform: 'capitalize',
  },
}))

const Status = ({ status, ...props }) => {
  const classes = useStyles()

  let color
  switch ((status || '').toLowerCase()) {
    case 'online':
      color = 'rgba(98, 198, 115, 0.7)'
      break
    case 'awaiting':
      color = 'rgba(244, 164, 22, 0.7)'
      break
    case 'offline':
      color = 'rgba(237,45,91, 0.9)'
      break
    case 'unknown':
      color = 'rgba(144,144,144, 0.4)'
      break
    case 'disconnected':
    case 'canceled':
    case 'rejected':
      color = 'rgba(46, 46, 46, 0.7)'
      break
    default:
      color = 'rgba(98, 198, 115, 0.7)'
  }

  return (
    <span className={classes.root} style={{ backgroundColor: color, ...props.style }}>
      {status}
    </span>
  )
}

export default Status
