import { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { motion } from 'framer-motion'

import { useAppContext } from '../../../components/App/AppContext'
import Tabs from '../../../components/Tabs/Tabs'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
import CardBody from '../../../components/Card/CardBody'
import CardItem from '../../../components/Card/CardItem'
import EventsTable from '../../OutboundTransferRequests/AddTransferRequest/EventsTable'
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog'
import apiService from '../../../services/api-service'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: 'calc(50% - 15px)',
    minHeight: 290,
  },
  tabsContainer: {
    marginTop: 40,
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
}))

function InboundRequestCard() {
  const {
    setCurrentPage,
    language,
    openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()
  const classes = useStyles()
  // const history = useHistory()
  const { contextId, requestId } = useParams()

  const [data, setData] = useState({})

  const getData = () => {
    Promise.all([
      apiService.get(`/virtual-matrix/transfer-requests/inbound/${requestId}`, contextId),
      apiService.get(
        `/virtual-matrix/transfers/inbound?filter[transfer_request]=${requestId}`,
        contextId,
      ),
    ])
      .then(([res1, res2]) => {
        const transfers = res1.data.events.map((el) => {
          const status =
            res2.data.find((e) => e.transfer_request_event.id === el.id)?.status || 'awaiting'
          return {
            ...el,
            status,
          }
        })
        setData({ ...res1.data, events: transfers })
      })
      .catch(console.log)
  }

  useEffect(() => {
    setCurrentPage(t.inboundTransferRequest[language])
    getData()
    // eslint-disable-next-line
  }, [])

  const handleAccept = ({ id }) => {
    const username = apiService.getCredential().username
    return apiService
      .post(`/virtual-matrix/transfer-requests/events/${id}/accept`, { username }, contextId)
      .then((res) => {
        getData()
      })
      .catch(console.log)
  }

  const tabs = [
    {
      label: `${t.transfers[language]}`,
      contentTitle: '',
      contentBody: (
        <EventsTable
          rows={data.events || []}
          onAccept={(item) => openFullScreenModal('acceptEvent', item)}
          large
          inbound
        />
      ),
    },
  ]

  return (
    <>
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.doYouWantToConfirmThisAction[language]}
        onOk={handleAccept.bind(this, buffer)}
        id='acceptEvent'
      />
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={classes.root}
      >
        <div className={classes.cardRow}>
          <div className={classes.cardContainer}>
            <Card>
              <CardTitle title={data.title} />
              <CardBody>
                <CardItem label={t.owner[language]} value={data.owner?.name} />
                <CardItem
                  label={t.created[language]}
                  value={
                    data.created_at ? format(new Date(data.created_at), 'dd/MM/yyyy HH:mm') : ''
                  }
                />
                <CardItem label={t.description[language]} value={data.description} vertical />
              </CardBody>
            </Card>
          </div>
        </div>
        <div className={classes.tabsContainer}>
          <Tabs data={tabs} resizable />
        </div>
      </motion.div>
    </>
  )
}

export default InboundRequestCard
