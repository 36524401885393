import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import makeStyles from '@mui/styles/makeStyles'
import { Grid } from '@mui/material'

import FullScreenModal from '../../components/FullScreenModal/FullScreenModal'
import TextField from '../../forms/TextField'
import SubmitButton from '../../forms/SubmitButton'
import apiService from '../../services/api-service'
import { useAppContext } from '../../components/App/AppContext'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 1100,
    margin: '0 auto',
    padding: '48px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 20,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
}))

const AddPreset = ({ accountId, onOk }) => {
  const classes = useStyles()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const initialValues = {
    name: buffer?.name || '',
    url: buffer?.url || '',
    description: buffer?.description || '',
  }

  return (
    <FullScreenModal
      id={`addPreset${accountId}`}
      title={buffer ? t.editPreset[language] : t.newPreset[language]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const preset = {
            name: values.name,
            url: values.url,
            description: values.description,
          }

          const request = buffer
            ? apiService.patch(`/virtual-matrix/presets/${buffer.id}`, preset, accountId)
            : apiService.post(`/virtual-matrix/presets`, preset, accountId)

          return request
            .then(() => {
              onOk()
            })
            .catch(console.log)
            .finally(() => {
              closeFullScreenModal()
            })
        }}
        validationSchema={object({
          name: string().required(t.fieldIsRequired[language]),
          url: string().required(t.fieldIsRequired[language]),
        })}
      >
        {({ isSubmitting }) => (
          <Form noValidate className={classes.formContainer}>
            <Grid container spacing={5} style={{ minHeight: '600px' }}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <TextField label={t.name[language]} name='name' required />
              </Grid>
              {/*END - первая колонка*/}

              {/* Средняя колонка*/}
              <Grid item xs={4}>
                <TextField label={t.link[language]} name='url' required />
              </Grid>
              {/* END - Средняя колонка*/}

              {/* Третья колонка*/}
              <Grid item xs={4}>
                <TextField multiline label={t.description[language]} name='description' />
              </Grid>
              {/* END - Третья колонка*/}
            </Grid>
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
            {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default AddPreset
