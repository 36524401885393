import { useField } from 'formik'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import SelectMui from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  formControl: {
    // marginTop: theme.spacing(2),
    marginTop: 16,
    marginBottom: 0,
    // marginBottom: 15,
    // marginBottom: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%', //420,
    '& svg': {
      fill: theme.palette.text.dark,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.dark,
    },
    '& .MuiInput-underline': {
      '& button': {
        padding: 12,
        '& button': {
          padding: '1px 5px',
        },
      },

      '&:before': {
        borderColor: theme.palette.text.dark,
      },
      '&:hover:not(.Mui-disabled):before, &:after': {
        borderColor: theme.palette.text.dark,
      },
    },
    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline': {
      '& button': {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,

        '& button': {
          pointerEvents: 'all',
          right: '15px !important',
        },
      },
    },
    '& label': {
      marginTop: 5,
      marginLeft: -14,
      color: theme.palette.text.dark,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left',
      paddingRight: ({ clean }) => (clean ? 36 : 24),
    },
    '& .MuiSelect-select.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input': {
      background: 'transparent',
    },
  },
  select: {
    '&.MuiPaper-root': {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important',
      maxWidth: 419,
      // minHeight: '100px',
      // height: '100%',
      // maxHeight: 288,
    },
    '& .MuiList-root': {
      // position: 'absolute',
      '& li': {
        padding: '5px 10px',
        whiteSpace: 'pre-line',
        borderBottom: '1px solid transparent',
      },
      '& li.Mui-selected': {
        borderBottom: `1px solid ${theme.palette.background.default}`,
      },
    },
    backgroundColor: theme.palette.background.default,
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.primary.mainLight,
    },
    maxHeight: 320,
    '&::-webkit-scrollbar': {
      width: '0.40em',
      height: '0.75em',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // width
    '& *::-webkit-scrollbar': {
      width: 7,
      // width: '0.40em',
      height: '0.75em',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // Track
    '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
      // box-shadow: inset 0 0 5px grey
      borderRadius: '5px',
      // backgroundColor: '#34426C',
      backgroundColor: 'inherit',
    },
    // Handle
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      // backgroundColor: '#6565cf',
      borderRadius: '5px',
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
    },
  },
  error: {
    '& svg': {
      fill: theme.palette.error.main,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.error.main,
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderColor: theme.palette.error.main,
      },
      '&:hover:not(.Mui-disabled):before, &:after': {
        borderColor: theme.palette.error.main,
      },
    },
  },
  clearBtn: {
    display: 'none',
    position: 'absolute',
    // right: '15px !important',
    top: -5,
    zIndex: 1000,
    flexShrink: '0 !important',
    '&.MuiIconButton-root': {
      // padding: 3,
    },
    '& .MuiSvgIcon-root': {
      flexShrink: '0 !important',
      width: '20px',
      height: '20px',
    },
  },
  hoverIsShowClearBtn: {
    '& div.MuiInputBase-root.MuiInput-root.MuiInput-underline:hover': {
      '& button': {
        '& button': {
          display: 'block',
        },
      },
    },
  },
}))

const Select = ({ label, children, ...props }) => {
  const classes = useStyles()
  const [field, meta] = useField(props)

  const error = Boolean(meta.touched && meta.error)

  const onChange = (e) => {
    field.onChange(e)
    if (props.onChange) props.onChange(e)
  }

  return (
    <FormControl
      error={error}
      className={clsx(classes.formControl, props.className, error && classes.error)}
      style={props.style}
      margin='normal'
      fullWidth={props.fullWidth}
      classes={{ root: classes.label }}
    >
      <InputLabel id={`${props.name}-labelId`} required={props.required}>
        {label}
      </InputLabel>
      <SelectMui
        {...field}
        onChange={onChange}
        error={error}
        multiple={props.multiple}
        id={`${props.name}-id`}
        labelId={`${props.name}-labelId`}
        disabled={props.disabled}
        MenuProps={{ classes: { paper: classes.select } }}
        variant='standard'
      >
        {children.map((el) => (
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </SelectMui>
      <FormHelperText error sx={{ marginLeft: 0, marginRight: 0 }}>
        {error ? meta.error : ' '}
      </FormHelperText>
    </FormControl>
  )
}

export default Select
