import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { object, string } from 'yup'

import { useAppContext } from '../../../components/App/AppContext'
import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'
import AutocompleteSingleCheckbox from '../../../forms/AutocompleteSingleCheckbox'
import SubmitButton from '../../../forms/SubmitButton'
import apiService from '../../../services/api-service'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 1320,
    margin: '0 auto',
    padding: '20px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 36,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
  posterItem: {
    marginBottom: 26,
    marginRight: 20,
  },
}))

const EditSettings = ({ onOk, inputId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { buffer, open, id },
  } = useAppContext()

  const [dictionary, setDictionary] = useState({})

  const initialValues = {
    resolution: buffer ? String(buffer?.width + buffer?.height) : '',
    duration: buffer ? String(buffer?.duration?.value) : '',
    bitrate: buffer ? String(buffer?.bitrate?.value) : '',
    codec: buffer ? String(buffer?.codec?.value) : '',
  }

  useEffect(
    () => {
      if (open && id === 'editSettings') {
        apiService
          .get(`/virtual-matrix/inputs/magewell-ultra/${inputId}/settings-dictionary`)
          .then((res) => {
            setDictionary({
              resolutions: res.data.resolutions
                .map((el) => ({
                  ...el,
                  id: (el.w + el.h).toString(),
                  label: `${el.w} x ${el.h}`,
                }))
                .reverse(),
              durations: res.data.durations
                .map((el) => ({
                  ...el,
                  id: el.value.toString(),
                  label: el.name,
                }))
                .reverse(),
              bitrates: res.data.bitrates
                .map((el) => ({
                  ...el,
                  id: el.value.toString(),
                  label: el.name,
                }))
                .reverse(),
              codecs: res.data.codecs
                .map((el) => ({
                  ...el,
                  id: el.value.toString(),
                  label: el.name,
                }))
                .reverse(),
            })
          })
          .catch(console.log)
      }
    },
    // eslint-disable-next-line
    [open],
  )

  return (
    <FullScreenModal
      id='editSettings'
      title={buffer ? t.editSettings[language] : t.newSettings[language]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const valueResolution = dictionary.resolutions.find((el) => el?.id === values?.resolution)
          const reqBody = {
            width: Number(valueResolution.w),
            height: Number(valueResolution.h),
            codec: Number(values.codec),
            duration: Number(values.duration),
            bitrate: Number(values.bitrate),
          }
          const request = buffer
            ? apiService.put(`/virtual-matrix/inputs/magewell-ultra/settings/${buffer.id}`, reqBody)
            : apiService.post(`/virtual-matrix/inputs/magewell-ultra/${inputId}/settings`, reqBody)
          return request
            .then((res) => {
              onOk()
              closeFullScreenModal()
            })
            .catch((e) => {
              console.log(e)
              enqueueSnackbar(e.response?.data.error || e.message, {
                variant: 'error',
              })
            })
        }}
        validationSchema={object({
          resolution: string().required(t.fieldIsRequired[language]),
          duration: string().required(t.fieldIsRequired[language]),
          bitrate: string().required(t.fieldIsRequired[language]),
          codec: string().required(t.fieldIsRequired[language]),
        })}
      >
        {({ isSubmitting, values }) => (
          <Form noValidate className={classes.formContainer}>
            <Grid container spacing={5} style={{ minHeight: '600px' }}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <AutocompleteSingleCheckbox
                  name='resolution'
                  label={t.resolution[language]}
                  options={dictionary.resolutions || []}
                  language={language}
                  required
                />
                <AutocompleteSingleCheckbox
                  name='duration'
                  label={t.frameRate[language]}
                  options={dictionary.durations || []}
                  language={language}
                  required
                />
              </Grid>
              {/*END - первая колонка*/}
              {/* средняя колонка*/}
              <Grid item xs={4}>
                <AutocompleteSingleCheckbox
                  name='bitrate'
                  label={t.bitrate[language]}
                  options={dictionary.bitrates || []}
                  language={language}
                  required
                />
                <AutocompleteSingleCheckbox
                  name='codec'
                  label={t.codec[language]}
                  options={dictionary.codecs || []}
                  language={language}
                  required
                />
              </Grid>
              {/*END - средняя колонка*/}
              {/* третья колонка*/}
              <Grid item xs={4}></Grid>
              {/*END - третья колонка*/}
            </Grid>
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default EditSettings
