import { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useAppContext } from '../../../components/App/AppContext'
import Tabs from '../../../components/Tabs/Tabs'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
import Status from '../../../components/Status/Status'
import CardBody from '../../../components/Card/CardBody'
import CardItem from '../../../components/Card/CardItem'
// import InputsTable from '../InputsTable/InputsTable'
import apiService from '../../../services/api-service'
import ClearBtn from '../../../components/ActionButtons/ClearBtn/ClearBtn'
import InternalConnectionsTab from './InnerTabs/InternalConnectionsTab'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: 'calc(33% - 20px)',
    minHeight: 250,
  },
  tabsContainer: {
    marginTop: 40,
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
}))

const InputTransfersCard = () => {
  const { setCurrentPage, language } = useAppContext()
  const classes = useStyles()

  const { contextId, transferID } = useParams()

  const [data, setData] = useState({})

  const getData = () => {
    apiService
      .get(`/virtual-matrix/transfers/input/${transferID}`, contextId)
      .then((res) => {
        setData(res.data)
      })
      .catch(console.log)
  }

  useEffect(() => setCurrentPage(t.inputTransfer[language]))

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const tabs = [
    {
      label: t.internalConnections[language],
      contentTitle: '',
      contentBody: <InternalConnectionsTab />,
    },
  ]

  return (
    <motion.div
      className={classes.root}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={classes.cardRow}>
        <div className={classes.cardContainer}>
          <Card>
            <CardTitle title={data.title}>
              <Status status='Active' />
              <ClearBtn style={{ marginRight: -18 }} />
            </CardTitle>
            <CardBody>
              <CardItem label={t.recipient[language]} value='ТОВ "Космонова"' />
              <CardItem label={t.validFrom[language]} value='SDI' />
              <CardItem label={t.validTill[language]} value='RTMP' />
            </CardBody>
          </Card>
        </div>
        <div className={classes.cardContainer}>
          <Card>
            <CardTitle title={t.event[language]} />
            <CardBody>
              <CardItem label={t.title[language]} value='ТОВ "Космонова"' />
              <CardItem label={t.start[language]} value='SDI' />
              <CardItem label={t.end[language]} value='RTMP' />
              <CardItem label={t.tags[language]} value='03038, Kiev, N.Grinchenka 2/1' vertical />
            </CardBody>
          </Card>
        </div>
        <div className={classes.cardContainer}>
          <Card>
            <CardTitle title={t.transferRequest[language]} />
            <CardBody>
              <CardItem label={t.title[language]} value='34 000 kbps' />
              <CardItem label={t.created[language]} value='34 000 kbps' />
              <CardItem label={t.description[language]} value='34 000 kbps' vertical />
            </CardBody>
          </Card>
        </div>
      </div>
      <div className={classes.tabsContainer}>
        <Tabs data={tabs} />
      </div>
    </motion.div>
  )
}

export default InputTransfersCard
