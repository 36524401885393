import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    listStyle: 'none',
  },
  item: {
    fontSize: 16,
    display: 'block',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    padding: '6px 0',
    '&:last-child': {
      border: 'none',
    },
  },
}))

const Recipients = ({ recipients }) => {
  const classes = useStyles()

  return (
    <ul className={classes.root}>
      {recipients?.map((el) => (
        <li className={classes.item} key={el.id}>
          {el.name}
        </li>
      ))}
    </ul>
  )
}

export default Recipients
