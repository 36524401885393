import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import apiService from '../../../services/api-service'
import { useAppContext } from '../../../components/App/AppContext'
import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'
import EventsForm from './EventsForm'
import RequestForm from './RequestForm'
import CardBody from '../../../components/Card/CardBody'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 30px',
    position: 'relative',
    maxWidth: 1440,
    margin: '0 auto',
  },
  cardContainer: {
    marginTop: 14,
  },
}))

const AddTransferRequest = ({ accountId }) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { open, buffer },
  } = useAppContext()

  const [accounts, setAccounts] = useState([])
  const [tags, setTags] = useState([])
  const [inputs, setInputs] = useState([])
  const [noEventsError, setNoEventsError] = useState(false)

  const [formData, setFormData] = useState({
    events: [],
    submitted: false,
  })

  const [isSubmitting, setSubmitting] = useState(false)

  const saveEvent = (event) => {
    const eventsUpd = formData.events.filter((el) => el.id !== event.id)
    setFormData({ ...formData, events: [...eventsUpd, event] })
  }
  const deleteEvent = (event) => {
    const eventsUpd = formData.events.filter((el) => el.id !== event.id)
    setFormData({ ...formData, events: [...eventsUpd] })
  }

  const addFormData = (values) => {
    setFormData({ ...formData, ...values, submitted: true })
  }

  useEffect(() => {
    if (open && buffer === accountId) {
      Promise.all([
        apiService.get('/virtual-matrix/accounts'),
        apiService.get('/virtual-matrix/tags'),
        apiService.get('/virtual-matrix/inputs', accountId),
      ])
        .then(([res1, res2, res3]) => {
          setAccounts(res1.data.filter((el) => el.id !== accountId))
          setTags(res2.data)
          setInputs(res3.data)
        })
        .catch(console.log)
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (formData.submitted) {
      if (formData.events.length === 0) {
        setFormData({ ...formData, submitted: false })
        setNoEventsError(true)
      } else {
        const request = {
          title: formData.title,
          description: formData.description,
          with_confirmation: formData.isConfirmation,
          [formData.isAll ? 'excludes' : 'includes']: formData.recipients,
        }
        setSubmitting(true)
        let newRequestId
        apiService
          .post('/virtual-matrix/transfer-requests', request, buffer.accountId)
          .then(({ data }) => {
            return formData.events.map((ev) => {
              const event = {
                name: ev.eventName,
                event_start_time: ev.eventStart,
                event_end_time: ev.eventEnd,
                start_time: ev.isPermanent ? null : ev.validFrom,
                end_time: ev.isPermanent ? null : ev.validTill,
                input: ev.selectInput,
                tags: ev.tags,
              }
              newRequestId = data.id
              return apiService.post(
                `/virtual-matrix/transfer-requests/${newRequestId}/events`,
                event,
              )
            })
          })
          .then(() => {
            setSubmitting(false)
            closeFullScreenModal()
            history.push(`/outbound-transfer-requests/${accountId}/${newRequestId}`)
          })
          .catch((e) => {
            setSubmitting(false)
            closeFullScreenModal()
            console.log(e)
          })
      }
    } else if (formData.events.length) {
      setNoEventsError(false)
    }
    // eslint-disable-next-line
  }, [formData])

  useEffect(() => {
    setFormData({ events: [], submitted: false })
    setNoEventsError(false)
  }, [open])

  return (
    <FullScreenModal id={`addTransferRequest${accountId}`} title={t.newTransferRequest[language]}>
      <div className={classes.root}>
        <Grid container spacing={5}>
          {/* первая колонка*/}
          <Grid item xs={3}>
            <RequestForm
              addFormData={addFormData}
              isSubmitting={isSubmitting}
              recipients={accounts}
            />
          </Grid>
          {/*END - первая колонка*/}

          {/* Вторая колонка*/}
          <Grid item xs={9}>
            <Card className={classes.cardContainer}>
              <CardTitle title={t.events[language]} />
              <CardBody style={{ paddingRight: 0 }}>
                <EventsForm
                  events={formData.events}
                  inputs={inputs}
                  tags={tags}
                  saveEvent={saveEvent}
                  deleteEvent={deleteEvent}
                  error={noEventsError}
                />
              </CardBody>
            </Card>
          </Grid>
          {/* END - Вторая колонка*/}
        </Grid>
      </div>
    </FullScreenModal>
  )
}

export default AddTransferRequest
