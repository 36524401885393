import { useRef, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ExitToApp from '@mui/icons-material/ExitToApp'
import { Popover, Stack, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { alpha, styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import apiService from '../../services/api-service'
import { translations as t } from '../../services/translations'
import { useAppContext } from '../App/AppContext'

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    // background: theme.palette.background.paper,
    background: '#1e2a4f', //'#364473', //'#1e2a4f !important',
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}))
const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  popperNav: {
    '& .MuiPaper-root': {
      marginTop: 5,
      marginRight: 16,
      backgroundColor: theme.palette.background.color1,
      width: 245,
      padding: '8px 0',
      '& ul': {
        overflow: 'auto',
        maxHeight: 500,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '&::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: '-18px',
        left: '208px',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '12px',
        borderColor: `transparent transparent ${theme.palette.background.color1} transparent`,
      },
    },
  },
  menuLine: {
    width: '100%',
    // height: 2,
    borderBottom: '2px solid white',
  },
  dropdownItem: {
    fontSize: '12px',
    //padding: '5px 0px 5px 8px', //5px 0px 5px 8px || '10px 16px 10px 12px'
    margin: '2px 0px',
    //borderRadius: '2px',
    // WebkitTransition: 'all 150ms linear',
    // MozTransition: 'all 150ms linear',
    // OTransition: 'all 150ms linear',
    // MsTransition: 'all 150ms linear',
    // transition: 'all 150ms linear',
    // display: 'block',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#fff',
    whiteSpace: 'normal',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.mainLight} !important`,
    },
  },
  dropdownItemClose: {
    position: 'absolute',
    right: '5px',
    top: '10px',
    fontSize: '14px',
  },
  lkLink: {
    display: 'flex',
    flexDirection: 'column',
    '& .heading': {
      fontSize: '14px',
      maxWidth: 190,
      overflow: 'hidden',
    },
    '& .subheading': {
      fontSize: '12px',
      opacity: '0.6',
    },
  },
  icon: {
    color: 'white !important',
  },
}))

const Person = () => {
  const classes = useStyles()
  const { language } = useAppContext()
  const [open, setOpen] = useState(false)
  // const [openNotifications, setOpenNotifications] = useState(null)
  const anchorRef = useRef(null)

  // const handleClickNotifications = (event) => {
  //   if (openNotifications && openNotifications.contains(event.target)) {
  //     setOpenNotifications(null)
  //   } else {
  //     setOpenNotifications(event.currentTarget)
  //   }
  // }
  // const handleCloseNotifications = () => {
  //   setOpenNotifications(null)
  // }
  const reAuth = () => {
    localStorage.removeItem('auth')
    window.location.reload()
  }
  const logout = () => {
    localStorage.clear()
    window.location =
      process.env.REACT_APP_AUTH_URL + `/logout?redirect_url=${process.env.REACT_APP_REDIRECT_URI}`
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        // sx={{
        //   padding: 0,
        //   width: 44,
        //   height: 44,
        //   ...(open && {
        //     // bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
        //     bgcolor: 'transparent', //'rgba(0, 0, 0, 0.04)',
        //   }),
        // }}
      >
        <PersonIcon className={classes.icon} />
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            overflow: 'inherit',
            mt: 1.5,
            ml: 0.75,
            minWidth: 230,
            maxWidth: 290,
            height: 290,
            '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
            padding: '4px 8px 4px 8px !important',
            backgroundColor: '#1e2a4f', //'#364473',
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <ArrowStyle className='arrow' />
        <Stack spacing={0.75}>
          <MenuList role='menu'>
            <MenuItem className={classes.dropdownItem}>
              <IconButton color='inherit' size='large'>
                <PersonIcon />
              </IconButton>
              <div className={classes.lkLink}>
                <Typography className={'heading'}>{apiService.getCredential().username}</Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuLine} disabled />
            <MenuItem className={classes.dropdownItem} onClick={reAuth}>
              <IconButton color='inherit' size='large'>
                <SupervisorAccountIcon />
              </IconButton>
              <div className={classes.lkLink}>
                <Typography className={'heading'}>
                  {apiService.getCredential().companyName}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.dropdownItem}>
              <IconButton color='inherit' size='large'>
                <SettingsIcon />
              </IconButton>
              <div className={classes.lkLink}>
                <Typography className={'heading'}>{t.settings[language]}</Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.dropdownItem} onClick={logout}>
              <IconButton color='inherit' size='large'>
                <ExitToApp />
              </IconButton>
              <div className={classes.lkLink}>
                <Typography className={'heading'}>{t.logout[language]}</Typography>
              </div>
            </MenuItem>
          </MenuList>
        </Stack>
      </Popover>
    </>
  )
}

export default Person
