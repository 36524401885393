import makeStyles from '@mui/styles/makeStyles'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Layers from '@mui/icons-material/Layers'
import SmartButtonIcon from '@mui/icons-material/SmartButton'
import LinkIcon from '@mui/icons-material/Link'
// import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  multiList_item: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    width: '100%',
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .item__name': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& .item__icon': {
      marginRight: 10,
    },
  },
}))

const Connection = ({ name, type /*, link  */ }) => {
  const classes = useStyles()

  return (
    <div className={classes.multiList_item}>
      {/*<Link to={link || '#'}>*/}
      {type === 'input' || type === 'transfer' ? (
        <ExitToApp className='item__icon' />
      ) : type === 'preset' ? (
        <Layers className='item__icon' />
      ) : type === 'service' ? (
        <SmartButtonIcon className='item__icon' />
      ) : (
        <LinkIcon className='item__icon' />
      )}
      <div className='item__name'>{name}</div>
      {/*</Link>*/}
    </div>
  )
}

export default Connection
