import { useEffect } from 'react'

import apiService from '../../services/api-service'
import { useQuery } from '../../services/useQuery'
import { useAppContext } from '../../components/App/AppContext'
import Spinner from '../../components/Spinner/Spinner'

const authLink = `${process.env.REACT_APP_AUTH_URL}/oauth/authorization?client_id=${process.env.REACT_APP_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`

function SignIn() {
  const query = useQuery()
  const code = query.get('code')
  const { setStatus } = useAppContext()

  if (!code) {
    window.location = authLink
  }

  useEffect(() => {
    apiService
      .login(code)
      .then(() => {
        setStatus('unauthorized')
      })
      .catch((e) => {
        console.log(e)
        window.location(process.env.REACT_APP_AUTH_URL)
      })
    // eslint-disable-next-line
  }, [])

  return <Spinner />
}

export default SignIn
