import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import ListItem from '@mui/material/ListItem'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useField } from 'formik'
import { useState } from 'react'
import iconSelected from '../assets/img/icons/autocomplete/selected.png'
import iconUnselected from '../assets/img/icons/autocomplete/unselected.png'
import TextField from './SimpleTextField'
import { translations as t } from '../services/translations'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    marginBottom: 0,
  },
  root: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  autocomplete: {
    width: '100%',
  },
  paper: {
    maxHeight: 320,
    backgroundColor: theme.palette.background.default,

    '&.MuiPaper-root': {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',

      //scroll
      '&::-webkit-scrollbar': {
        width: '7px !important',
      },
      // width
      '& *::-webkit-scrollbar': {
        width: '7px !important',
        backgroundColor: 'transparent',
      },
      // Track
      '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
        borderRadius: '5px',
        backgroundColor: 'inherit',
      },
      // Handle
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        height: '2.75em',
        borderRadius: '5px',
      },

      '& .MuiAutocomplete-listbox': {
        maxHeight: '100%',
        overflow: 'hidden',
      },
    },

    '& ul li': {
      color: theme.palette.text.primary,
      '&.MuiAutocomplete-option': {
        padding: '5px 10px 5px 35px',
        borderBottom: `1px solid ${theme.palette.background.default}`,
        borderRight: `1px solid ${theme.palette.background.default}`,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '&.MuiAutocomplete-option.Mui-focused:hover': {
        backgroundColor: `${theme.palette.primary.mainLight} !important`,
      },
    },
  },
  popper: {},
  clearIndicator: {
    marginRight: '-10px',
  },
  arrow: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.dark,
    },
  },
  arrowError: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
}))
/* eslint-disable no-use-before-define */

const AutocompleteSingleCheckbox = (props) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)
  const error = Boolean(meta.touched && meta.error)
  const [value, setValue] = useState('')

  // if state is an array with id => [ 'string' ]
  if (Array.isArray(field.value) && typeof field.value[0] === 'string') {
    helpers.setValue(field.value[0])
  }

  return (
    <FormControl
      error={error}
      onBlur={() => helpers.setTouched(true)}
      fullWidth={true}
      margin='normal'
      variant='standard'
      style={props.style}
      className={clsx(classes.formControl, props.className, error && classes.error)}
    >
      <Autocomplete
        value={field.value}
        onChange={(e, v, t) => {
          if (props.onChangeCallback) {
            props.onChangeCallback(e, v, t)
          }

          if (t === 'clear') {
            helpers.setValue('') // null
            setValue('')
            //  return
          } /*else if (t === 'selectOption' && field.value === v?.id){
              helpers.setValue(null)
              setValue('')
              return
            }*/ else if (
            t === 'selectOption'
          ) {
            helpers.setValue(v?.id)
            //  return
          }
        }}
        options={props.options}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value
        }}
        getOptionLabel={(option) => {
          if (props.getOptionLabel) {
            return props.getOptionLabel(option)
          }

          if (typeof option === 'string') {
            const id = option.split(' ')[0]
            return props.options.find((el) => el?.id === id)?.label || '' // ''
          } else if (typeof option === 'object') {
            return props.options.find((el) => el?.id === option?.id)?.label || '' // ''
          }
        }}
        noOptionsText={
          props.noOptionsText || props.language ? t.noDataFound[props.language] : 'Data not found'
        }
        renderOption={(props, option, { selected }) => {
          return (
            <ListItem {...props} key={option?.id}>
              {selected ? (
                <img
                  src={iconSelected}
                  alt='selected icon'
                  style={{
                    position: 'absolute',
                    top: 10,
                    left: 9,
                    height: 15,
                    width: 15,
                  }}
                />
              ) : (
                <img
                  src={iconUnselected}
                  alt='unselected icon'
                  style={{
                    position: 'absolute',
                    top: 10,
                    left: 9,
                    height: 15,
                    width: 15,
                  }}
                />
              )}
              {option?.label || ''}
            </ListItem>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={props.label}
              sx={{
                '& .MuiInputBase-root': {
                  '& input': {
                    '&::placeholder': {
                      fontSize: 17,
                    },
                  },
                },
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              placeholder={
                props.placeholder || props.language
                  ? t.clickToSelect[props.language]
                  : 'Click to select ...'
              }
              error={error}
              variant='standard'
              required={props.required}
            />
          )
        }}
        className={clsx(error ? classes.arrowError : classes.arrow, classes.root)}
        // className={clsx(classes.arrow)}
        classes={{
          root: classes.autocomplete,
          paper: classes.paper,
          listbox: classes.popper,
          clearIndicator: classes.clearIndicator,
        }}
        id={`${props.name}-id`}
        disableCloseOnSelect={true}
        disabled={props.disabled}
      />
      <FormHelperText sx={{ marginTop: '3px' }} error>
        {error ? meta.error || meta.error.label || meta.error.name : ' '}
      </FormHelperText>
    </FormControl>
  )
}

export default AutocompleteSingleCheckbox
