import Layers from '@mui/icons-material/Layers'
import clsx from 'clsx'
import { useCallback, useRef, useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import SmartButtonIcon from '@mui/icons-material/SmartButton'

import ContextMenu from '../ContextMenu/ContextMenu'
import { useDashboardContext } from '../Dashboard'
import HDIcon from '../icons/HDIcon'
import LockIcon from '../icons/LockIcon'
import SDIcon from '../icons/SDIcon'
import './MatrixItem.css'

function MatrixItem({ item, refreshDashboard, xmode, presets }) {
  const { nodes, coords, setNodes, relations, setRelations, refresh } = useDashboardContext()

  const leaveContext = useRef(null)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [eventCoordinates, setEventCoordinates] = useState({})

  const element = useCallback(
    (elem) => {
      if (elem) {
        const { offsetLeft, offsetTop, offsetHeight, offsetWidth } = elem
        const newCoord = {
          leftPoint: {
            x: offsetLeft,
            y: offsetTop + offsetHeight / 2,
          },
          rightPoint: {
            x: offsetLeft + offsetWidth,
            y: offsetTop + offsetHeight / 2,
          },
        }
        coords.current = { ...coords.current, [item.id]: newCoord }
      }
    },
    // eslint-disable-next-line
    [refresh],
  )

  const onMouseEnterHandler = (item) => {
    clearTimeout(leaveContext.current)
    if (item.source) {
      const nodesUpd = nodes.map((el) => {
        return {
          ...el,
          selected: el.connected === item.id || el.id === item.id,
        }
      })
      const relationsUpd = relations.map((el) => {
        return {
          ...el,
          selected: el.source === item.id,
        }
      })
      setRelations(relationsUpd)
      setNodes(nodesUpd)
    } else if (item.connected) {
      const nodesUpd = nodes.map((el) => {
        return {
          ...el,
          selected: el.id === item.connected || el.id === item.id,
        }
      })
      const relationsUpd = relations.map((el) => {
        return {
          ...el,
          selected: el.target === item.id,
          showButton: el.target === item.id,
        }
      })
      setRelations(relationsUpd)
      setNodes(nodesUpd)
    }
  }

  const onMouseLeaveHandler = () => {
    if (item.source || item.connected) {
      const nodesUpd = nodes.map((el) => {
        return {
          ...el,
          selected: false,
        }
      })
      const relationsUpd = relations.map((el) => {
        return {
          ...el,
          selected: false,
          showButton: false,
        }
      })
      setRelations(relationsUpd)
      setNodes(nodesUpd)
    }
    leaveContext.current = setTimeout(() => setShowContextMenu(false), 300)
  }

  const onContextMenuHandler = (item, e) => {
    setShowContextMenu(true)
    setEventCoordinates({ x: e.clientX, y: e.clientY })

    e.preventDefault()
  }

  const ownerName =
    item.group === 'externalInputs' || item.group === 'externalOutputs' || xmode === 'location'
      ? item.owner?.name || item.accountName
      : null

  let transferName = null
  if (item.group === 'externalInputs') {
    transferName = item.transfer_request_event?.name
  } else if (item.group === 'externalOutputs') {
    transferName = item.connection.transfer.transfer_request_event.name
  }

  return (
    <div
      ref={element}
      className={clsx('MatrixItem__node', item.selected && 'Selected')}
      onMouseEnter={onMouseEnterHandler.bind(this, item)}
      onMouseLeave={onMouseLeaveHandler}
      onContextMenu={onContextMenuHandler.bind(this, item)}
      style={{ borderColor: item.color }}
    >
      <div
        className={clsx(
          'MatrixItem__sideLine',
          item.status === 'offline' && 'Disabled',
          item.status === 'unknown' && 'Unknown',
        )}
      />
      <div className='MatrixItem__nodeContent'>
        <h4>{item.name}</h4>
        {transferName && <p className='MatrixItem__transferName'>{transferName}</p>}
        {ownerName && <p>{ownerName}</p>}
        {/*{item.group === 'outputs' && <Layers style={{ fontSize: 15 }}/>}*/}
        {item.connection?.type === 'preset' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'default',
              color: 'rgba(98, 198, 115, 0.7)',
            }}
          >
            <Layers style={{ fontSize: 15, marginRight: 3 }} />
            <p className='MatrixItem__transferName'>{item.connection?.preset?.name}</p>
          </div>
        )}
        {item.connection?.type === 'custom' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'default',
              color: 'rgba(98, 198, 115, 0.7)',
            }}
            title={item.connection?.url}
          >
            <LinkIcon style={{ fontSize: 15, marginRight: 3 }} />
            <p className='MatrixItem__transferName'>Link</p>
          </div>
        )}
        {item.connection?.type === 'service' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'default',
              color: 'rgba(98, 198, 115, 0.7)',
            }}
            title={item.connection?.url}
          >
            <SmartButtonIcon style={{ fontSize: 15, marginRight: 3 }} />
            <p className='MatrixItem__transferName'>Service</p>
          </div>
        )}
      </div>
      {item.quality && (
        <div className='MatrixItem__iconContainer'>
          {item.quality === 'HD' ? <HDIcon /> : <SDIcon />}
          {item.is_locked && <LockIcon />}
        </div>
      )}
      <ContextMenu
        show={showContextMenu}
        setShow={setShowContextMenu}
        eventCoordinates={eventCoordinates}
        item={item}
        refreshDashboard={refreshDashboard}
        presets={presets}
      />
    </div>
  )
}

export default MatrixItem
