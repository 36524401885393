import { createTheme } from '@mui/material/styles'

const background1 = '#1e2a4f'
// const background1 = '#1c2749'
const background2 = '#212b4c'
const background3 = '#394875'
// const background3 = '#374570'
const background4 = '#364473'
const background5 = '#35436E'
const theme = createTheme({
  transitions: {
    duration: {
      enteringScreen: 660,
      leavingScreen: 660,
    },
  },
  palette: {
    active: {
      main: 'rgba(98, 198, 115, 0.7)',
    },
    disable: {
      main: 'rgba(223, 59, 59, 0.7)',
    },
    success: {
      main: '#62c673',
    },
    info: {
      main: '#4a90e2',
    },
    warning: {
      main: '#e29500',
    },
    error: {
      main: 'rgb(255, 0, 0)',
    },
    primary: {
      main: '#6565cf',
      mainLight: 'rgba(101,101,207,0.51)',
    },
    secondary: {
      main: '#62c673',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      dark: 'rgba(255,255,255, 0.6)',
      lightDark: 'rgba(255,255,255, 0.1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.18)',
    background: {
      default: '#2E3B63',
      color1: background1,
      color2: background2,
      dimmer: 'rgba(0, 0, 0, .03)',
      bleacher: 'rgba(255, 255, 255, .03)',
    },

    layout: {
      main: `linear-gradient(to bottom, ${background3}, ${background1})`,
      sideMenu: `linear-gradient(to top, ${background2}, ${background4})`,
      appBar: `linear-gradient(to bottom, ${background3}, ${background5})`,
    },
  },
})

export default theme
