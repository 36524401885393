import { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useSnackbar } from 'notistack'

import { useAppContext } from '../../../components/App/AppContext'
import Tabs from '../../../components/Tabs/Tabs'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
import Status from '../../../components/Status/Status'
import CardBody from '../../../components/Card/CardBody'
import CardItem from '../../../components/Card/CardItem'
import RefreshBtn from '../../../components/ActionButtons/RefreshBtn/RefreshBtn'
import OutboundTransferRequestsTab from './InnerTabs/OutboundTransferRequestsTab'
import apiService from '../../../services/api-service'
import InternalConnectionsTab from './InnerTabs/InternalConnectionsTab'
import InputTransfersTab from './InnerTabs/InputTransfersTab'
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog'
import LocationMapGoogleShow from '../../../forms/LocationMapGoogleShow'
import {
  isPrepareDeviceAttributes,
  prepareLabelDeviceAttributes,
  prepareDeviceAttributes,
  numberFromFormatToMbps,
  capitalizeFirstLetter,
} from '../../../services/helpers'
import { translations as t } from '../../../services/translations'
import EditInput from '../InputsTable/EditInput'
import SettingsTable from './SettingsTable'
import ServersTable from './ServersTable'
import EditSettings from './EditSettings'
import EditServers from './EditServers'
import EditBtn from '../../../components/ActionButtons/EditBtn/EditBtn'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: 'calc(50% - 15px)',
    minHeight: 250,
  },
  tabsContainer: {
    marginTop: 20,
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
  cardItemValue: {
    width: '430px',
    display: 'flex',
    justifyContent: 'end',
    overflowWrap: 'anywhere',
    '& span': {
      textAlign: 'start',
    },
  },
}))

const Input = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [deviceAttributes, setDeviceAttributes] = useState({
    dune: [],
    status: null,
    live_status: {
      live: {
        isLiveProperty: false,
      },
    },
    video_info: {
      isVideoInfoProperty: false,
    },
  })

  const {
    setCurrentPage,
    language,
    fullScreenModal: { buffer },
    openFullScreenModal,
  } = useAppContext()

  const { contextId, inputId } = useParams()
  const [data, setData] = useState({})
  const [settings, setSettings] = useState([])
  const [servers, setServers] = useState([])

  const getData = () => {
    apiService
      .get(`/virtual-matrix/inputs/${inputId}`, contextId)
      .then((res) => {
        setData(res.data)

        const dev_att = JSON.parse(res.data?.device_attributes)
        if (isPrepareDeviceAttributes(dev_att)) {
          setDeviceAttributes(prepareDeviceAttributes(dev_att))
        }
        if (res.data?.type === 'magewell-ultra') {
          return Promise.all([
            apiService
              .get(`/virtual-matrix/inputs/magewell-ultra/${inputId}/settings`)
              .catch((err) => {
                console.log(err)
                return { data: [] }
              }),
            apiService.get(`/virtual-matrix/inputs/${inputId}/servers`).catch((err) => {
              console.log(err)
              return { data: [] }
            }),
          ])
        }
      })
      .then((res) => {
        if (res[0]) {
          setSettings(res[0].data)
        }
        if (res[1]) {
          setServers(res[1].data)
        }
      })
      .catch(console.log)
  }

  useEffect(() => {
    apiService.get(`/virtual-matrix/inputs/${inputId}`, contextId).then((res) => {
      setCurrentPage(
        <span>
          {t.input[language]} <span style={{ margin: '0 17px' }}>|</span> {res.data.name}
        </span>,
      )
    })
    return () => setCurrentPage('')

    // eslint-disable-next-line
  }, [language])

  useEffect(() => {
    getData()

    return () =>
      setDeviceAttributes({
        dune: [],
        status: null,
        live_status: {
          live: {
            isLiveProperty: false,
          },
        },
        video_info: {
          isVideoInfoProperty: false,
        },
      })
    // eslint-disable-next-line
  }, [])

  const handleReboot = (item) => {
    return apiService
      .post(`/virtual-matrix/inputs/${item.id}/reboot`, null, contextId)
      .then((res) => {
        getData()
      })
      .catch(console.log)
  }

  let tabs = [
    {
      label: t.internalConnections[language],
      contentTitle: '',
      contentBody: <InternalConnectionsTab />,
    },
    {
      label: t.externalConnections[language],
      contentTitle: '',
      // contentBody: <InputsTable withoutPagination />,
    },
    {
      label: t.inputTransfers[language],
      contentTitle: '',
      contentBody: <InputTransfersTab />,
    },
    {
      label: t.outboundTransferRequest[language],
      contentTitle: '',
      contentBody: <OutboundTransferRequestsTab />,
    },
  ]

  if (data?.type === 'magewell-ultra') {
    tabs = [
      {
        label: t.settings[language],
        contentTitle: '',
        contentBody: <SettingsTable data={settings} />,
      },
      {
        label: t.servers[language],
        contentTitle: '',
        contentBody: <ServersTable data={servers} />,
      },
      ...tabs,
    ]
  }

  const handleActivateSettings = ({ id }) => {
    return apiService
      .post(`/virtual-matrix/inputs/magewell-ultra/settings/${id}/activate`)
      .then((res) => {
        getData()
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e.response?.data.error || e.message, {
          variant: 'error',
        })
      })
  }

  const handleDeleteSettings = ({ id }) => {
    return apiService
      .delete(`/virtual-matrix/inputs/settings/${id}`)
      .then((res) => {
        getData()
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e.response?.data.error || e.message, {
          variant: 'error',
        })
      })
  }

  const handleDeleteServer = ({ id }) => {
    return apiService
      .delete(`/virtual-matrix/inputs/servers/${id}`)
      .then((res) => {
        getData()
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e.response?.data.error || e.message, {
          variant: 'error',
        })
      })
  }

  const handleEnableServer = ({ id }) => {
    return apiService
      .patch(`/virtual-matrix/inputs/magewell-ultra/servers/${id}/enable`)
      .then((res) => {
        getData()
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e.response?.data.error || e.message, {
          variant: 'error',
        })
      })
  }

  const handleDisableServer = ({ id }) => {
    return apiService
      .patch(`/virtual-matrix/inputs/magewell-ultra/servers/${id}/disable`)
      .then((res) => {
        getData()
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e.response?.data.error || e.message, {
          variant: 'error',
        })
      })
  }

  const handleApplyServer = ({ updUrl }) => {
    return apiService
      .patch(
        `/virtual-matrix/inputs/${inputId}`,
        {
          url: updUrl,
        },
        contextId,
      )
      .then((res) => {
        getData()
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e.response?.data.error || e.message, {
          variant: 'error',
        })
      })
  }

  return (
    <motion.div
      className={classes.root}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <EditInput contextAccountId={contextId} onOk={getData} />
      <EditSettings onOk={getData} inputId={inputId} />
      <EditServers onOk={getData} inputId={inputId} />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRebootIt[language]}
        onOk={handleReboot.bind(this, buffer)}
        id={`reboot${contextId}`}
      />
      {/*Settings*/}
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToActivateTheSettings[language]}
        onOk={handleActivateSettings.bind(this, buffer)}
        id='activateSettings'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToUpdateTheSettings[language]}
        onOk={handleActivateSettings.bind(this, buffer)}
        id='updateSettings'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToDeleteTheSettings[language]}
        onOk={handleDeleteSettings.bind(this, buffer)}
        id='deleteSettings'
      />
      {/* Servers */}
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToDeleteTheServer[language]}
        onOk={handleDeleteServer.bind(this, buffer)}
        id='deleteServer'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToEnableTheServer[language]}
        onOk={handleEnableServer.bind(this, buffer)}
        id='enableServer'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToDisableTheServer[language]}
        onOk={handleDisableServer.bind(this, buffer)}
        id='disableServer'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToApplyTheNewDeviceURL[language]}
        onOk={handleApplyServer.bind(this, buffer)}
        id='applyServer'
      />
      <div className={classes.cardRow}>
        <div className={classes.cardContainer}>
          <Card>
            <CardTitle title={data.name}>
              <Status status={data?.status} />
              {data?.status !== 'unknown' && data?.status !== 'offline' && (
                <>
                  <RefreshBtn
                    onClick={() => openFullScreenModal(`reboot${contextId}`, data)}
                    style={{ marginLeft: 6, marginRight: -10 }}
                  />
                </>
              )}
              <EditBtn
                onClick={openFullScreenModal.bind(this, `editInput${contextId}`, data)}
                style={{ marginRight: -14 }}
              />
            </CardTitle>
            <CardBody>
              <CardItem
                label={t.owner[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data.owner?.name}</span>
                  </span>
                }
              />
              <CardItem label={t.type[language]} value={data.input_type} />
              <CardItem label={t.device[language]} value={capitalizeFirstLetter(data?.type)} />
              <CardItem label={t.formatType[language]} value={data.stream_type} />
              <CardItem
                label={t.link[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data?.url}</span>
                  </span>
                }
              />
              <CardItem
                label={t.location[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data?.location?.name}</span>
                  </span>
                }
              />
              <CardItem
                label={t.address[language]}
                value={
                  <span className={classes.cardItemValue}>
                    <span>{data?.address}</span>
                  </span>
                }
              />
              {data?.location?.id && (
                <LocationMapGoogleShow
                  coordinates={{ lat: data?.location.lat, lng: data?.location.lng }}
                  mapContainerStyle={{
                    minWidth: '250px',
                    width: '100%',
                    height: '180px',
                  }}
                />
              )}
            </CardBody>
          </Card>
        </div>
        {deviceAttributes?.live_status?.isLiveProperty ||
        deviceAttributes?.video_info?.isVideoInfoProperty ? (
          <div className={classes.cardContainer}>
            <Card className={classes.cardContainerWithoutHeight}>
              <CardTitle title={t.currentDeviceInfo[language]}></CardTitle>
              <CardBody>
                {deviceAttributes?.live_status?.live?.result !== null &&
                  typeof deviceAttributes?.live_status?.live?.result === 'number' && (
                    <CardItem
                      label={t.state[language]}
                      value={prepareLabelDeviceAttributes(
                        deviceAttributes?.live_status.live.result,
                      )}
                    />
                  )}
                {deviceAttributes?.status !== null &&
                  typeof deviceAttributes?.status === 'number' && (
                    <CardItem
                      label={t.state[language]}
                      value={prepareLabelDeviceAttributes(deviceAttributes?.status)}
                    />
                  )}
                {deviceAttributes?.live_status?.live?.cur_bps !== null &&
                  typeof deviceAttributes?.live_status?.live?.cur_bps === 'number' && (
                    <CardItem
                      label={t.currentBitrateMbps[language]}
                      value={numberFromFormatToMbps(
                        deviceAttributes?.live_status.live.cur_bps,
                        'ultra',
                      )}
                    />
                  )}
                {deviceAttributes?.video_info?.width !== null &&
                  typeof deviceAttributes?.video_info?.width === 'number' && (
                    <CardItem
                      label={t.width[language]}
                      value={deviceAttributes?.video_info?.width}
                    />
                  )}
                {deviceAttributes?.video_info?.height !== null &&
                  typeof deviceAttributes?.video_info?.height === 'number' && (
                    <CardItem
                      label={t.height[language]}
                      value={deviceAttributes?.video_info?.height}
                    />
                  )}
                {deviceAttributes?.video_info?.scan !== null && (
                  <CardItem
                    label={t.scan[language]}
                    value={capitalizeFirstLetter(deviceAttributes?.video_info?.scan)}
                  />
                )}
                {deviceAttributes?.video_info?.field_rate !== null &&
                  typeof deviceAttributes?.video_info?.field_rate === 'number' && (
                    <CardItem
                      label={t.frameRate[language]}
                      value={deviceAttributes?.video_info?.field_rate}
                    />
                  )}
              </CardBody>
            </Card>
          </div>
        ) : null}
      </div>
      <div className={classes.tabsContainer}>
        <Tabs data={tabs} resizable />
      </div>
    </motion.div>
  )
}

export default Input
