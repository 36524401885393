import { useRef, useState } from 'react'
import { useAppContext } from '../App/AppContext'

import MenuList from '@mui/material/MenuList'
import Button from '@mui/material/Button'
import { Stack, IconButton } from '@mui/material'
import { Popover } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import LanguageIcon from '@mui/icons-material/Language'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    // background: theme.palette.background.paper,
    background: '#1e2a4f', //'#364473', //'#1e2a4f !important',
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}))
const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  popperNav: {
    '& .MuiPaper-root': {
      marginTop: 5,
      marginRight: 0,
      backgroundColor: theme.palette.background.color1,
      width: 50,
      padding: '8px 0',
      '& ul': {
        overflow: 'auto',
        maxHeight: 500,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '&::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: '-18px',
        left: '13px',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '12px',
        borderColor: `transparent transparent ${theme.palette.background.color1} transparent`,
      },
    },
  },
  dropdownItem: {
    fontSize: '12px',
    //padding: '5px 0px 5px 8px', //5px 0px 5px 8px || '10px 16px 10px 12px'
    margin: '2px 0px',
    //borderRadius: '2px',
    // WebkitTransition: 'all 150ms linear',
    // MozTransition: 'all 150ms linear',
    // OTransition: 'all 150ms linear',
    // MsTransition: 'all 150ms linear',
    // transition: 'all 150ms linear',
    // display: 'block',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#fff',
    whiteSpace: 'normal',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.mainLight} !important`,
    },
  },
  currenItem: {
    backgroundColor: '#6565cf !important', //'#1e2a4f !important',
  },
  dropdownItemClose: {
    position: 'absolute',
    right: '4px',
    top: '10px',
    fontSize: '14px',
  },
  icon: {
    color: 'white !important',
  },
}))

const LanguageSwitcher = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { language, setLanguage } = useAppContext()
  const anchorRef = useRef(null)

  const handleCloseNotifications = (e) => {
    if ((e.target.id !== language && e.target.id !== '') || null || undefined) {
      setLanguage(e.target.id)
      localStorage.setItem('language', e.target.id)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        // sx={{
        //   padding: 0,
        //   width: 44,
        //   height: 44,
        //   ...(open && {
        //     // bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
        //     bgcolor: 'transparent', //'rgba(0, 0, 0, 0.04)',
        //   }),
        // }}
      >
        <LanguageIcon className={classes.icon} />
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            overflow: 'inherit',
            mt: 1.5,
            ml: 0.75,
            width: 80,
            padding: '4px 8px 4px 8px !important',
            backgroundColor: '#1e2a4f', //'#364473',
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <ArrowStyle className='arrow' />
        <Stack spacing={0.75}>
          <MenuList role='menu'>
            <Button
              size='small'
              onClick={handleCloseNotifications}
              id='uk'
              variant='outlined'
              className={clsx(classes.dropdownItem, language === 'uk' && classes.currenItem)}
            >
              UKR
            </Button>
            <Button
              size='small'
              onClick={handleCloseNotifications}
              id='en'
              variant='outlined'
              className={clsx(classes.dropdownItem, language === 'en' && classes.currenItem)}
            >
              ENG
            </Button>
          </MenuList>
        </Stack>
      </Popover>
    </>
  )
}

export default LanguageSwitcher
