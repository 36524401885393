import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

const APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const LocationMapGoogleShow = ({ coordinates, mapContainerStyle, ...props }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: APP_GOOGLE_MAP_API_KEY,
  })
  // const [zoom, setZoom] = React.useState(10)
  // const [map, setMap] = React.useState(null)

  // eslint-disable-next-line
  const [centerMap, setCenterMap] = React.useState({
    lat: 50.426220981280586,
    lng: 30.505241870879807,
  })

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(centerMap)
  //   map.fitBounds(bounds)
  //
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  React.useEffect(() => {
    // setTimeout(() => {
    //   setZoom(15)
    setCenterMap(coordinates)
    // }, 300)
    // eslint-disable-next-line
  }, [coordinates]) //are showing after update in location page

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={{ disableDoubleClickZoom: true, streetViewControl: false }}
        center={centerMap}
        zoom={15}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
      >
        <Marker position={coordinates} draggable={false} />
      </GoogleMap>
    </>
  ) : (
    <>Loading map...</>
  )
}

export default React.memo(LocationMapGoogleShow)
