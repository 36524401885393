import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import makeStyles from '@mui/styles/makeStyles'

import { translations as t } from '../../services/translations'
import { useAppContext } from '../App/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      // position: 'relative',

      width: '400px',
      height: '400px',
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  backgroundCircular: {
    position: 'relative',
    // opacity: '0.7',
    // backgroundColor: '#2e3b63',
    backgroundColor: 'rgba(46, 59, 99, 0.8)',

    borderRadius: '50%',
    height: '190px',
  },
  circularProgress: {},
  containerTitle: {
    position: 'absolute',
    top: 83,
    left: 50,
    width: '50%',
  },
  title: {
    // color: 'rgb(101, 101, 207)',
    textAlign: 'center',
  },
}))

function FullScreenLoader() {
  const classes = useStyles()
  const { language, isFullScreenLoader } = useAppContext()

  return (
    <Dialog className={classes.root} open={isFullScreenLoader}>
      <div className={classes.backgroundCircular}>
        <CircularProgress thickness='2' size='190px' className={classes.circularProgress} />
        <section className={classes.containerTitle}>
          <p className={classes.title}>{t.processing[language]}...</p>
        </section>
      </div>
    </Dialog>
  )
}

export default FullScreenLoader
