import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import DoneIcon from '@mui/icons-material/Done'

import Table from '../../../components/Table/Table'
import TableBody from '../../../components/Table/TableBody'
import useSorting from '../../../services/sorting'
import TableCell from '../../../components/Table/TableCell'
// import TableHeadMini from '../../../components/Table/TableHeadMini'
import { translations as t } from '../../../services/translations'
import { useAppContext } from '../../../components/App/AppContext'
import TableHead from '../../../components/Table/TableHead'
import AddBtn from '../../../components/AddBtn/AddBtn'
import EditBtn from '../../../components/ActionButtons/EditBtn/EditBtn'
import DeleteBtn from '../../../components/ActionButtons/DeleteBtn/DeleteBtn'
import UniversalBtn from '../../../components/ActionButtons/UniversalBtn'
import SyncIcon from '@mui/icons-material/Sync'
// import SettingsContextMenu from './SettingsContextMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 'auto',
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
    '& .doubleLine > p': {
      lineHeight: '18px',
      fontWeight: 500,
      whiteSpace: 'nowrap,',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // paddingRight: 12,
  },
  tableContainer: {
    height: '100%',
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
  subCell: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(101, 101, 207, 0.3)',
    display: 'grid',
    placeItems: 'center',
  },
  imgCell: {
    width: 60,
    height: 34,
    objectFit: 'cover',
  },
  customRow: {
    '& th,td': {
      height: 'auto',
      padding: '0 16px 0 16px',
    },
  },
  greenRow: {
    '& td,th': {
      color: theme.palette.active.main,
    },
  },
}))

const SettingsTable = ({ data }) => {
  const classes = useStyles()
  const {
    language,
    openFullScreenModal,
    // fullScreenModal: { buffer },
  } = useAppContext()
  const { order, orderBy, createSortHandler } = useSorting('')

  const headCells = [
    [
      {
        id: 'resolution',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.resolution[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      { id: 'frameRate', numeric: false, label: t.frameRate[language] },
      { id: 'bitrate', numeric: false, label: t.bitrate[language] },
      { id: 'codec', numeric: false, label: t.codec[language] },
      {
        id: 'add',
        numeric: false,
        label: (
          <AddBtn
            onClick={() => {
              openFullScreenModal('editSettings', null)
            }}
          />
        ),
        props: {
          align: 'right',
          width: 140,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.root} stickyHeader>
        <TableHead
          headCells={headCells}
          createSortHandler={createSortHandler}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow
                key={row.id}
                className={clsx(
                  // classes.customRow,
                  row.status === 'active' && classes.greenRow,
                )}
              >
                <TableCell component='th' scope='row'>
                  {`${row?.width} x ${row?.height}`}
                </TableCell>
                <TableCell>{row.duration?.name}</TableCell>
                <TableCell>{row.bitrate?.name}</TableCell>
                <TableCell>{row.codec?.name}</TableCell>
                {/*<TableCell>/!*<SettingsContextMenu data={row} />*!/</TableCell>*/}
                <TableCell>
                  <div className={classes.buttonTdContainer}>
                    {row?.status !== 'active' && (
                      <UniversalBtn
                        onClick={() => openFullScreenModal('activateSettings', row)}
                        icon={DoneIcon}
                        alt='Activate'
                      />
                    )}
                    {row?.status === 'active' && (
                      <UniversalBtn
                        onClick={() => openFullScreenModal('updateSettings', row)}
                        icon={SyncIcon}
                        alt='Update'
                      />
                    )}
                    <EditBtn onClick={() => openFullScreenModal('editSettings', row)} />
                    {row?.status !== 'active' && (
                      <DeleteBtn onClick={() => openFullScreenModal('deleteSettings', row)} />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SettingsTable
