import makeStyles from '@mui/styles/makeStyles'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const Spinner = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color='primary' />
    </div>
  )
}

export default Spinner
