import { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { motion } from 'framer-motion'

import { useAppContext } from '../../../components/App/AppContext'
import Tabs from '../../../components/Tabs/Tabs'
import Card from '../../../components/Card/Card'
import CardTitle from '../../../components/Card/CardTitle'
import CardBody from '../../../components/Card/CardBody'
import CardItem from '../../../components/Card/CardItem'
import EditBtn from '../../../components/ActionButtons/EditBtn/EditBtn'
import DeleteBtn from '../../../components/ActionButtons/DeleteBtn/DeleteBtn'
import EventsTable from '../AddTransferRequest/EventsTable'
import apiService from '../../../services/api-service'
import Recipients from './Recipients'
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog'
import AddEvent from './AddEvent'
import EditTransferRequest from './EditTransferRequest'
import InputTransfersTab from './InputTransfersTab'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: 'calc(50% - 15px)',
    minHeight: 290,
  },
  tabsContainer: {
    marginTop: 40,
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
}))

function OutboundTransferRequest() {
  const {
    setCurrentPage,
    language,
    openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const classes = useStyles()
  const history = useHistory()
  const { contextId, requestId } = useParams()

  const [data, setData] = useState({})

  const getData = () => {
    apiService
      .get(`/virtual-matrix/transfer-requests/outbound/${requestId}`, contextId)
      .then((res) => {
        setData(res.data)
      })
      .catch(console.log)
  }

  useEffect(() => setCurrentPage(t.outboundTransferRequest[language]))

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const handleDeleteRequest = (requestId) => {
    return apiService
      .delete(`/virtual-matrix/transfer-requests/${requestId}`)
      .then((res) => {
        history.push('/outbound-transfer-requests')
      })
      .catch(console.log)
  }

  const handleDeleteEvent = (eventId) => {
    return apiService
      .delete(`/virtual-matrix/transfer-requests/events/${eventId}`)
      .then((res) => {
        console.log(res)
        getData()
      })
      .catch(console.log)
  }

  const tabs = [
    {
      label: `${t.events[language]}`,
      contentTitle: '',
      contentBody: (
        <EventsTable
          rows={data.events || []}
          onEdit={(item) => openFullScreenModal('addEvent', item)}
          onDelete={(item) => openFullScreenModal('deleteEvent', item)}
          large
        />
      ),
    },
    {
      label: `${t.transfers[language]}`,
      contentTitle: '',
      contentBody: <InputTransfersTab />,
    },
  ]

  const isAll = data.includes?.length === 0

  return (
    <>
      <EditTransferRequest onOk={getData} />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheRequest[language]}
        onOk={handleDeleteRequest.bind(this, buffer)}
        id='deleteTransferRequest'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheEvent[language]}
        onOk={handleDeleteEvent.bind(this, buffer?.id)}
        id='deleteEvent'
      />
      <AddEvent onOk={getData} />
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={classes.root}
      >
        <div className={classes.cardRow}>
          <div className={classes.cardContainer}>
            <Card>
              <CardTitle title={data.title}>
                <EditBtn
                  onClick={() =>
                    openFullScreenModal('editTransferRequest', { id: requestId, contextId })
                  }
                  style={{ paddingRight: 6 }}
                />
                <DeleteBtn
                  onClick={() => openFullScreenModal('deleteTransferRequest', requestId)}
                  style={{ marginRight: -18, paddingLeft: 6 }}
                />
              </CardTitle>
              <CardBody>
                <CardItem label={t.owner[language]} value={data.owner?.name} />
                <CardItem
                  label={t.created[language]}
                  value={
                    data.created_at ? format(new Date(data.created_at), 'dd/MM/yyyy HH:mm') : ''
                  }
                />
                <CardItem
                  label={t.confirmation[language]}
                  value={data.with_confirmation ? 'Required' : 'Not required'}
                />
                <CardItem label={t.recipients[language]} value={isAll ? 'All' : 'Selected'} />
                <CardItem label={t.description[language]} value={data.description} vertical />
              </CardBody>
            </Card>
          </div>
          <div className={classes.cardContainer}>
            <Card>
              <CardTitle
                title={isAll ? t.excludedRecipients[language] : t.selectedRecipients[language]}
              />
              <CardBody>
                <Recipients recipients={isAll ? data.excludes : data.includes} />
              </CardBody>
            </Card>
          </div>
        </div>
        <div className={classes.tabsContainer}>
          <Tabs data={tabs} resizable />
        </div>
      </motion.div>
    </>
  )
}

export default OutboundTransferRequest
