import { useField } from 'formik'
import CloseIcon from '@mui/icons-material/Close'
import ExitToApp from '@mui/icons-material/ExitToApp'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  multiList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    paddingLeft: '0',
    margin: '0',
    marginTop: '-16px',
  },
  multiList_item: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    listStyle: 'none',
    '&.item--withLogo': {
      width: '100%',
      fontSize: 18,
      '& .item__name': {
        fontWeight: 500,
      },
    },
    // '&.item--withLogo:first-child': {
    //   marginTop: 0,
    // },
    '&:not(.item--withLogo)': {
      marginRight: '3px',
      padding: '5px 6px 5px 12px',
      borderRadius: '5px',
      border: 'solid 2px #6565cf',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      '& .item__remove': {
        fontSize: '12px',
        opacity: '0.6',
      },
    },
    '& .item__icon': {
      marginRight: '15px',
    },
    '& .item__name': {
      marginRight: '4px',
    },
    '& .item__remove': {
      marginLeft: 'auto',
      fontSize: '18px',
      cursor: 'pointer',
    },
  },
  multiChannelsList_item: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '15px',
    listStyle: 'none',
  },
}))

const SelectList = ({ listOfValues, withoutIcon, tags, ...rest }) => {
  const classes = useStyles()
  const [field, , helpers] = useField(rest)

  const onRemove = (id) => {
    helpers.setValue(field.value.filter((el) => el !== id))
  }

  return (
    <ul className={classes.multiList}>
      {field.value.map((el) => {
        return (
          <li className={clsx(classes.multiList_item, !tags && 'item--withLogo')} key={el}>
            {!withoutIcon && <ExitToApp className='item__icon' />}
            <div className='item__name'>
              {typeof el === 'object' && el !== null
                ? el.name || el.title || el.value
                : listOfValues.find((v) => v.id === el)?.label ||
                  listOfValues.find((v) => v.id === el)?.title ||
                  listOfValues.find((v) => v.id === el)?.value ||
                  listOfValues.find((v) => v.id === el)?.name}
            </div>
            <CloseIcon className='item__remove' onClick={onRemove.bind(this, el)} />
          </li>
        )
      })}
    </ul>
  )
}

export default SelectList
