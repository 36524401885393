import clsx from 'clsx'
import { useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import apiService from '../../../services/api-service'
import { translations as t } from '../../../services/translations'
import { useAppContext } from '../../App/AppContext'
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog'
import { useDashboardContext } from '../Dashboard'
import './ContextMenu.css'
import CurrentDeviceInfo from './CurrentDeviceInfo'

const Separator = () => <div style={{ borderBottom: '1px solid #555', margin: '5px 0' }} />
const successMsg = 'SUCCESS'

function ContextMenu({ show, setShow, item, refreshDashboard, eventCoordinates, presets }) {
  const {
    openFullScreenModal,
    openFullScreenLoader,
    closeFullScreenLoader,
    language,
  } = useAppContext()
  const { enqueueSnackbar } = useSnackbar()
  const { nodes } = useDashboardContext()

  // const [presets, setPresets] = useState([])
  const [position, setPosition] = useState({})

  // useEffect(() => {
  //   if (item.group === 'outputs' && show) {
  //     apiService
  //       .get('/virtual-matrix/presets', item.accountId)
  //       .then((res) => setPresets(res.data))
  //       .catch(console.log)
  //   }
  //   // eslint-disable-next-line
  // }, [show])

  useLayoutEffect(() => {
    if (show) {
      const div = document?.getElementById(item?.id)?.getBoundingClientRect()

      const validatedX = Math.max(
        0,
        Math.min(eventCoordinates?.x, window.innerWidth - div?.width - 50),
      )
      // - 50 +/- запас для скролла, 2 меню и тд
      const validatedY = Math.max(
        0,
        Math.min(eventCoordinates?.y, window.innerHeight - div?.height - 60),
      )
      // - 60 +/- запас для скролла, 2 меню и тд

      setPosition({
        x: validatedX,
        y: validatedY,
      })
    }

    return () => setPosition({})
    // eslint-disable-next-line
  }, [show])

  const isOutput = item.group === 'outputs'

  let myInputs = []
  let otherInputs = []
  let extInputs = []

  if (isOutput) {
    myInputs = nodes.filter((el) => el.group === 'inputs' && el.accountId === item.accountId)
    otherInputs = nodes.filter((el) => el.group === 'inputs' && el.accountId !== item.accountId)
    extInputs = nodes.filter(
      (el) => el.group === 'externalInputs' && el.accountId === item.accountId,
    )
  }

  let link = '#'
  let typeReboot = ''
  if (item.group === 'inputs' || item.group === 'outputs') {
    link = `${item.group}/${item.accountId}/${item.id}`
    typeReboot = item.group
  } else if (item.group === 'externalOutputs') {
    link = `/external-connections`
  } else if (item.group === 'externalInputs') {
    link = `/external-inputs/${item.accountId}/${item.id}`
    typeReboot = 'inputs'
  }

  const handleReboot = () => {
    return apiService
      .post(`/virtual-matrix/${typeReboot}/${item.id}/reboot`, null, item.accountId)
      .then((res) => {
        setShow(false)
      })
  }

  const handleClose = () => {
    let targetAccount = item.owner?.id || item.accountId
    let target = item.connection?.id
    return apiService.delete(`/virtual-matrix/connections/${target}`, targetAccount).then((res) => {
      setShow(false)
      refreshDashboard()
    })
  }

  const createConnection = (input) => {
    openFullScreenLoader()
    const url =
      input.group === 'inputs'
        ? '/virtual-matrix/connections/input'
        : '/virtual-matrix/connections/transfer'

    const body =
      input.group === 'inputs'
        ? {
            input: input.id,
            output: item.id,
          }
        : {
            transfer: input.id,
            output: item.id,
          }

    return apiService
      .post(url, body, item.accountId)
      .then((res) => {
        refreshDashboard()
        enqueueSnackbar(successMsg, {
          variant: 'success',
        })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err.response?.data.error || err.message, {
          variant: 'error',
        })
      })
      .finally(() => {
        setShow(false)
        closeFullScreenLoader()
      })
  }

  const createPresetConnection = (preset) => {
    openFullScreenLoader()
    const url = '/virtual-matrix/connections/preset'

    const body = {
      preset: preset.id,
      output: item.id,
    }

    return apiService
      .post(url, body, item.accountId)
      .then((res) => {
        refreshDashboard()
        enqueueSnackbar(successMsg, {
          variant: 'success',
        })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err.response?.data.error || err.message, {
          variant: 'error',
        })
      })
      .finally(() => {
        setShow(false)
        closeFullScreenLoader()
      })
  }

  const handleLock = () => {
    return apiService
      .patch(`/virtual-matrix/outputs/${item.id}`, { is_locked: !item.is_locked }, item.accountId)
      .then((res) => {
        refreshDashboard()
        setShow(false)
      })
  }

  return show ? (
    <div
      className='ContextMenu__root'
      style={{ top: position.y + 5, left: position.x + 8 }}
      id={item.id}
    >
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRebootIt[language]}
        onOk={handleReboot}
        optionalCallbackOnHandleClose={() => setShow(false)}
        id='reboot'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={
          item.is_locked
            ? t.areYouSureYouWantToUnlockIt[language]
            : t.areYouSureYouWantToLockIt[language]
        }
        onOk={handleLock}
        id='lock'
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToCloseThisConnection[language]}
        onOk={handleClose}
        optionalCallbackOnHandleClose={() => setShow(false)}
        id='closeConnection'
      />
      <Link to={link}>
        <p>{t.viewInfo[language]}</p>
      </Link>
      <CurrentDeviceInfo
        item={item}
        title={t.currentDeviceInfo[language]}
        optionalCallbackOnHandleClose={() => setShow(false)}
        id='currentDeviceInfo'
      />

      {/* -- Reboot -- */}
      {((item.group === 'inputs' && item.status !== 'unknown' && item.status !== 'offline') ||
        (item.group === 'outputs' && item.status !== 'offline')) && (
        <p onClick={() => openFullScreenModal('reboot')}>{t.reboot[language]}</p>
      )}

      {/* -- Create connection -- */}
      {(!isOutput || (!item.is_locked && item.status === 'online')) && (
        <p onClick={() => openFullScreenModal('addConnection', item)}>
          {t.createConnection[language]}
        </p>
      )}

      {/* -- Lock / Unlock -- */}
      {isOutput && (
        <p onClick={() => openFullScreenModal('lock')}>
          {item.is_locked ? t.unLock[language] : t.lock[language]}
        </p>
      )}

      {/* -- Close connection -- */}
      {item.connection && !item.is_locked && (
        <p
          onClick={() => {
            openFullScreenModal('closeConnection')
          }}
        >
          {t.closeConnection[language]}
        </p>
      )}

      {isOutput && !item.is_locked && (
        <>
          {!!myInputs.length && <Separator />}
          {myInputs.map((el) => (
            <p
              onClick={createConnection.bind(this, el)}
              className={clsx(el.id === item.connected && 'connected')}
              key={el.id}
            >
              {el.name}
            </p>
          ))}
          {!!otherInputs.length && <Separator />}
          {otherInputs.map((el) => (
            <p
              onClick={createConnection.bind(this, el)}
              className={clsx(el.id === item.connected && 'connected')}
              key={el.id}
            >
              {el.name}
            </p>
          ))}
          {!!extInputs.length && <Separator />}
          {extInputs.map((el) => (
            <p
              onClick={createConnection.bind(this, el)}
              className={clsx(el.id === item.connected && 'connected')}
              key={el.id}
            >
              {el.name}
            </p>
          ))}
          {!!presets.length && <Separator />}

          {/* -- Presets -- */}
          {!!presets.length && (
            <div className='ContextMenu__presetsContainer'>
              {t.presets[language]} >
              <div className='ContextMenu__presets'>
                {presets.map((el) => (
                  <p onClick={createPresetConnection.bind(this, el)} key={el.id}>
                    {el.name}
                  </p>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  ) : null
}

export default ContextMenu
