import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ukLocale from 'date-fns/locale/uk'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import SignIn from '../../views/SignIn/SignIn'
import Layout from '../Layout/Layout'

import Auth from '../../views/Auth/Auth'
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader'
import Spinner from '../Spinner/Spinner'
import { useAppContext } from './AppContext'
import routes from './routes'

const AppRouter = () => {
  const { status } = useAppContext()

  return (
    <Router>
      <FullScreenLoader />

      {status === 'authorized' ? (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
          <Layout>
            <Switch>
              {routes.map(({ Component, ...props }) => (
                <Route key={props.path} {...props}>
                  <Component />
                </Route>
              ))}
              <Redirect to='/dashboard' />
            </Switch>
          </Layout>
        </LocalizationProvider>
      ) : status === 'unauthorized' ? (
        <Switch>
          <Route key='auth' path='/auth' exact>
            <Auth />
          </Route>
          <Redirect to='/auth' />
        </Switch>
      ) : status === 'loading' ? (
        <Spinner />
      ) : (
        <Switch>
          <Route key='sign-in' path='/sign-in' exact>
            <SignIn />
          </Route>
          <Redirect to='/sign-in' />
        </Switch>
      )}
    </Router>
  )
}

export default AppRouter
