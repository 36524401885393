import { useField } from 'formik'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckboxMui from '@mui/material/Checkbox'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
    '&>span.MuiFormControlLabel-label': {
      marginTop: '10px',
      fontSize: 14,
      // color: 'rgba(255,255,255,.6)',
    },
    '& .MuiCheckbox-root': {
      // color: 'red',
    },
  },
}))

const CheckBox = ({ className, style, ...props }) => {
  const classes = useStyles()
  const [field, meta] = useField({
    ...props,
    name: props.name,
    type: 'checkbox',
    value: props.value,
  })

  const error = Boolean(meta.touched && meta.error)

  return (
    <FormControlLabel
      className={clsx(classes.root, className)}
      control={<CheckboxMui color='primary' {...props} {...field} />}
      label={props.label}
      style={style}
    >
      <FormHelperText error style={{ marginTop: -8 }}>
        {error ? meta.error : ' '}
      </FormHelperText>
    </FormControlLabel>
  )
}

export default CheckBox
