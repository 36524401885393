import { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import CardTitle from '../../Card/CardTitle'
import CardBody from '../../Card/CardBody'
import CardItem from '../../Card/CardItem'
import { useAppContext } from '../../App/AppContext'
import { translations as t } from '../../../services/translations'
import {
  capitalizeFirstLetter,
  isPrepareDeviceAttributes,
  numberFromFormatToMbps,
  prepareDeviceAttributes,
  prepareLabelDeviceAttributes,
} from '../../../services/helpers'

function ConfirmationDialogRaw(props) {
  const { onClose, open, title, description, ...other } = props
  const { language } = useAppContext()

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog
      // disableBackdropClick
      // disableEscapeKeyDown
      onEscapeKeyDown={handleCancel}
      maxWidth='xs'
      // onEntering={() => console.log('onEntering!')}
      aria-labelledby='confirmation-dialog-title'
      open={open}
      {...other}
    >
      <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button
          // autoFocus
          onClick={handleCancel}
          color='primary'
          style={{
            border: '1px solid rgba(255,255,255,0.1)',
            paddingLeft: 20,
            paddingRight: 20,
            margin: '0 auto 30px',
          }}
        >
          {t.close[language]}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 390,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 465,
    backgroundColor: theme.palette.background.default,
  },
}))

export default function CurrentDeviceInfo({
  id,
  title,
  item,
  optionalCallbackOnHandleClose = null,
}) {
  const classes = useStyles()
  const { fullScreenModal, openFullScreenModal, closeFullScreenModal, language } = useAppContext()
  const [deviceAttributes, setDeviceAttributes] = useState({
    dune: [],
    status: null,
    live_status: {
      live: {
        isLiveProperty: false,
      },
    },
    video_info: {
      isVideoInfoProperty: false,
    },
  })

  const handleClose = () => {
    closeFullScreenModal()

    if (optionalCallbackOnHandleClose) {
      optionalCallbackOnHandleClose()
    }
  }
  const isContextMenuForCurrentDeviceInfo = () => {
    return (
      deviceAttributes?.live_status?.isLiveProperty ||
      deviceAttributes?.video_info?.isVideoInfoProperty ||
      deviceAttributes.dune.length
    )
  }

  useEffect(() => {
    if (item !== null && 'device_attributes' in item) {
      const dev_att = item?.device_attributes !== null ? JSON.parse(item?.device_attributes) : null
      if (isPrepareDeviceAttributes(dev_att)) {
        setDeviceAttributes(prepareDeviceAttributes(dev_att))
      }
    }
    return () =>
      setDeviceAttributes({
        dune: [],
        status: null,
        live_status: {
          live: {
            isLiveProperty: false,
          },
        },
        video_info: {
          isVideoInfoProperty: false,
        },
      })
  }, [item])

  return (
    <>
      {!!isContextMenuForCurrentDeviceInfo() && (
        <p
          onClick={() => {
            openFullScreenModal(id, item)
          }}
        >
          {title}
        </p>
      )}
      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id='delete-connection'
        // keepMounted
        open={fullScreenModal.open && fullScreenModal.id === id}
        onClose={handleClose}
        description={
          deviceAttributes?.live_status?.isLiveProperty ||
          deviceAttributes?.video_info?.isVideoInfoProperty ? (
            <>
              <CardTitle title={item?.name}></CardTitle>
              <CardBody>
                {deviceAttributes?.live_status?.live?.result !== null && (
                  <CardItem
                    label={t.state[language]}
                    value={prepareLabelDeviceAttributes(deviceAttributes?.live_status.live.result)}
                  />
                )}
                {deviceAttributes?.status !== null &&
                  typeof deviceAttributes?.status === 'number' && (
                    <CardItem
                      label={t.state[language]}
                      value={prepareLabelDeviceAttributes(deviceAttributes?.status)}
                    />
                  )}
                {deviceAttributes?.live_status?.live?.cur_bps !== null &&
                  typeof deviceAttributes?.live_status?.live?.cur_bps === 'number' && (
                    <CardItem
                      label={t.currentBitrateMbps[language]}
                      value={numberFromFormatToMbps(
                        deviceAttributes?.live_status.live.cur_bps,
                        'ultra',
                      )}
                    />
                  )}
                {deviceAttributes?.video_info?.width !== null &&
                  typeof deviceAttributes?.video_info?.width === 'number' && (
                    <CardItem
                      label={t.width[language]}
                      value={deviceAttributes?.video_info?.width}
                    />
                  )}
                {deviceAttributes?.video_info?.height !== null &&
                  typeof deviceAttributes?.video_info?.height === 'number' && (
                    <CardItem
                      label={t.height[language]}
                      value={deviceAttributes?.video_info?.height}
                    />
                  )}
                {deviceAttributes?.video_info?.scan !== null && (
                  <CardItem
                    label={t.scan[language]}
                    value={capitalizeFirstLetter(deviceAttributes?.video_info?.scan)}
                  />
                )}
                {deviceAttributes?.video_info?.field_rate !== null &&
                  typeof deviceAttributes?.video_info?.field_rate === 'number' && (
                    <CardItem
                      label={t.frameRate[language]}
                      value={deviceAttributes?.video_info?.field_rate}
                    />
                  )}
              </CardBody>
            </>
          ) : deviceAttributes.dune && deviceAttributes?.dune.length ? (
            <>
              <CardTitle title={item?.name} />
              <CardBody>
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_state') && (
                    <CardItem
                      label={t.state[language]}
                      value={capitalizeFirstLetter(
                        deviceAttributes.dune.find((item) => item.name === 'playback_state').value,
                      )}
                    />
                  )}
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_state') && (
                    <CardItem
                      label={t.currentBitrateMbps[language]}
                      value={numberFromFormatToMbps(
                        deviceAttributes.dune.find(
                          (item) => item.name === 'playback_current_bitrate',
                        ).value,
                        'dune',
                      )}
                    />
                  )}
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_state') && (
                    <CardItem
                      label={t.width[language]}
                      value={
                        deviceAttributes.dune.find((item) => item.name === 'playback_video_width')
                          .value
                      }
                    />
                  )}
                {deviceAttributes.dune.length &&
                  deviceAttributes.dune.find((item) => item.name === 'playback_video_height') && (
                    <CardItem
                      label={t.height[language]}
                      value={
                        deviceAttributes.dune.find((item) => item.name === 'playback_video_height')
                          .value
                      }
                    />
                  )}
              </CardBody>
            </>
          ) : null
        }
      />
    </>
  )
}
