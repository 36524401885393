import Dashboard from '../../views/Dashboard/Dashboard'
import Inputs from '../../views/Inputs/Inputs'
import Input from '../../views/Inputs/Input/Input'
// import AvailableChannels from '../../views/AvailableChannels/AvailableChannels'
import ExternalInputs from '../../views/ExternalInputs/ExternalInputs'
import ExternalInputCard from '../../views/ExternalInputs/ExternalInputCard/ExternalInputCard'
// import InputTransfers from '../../views/InputTransfers/InputTransfers'
import InputTransfersCard from '../../views/InputTransfers/InputTransfersCard/InputTransfersCard'
import Outputs from '../../views/Outputs/Outputs'
import Output from '../../views/Outputs/OutputCard/Output'
import Presets from '../../views/Presets/Presets'
import InternalConnections from '../../views/InternalConnections/InternalConnections'
// import OutboundTransferRequests from '../../views/OutboundTransferRequests/OutboundTransferRequests'
import OutboundTransferRequest from '../../views/OutboundTransferRequests/OutboundTransferRequest/OutboundTransferRequest'
// import InboundTransferRequests from '../../views/InboundTransferRequests/InboundTransferRequests'
import InboundRequestCard from '../../views/InboundTransferRequests/InboundRequestCard/InboundRequestCard'
import ExternalConnections from '../../views/ExternalConnections/ExternalConnections'

const routes = [
  { path: '/dashboard', Component: Dashboard, exact: true },
  { path: '/inputs', Component: Inputs, exact: true },
  { path: '/inputs/:contextId/:inputId', Component: Input, exact: true },
  { path: '/external-inputs', Component: ExternalInputs, exact: true },
  { path: '/external-inputs/:contextId/:inputID', Component: ExternalInputCard, exact: true },
  // { path: '/input-transfers', Component: InputTransfers, exact: true },
  { path: '/input-transfers/:contextId/:transferID', Component: InputTransfersCard, exact: true },
  { path: '/outputs', Component: Outputs, exact: true },
  { path: '/outputs/:contextId/:outputId', Component: Output, exact: true },
  // { path: '/available-channels', Component: AvailableChannels, exact: true },
  { path: '/internal-connections', Component: InternalConnections, exact: true },
  { path: '/external-connections', Component: ExternalConnections, exact: true },
  // { path: '/outbound-transfer-requests', Component: OutboundTransferRequests, exact: true },
  {
    path: '/outbound-transfer-requests/:contextId/:requestId',
    Component: OutboundTransferRequest,
    exact: true,
  },
  { path: '/presets', Component: Presets, exact: true },
  // { path: '/inbound-transfer-requests', Component: InboundTransferRequests, exact: true },
  {
    path: '/inbound-transfer-requests/:contextId/:requestId',
    Component: InboundRequestCard,
    exact: true,
  },
]

export default routes
