import makeStyles from '@mui/styles/makeStyles'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import Table from '../../../components/Table/Table'
import TableBody from '../../../components/Table/TableBody'
import useSorting from '../../../services/sorting'
import TableCell from '../../../components/Table/TableCell'
import NoItemsMessage from '../../../components/NoItemsMessage/NoItemsMessage'
// import ServersContextMenu from './ServersContextMenu'
import clsx from 'clsx'
import { useAppContext } from '../../../components/App/AppContext'
import { translations as t } from '../../../services/translations'
import TableHead from '../../../components/Table/TableHead'
import AddBtn from '../../../components/AddBtn/AddBtn'
import EditBtn from '../../../components/ActionButtons/EditBtn/EditBtn'
import DeleteBtn from '../../../components/ActionButtons/DeleteBtn/DeleteBtn'
import CallMadeIcon from '@mui/icons-material/CallMade'
import UniversalBtn from '../../../components/ActionButtons/UniversalBtn'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 'auto',
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
    '& .doubleLine > p': {
      lineHeight: '18px',
      fontWeight: 500,
      whiteSpace: 'nowrap,',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // paddingRight: 12,
  },
  tableContainer: {
    height: '100%',
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
  subCell: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(101, 101, 207, 0.3)',
    display: 'grid',
    placeItems: 'center',
  },
  imgCell: {
    width: 60,
    height: 34,
    objectFit: 'cover',
  },
  customRow: {
    '& th,td': {
      height: 'auto',
      padding: '0 16px 0 16px',
    },
  },
  greenRow: {
    '& td,th': {
      color: theme.palette.active.main,
    },
  },
  redRow: {
    '& td,th': {
      color: theme.palette.disable.main,
    },
  },
}))

const ServersTable = ({ data }) => {
  const classes = useStyles()
  const {
    language,
    openFullScreenModal,
    // fullScreenModal: { buffer },
  } = useAppContext()
  const { order, orderBy, createSortHandler } = useSorting('')

  const headCells = [
    [
      {
        id: 'name',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.name[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      // { id: 'streamKey', numeric: false, label: 'Stream key' },
      { id: 'type', numeric: false, label: t.type[language] },
      { id: 'url', numeric: false, label: t.url[language] },
      {
        id: 'add',
        numeric: false,
        label: <AddBtn onClick={() => openFullScreenModal('editServers', null)} />,
        props: {
          align: 'right',
          width: 140,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  const createFullUrlStr = (url, streamKey) =>
    `${url}${streamKey[0] !== '/' && url[url.length - 1] !== '/' ? '/' + streamKey : streamKey}`

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.root} stickyHeader>
        <TableHead
          headCells={headCells}
          createSortHandler={createSortHandler}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow
                key={row.id}
                className={clsx(
                  // classes.customRow,
                  row.status === 'enabled' && classes.greenRow,
                  row.status === 'disabled' && classes.redRow,
                )}
              >
                <TableCell component='th' scope='row'>
                  {row.name}
                </TableCell>
                <TableCell>{row.type === 0 ? 'RTMP' : 'SRT Caller'}</TableCell>
                <TableCell>
                  {row.url
                    ? createFullUrlStr(row.url, row.stream_key)
                    : `${row.address}:${row.port}`}
                </TableCell>
                {/*<TableCell>/!*<ServersContextMenu data={row} />*!/</TableCell>*/}
                <TableCell>
                  <div className={classes.buttonTdContainer}>
                    {row?.type === 0 && row?.status === 'enabled' && (
                      <UniversalBtn
                        onClick={() =>
                          openFullScreenModal('applyServer', {
                            ...row,
                            updUrl: createFullUrlStr(row.url, row.stream_key),
                          })
                        }
                        icon={CallMadeIcon}
                        alt='Apply for device'
                      />
                    )}
                    {row?.status !== 'enabled' && (
                      <UniversalBtn
                        onClick={() => openFullScreenModal('enableServer', row)}
                        icon={DoneIcon}
                        alt='Enable'
                      />
                    )}
                    {row?.status === 'enabled' && (
                      <UniversalBtn
                        onClick={() => openFullScreenModal('disableServer', row)}
                        icon={CloseIcon}
                        alt='Disable'
                      />
                    )}
                    <EditBtn onClick={() => openFullScreenModal('editServers', row)} />
                    <DeleteBtn onClick={() => openFullScreenModal('deleteServer', row)} />
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <NoItemsMessage data={data} message='Data not found' status={'success'} />
    </TableContainer>
  )
}

export default ServersTable
