import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/DeleteOutline'

function DeleteBtn(props) {
  return (
    <IconButton color='inherit' {...props} size='large'>
      <DeleteIcon />
    </IconButton>
  )
}

export default DeleteBtn
