import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import makeStyles from '@mui/styles/makeStyles'
import { Grid } from '@mui/material'

import FullScreenModal from '../../../components/FullScreenModal/FullScreenModal'
import Select from '../../../forms/Select'
import TextField from '../../../forms/TextField'
import DateTimePicker from '../../../forms/DateTimePicker'
import apiService from '../../../services/api-service'
import { useAppContext } from '../../../components/App/AppContext'
import SelectList from '../../../forms/SelectList'
import SubmitButton from '../../../forms/SubmitButton'
import CheckBox from '../../../forms/CheckBox'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 1100,
    margin: '0 auto',
    padding: '48px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 20,
  },
  eventEnd: {
    marginTop: 110,
  },
  isPermanent: {
    marginTop: 32,
  },
  validTill: {
    marginTop: 36,
  },
  container: {
    height: 400,
    paddingRight: 24,
  },
}))

const AddEvent = ({ onOk }) => {
  const classes = useStyles()
  const { requestId } = useParams()
  const {
    language,
    closeFullScreenModal,
    fullScreenModal: { open, id, buffer },
  } = useAppContext()

  const initialValues = {
    eventName: buffer?.name || '',
    selectInput: buffer?.input?.id || '',
    eventStart: buffer?.event_start_time || null,
    eventEnd: buffer?.event_end_time || null,
    validFrom: buffer?.start_time || null,
    validTill: buffer?.end_time || null,
    isPermanent: buffer ? !(buffer?.start_time || buffer?.end_time) : true,
    tags: buffer?.tags?.map((el) => el.id) || [],
  }

  const [tags, setTags] = useState([])
  const [inputs, setInputs] = useState([])

  useEffect(
    () => {
      if (open && id === 'addEvent') {
        Promise.all([
          apiService.get('/virtual-matrix/tags'),
          apiService.get('/virtual-matrix/inputs'),
        ])
          .then(([res1, res2]) => {
            setTags(res1.data)
            setInputs(res2.data)
          })
          .catch(console.log)
      }
    },
    // eslint-disable-next-line
    [open],
  )

  return (
    <FullScreenModal id='addEvent' title={buffer ? t.editEvent[language] : t.newEvent[language]}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const event = {
            name: values.eventName,
            event_start_time: values.eventStart,
            event_end_time: values.eventEnd,
            start_time: values.isPermanent ? null : values.validFrom,
            end_time: values.isPermanent ? null : values.validTill,
            input: values.selectInput,
            tags: values.tags,
          }

          const request = buffer
            ? apiService.patch(`/virtual-matrix/transfer-requests/events/${buffer.id}`, event)
            : apiService.post(`/virtual-matrix/transfer-requests/${requestId}/events`, event)

          return request
            .then(() => {
              onOk()
            })
            .catch(console.log)
            .finally(() => {
              closeFullScreenModal()
            })
        }}
        validationSchema={object({
          eventName: string().required(t.fieldIsRequired[language]),
          selectInput: string().required(t.fieldIsRequired[language]),
        })}
      >
        {({ values, isSubmitting }) => (
          <Form noValidate className={classes.formContainer}>
            <Grid container spacing={5} style={{ minHeight: '600px' }}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <TextField label={t.eventName[language]} name='eventName' required />
                <DateTimePicker
                  name='eventStart'
                  label={t.eventStart[language]}
                  className={classes.dateTime}
                  clean
                />
                <Select label={t.selectInput[language]} name='selectInput' required fullWidth>
                  {inputs.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                </Select>
                {!values.isPermanent && (
                  <DateTimePicker
                    name='validFrom'
                    label={t.validFrom[language]}
                    className={classes.dateTime}
                    clean
                  />
                )}
              </Grid>
              {/*END - первая колонка*/}

              {/* Средняя колонка*/}
              <Grid item xs={4}>
                <DateTimePicker
                  clean
                  name='eventEnd'
                  label={t.eventEnd[language]}
                  className={classes.eventEnd}
                />
                <CheckBox
                  name='isPermanent'
                  label={t.permanentTransfer[language]}
                  className={classes.isPermanent}
                />
                {!values.isPermanent && (
                  <DateTimePicker
                    name='validTill'
                    label={t.validTill[language]}
                    className={classes.validTill}
                    clean
                  />
                )}
              </Grid>
              {/* END - Средняя колонка*/}

              {/* Третья колонка*/}
              <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                <Select label={t.tags[language]} name='tags' fullWidth multiple>
                  {tags.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                </Select>
                <SelectList name='tags' tags withoutIcon listOfValues={tags} />
              </Grid>
              {/* END - Третья колонка*/}
            </Grid>
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
            {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default AddEvent
