import { motion } from 'framer-motion'
import ResizeObserver from 'rc-resize-observer'
import { useEffect, useState } from 'react'

import SimpleSwitcher from '../../../forms/SimpleSwitcher'
import apiService from '../../../services/api-service'
import { getGroups, getLineColor } from '../../../services/helpers'
import { translations as t } from '../../../services/translations'
import AddConnection from '../../AddConnection/AddConnection'
import { useAppContext } from '../../App/AppContext'
import CloseConnections from '../../CloseConnections/CloseConnections'
import { useDashboardContext } from '../Dashboard'
import DashboardHeading from '../DashboardHeading/DashboardHeading'
import MatrixItem from '../MatrixItem/MatrixItem'
import SvgGraph from '../SvgGraph/SvgGraph'
import './DashboardContent.css'

function DashboardContent() {
  const { language } = useAppContext()
  const frequencyRefresh = 15 // секунды, частота обновления дашборда
  const { nodes, setNodes, setRelations, doRefresh } = useDashboardContext()
  const [mode, setMode] = useState('account')
  const [presets, setPresets] = useState([])

  const sortNodes = (a, b) => {
    if (mode === 'account') {
      return a.sort > b.sort ? 1 : -1
    } else {
      return (a.name || a.input.name) > (b.name || b.input.name) ? 1 : -1
    }
  }

  const getDashboard = () => {
    return apiService
      .get('/virtual-matrix/dashboard')
      .then(({ data }) => {
        // return Promise.resolve({ data }).then(({ data }) => {
        const accountArrays = data.map((account) => [
          ...account.data.inputs.map((el) => ({
            ...el,
            group: 'inputs',
            accountId: account.account.id,
            accountName: account.account.name,
            locationId: el.location.id,
            locationName: el.location.name,
          })),
          ...account.data.outputs.map((el) => ({
            ...el,
            group: 'outputs',
            accountId: account.account.id,
            accountName: account.account.name,
            locationId: el.location.id,
            locationName: el.location.name,
          })),
          ...account.data.external_outputs.map((el) => ({
            ...el,
            id: el.id + 'ext',
            group: 'externalOutputs',
            accountId: account.account.id,
            accountName: account.account.name,
            locationId: el.location.id,
            locationName: el.location.name,
          })),
          ...account.data.external_inputs.map((el) => ({
            ...el,
            name: el.input.name,
            status: el.input.status,
            group: 'externalInputs',
            accountId: account.account.id,
            accountName: account.account.name,
            locationId: el.input.location.id,
            locationName: el.input.location.name,
          })),
        ])

        let rawNodes = []
        accountArrays.forEach((el) => (rawNodes = rawNodes.concat(el)))
        const rawRelations = []

        rawNodes.forEach((el) => {
          if (el.connections) {
            el.connections.forEach((connection) => {
              const source = el.id
              const target = rawNodes.find(
                (node) =>
                  node.id.slice(node.id.length - 3) !== 'ext' &&
                  connection.id === node.connection?.id,
              )?.id
              if (target) {
                const newConnection = {
                  ...connection,
                  source,
                  target,
                }
                rawRelations.push(newConnection)
              }
            })
          }
          if (el.transfers && el.transfers.length) {
            const trs = el.transfers.filter((el) => el.connections.length > 0)
            trs.forEach((t) => {
              t.connections.forEach((connection) => {
                const source = el.id
                const target = rawNodes.find(
                  (node) =>
                    node.id.slice(node.id.length - 3) === 'ext' &&
                    connection.id === node.connection?.id,
                )?.id
                if (target) {
                  const newConnection = {
                    ...connection,
                    id: connection.id + 'ext',
                    source,
                    target,
                  }
                  rawRelations.push(newConnection)
                }
              })
            })
          }
        })

        const getColor = getLineColor()
        rawRelations.forEach((el) => {
          const node = rawNodes.find((n) => n.id === el.source)
          node.source = true
        })
        let shift = 0
        const nodeUpd = rawNodes.map((el) => {
          return {
            ...el,
            color: el.source && getColor(),
            shift: el.source && shift++,
          }
        })
        rawRelations.forEach((el) => {
          const sourceNode = nodeUpd.find((n) => n.id === el.source)
          const targetNode = nodeUpd.find((n) => n.id === el.target)
          targetNode.color = sourceNode.color
          targetNode.connected = sourceNode.id
        })
        const prsts = data
          .reduce((acc, el) => {
            acc = [...acc, ...el.data?.presets]
            return acc
          }, [])
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        setNodes(nodeUpd)
        setRelations(rawRelations)
        setPresets(prsts)
      })
      .catch(console.log)
  }

  const refreshDashboard = () => {
    getDashboard().then(() => {
      setTimeout(doRefresh, 100)
      setTimeout(doRefresh, 200)
    })
  }

  const handleMode = () => setMode((mode) => (mode === 'account' ? 'location' : 'account'))

  useEffect(() => {
    getDashboard().then(() => setTimeout(doRefresh, 100))
    const refreshInterval = setInterval(refreshDashboard, 1000 * frequencyRefresh)
    return () => clearInterval(refreshInterval)
    // eslint-disable-next-line
  }, [])

  const trigger = mode === 'location' ? 'locationId' : 'accountId'
  const label = mode === 'location' ? 'locationName' : 'accountName'
  let content = getGroups(nodes, trigger)

  // ставим активный аккаунт верхним
  const ownerId = apiService.getCredential().cin
  const owner = content.find((el) => el.id === ownerId)
  if (owner) {
    content = content.filter((el) => el.id !== ownerId)
    content = [owner, ...content]
  }

  content = content.map((el) => {
    const name = el.data[0][label]
    const sorted = {}
    el.data.forEach((e) => {
      sorted[e.group] = sorted[e.group] ? [...sorted[e.group], e] : [e]
    })
    const maxNumItem = Math.max(
      sorted.externalOutputs?.length || 0,
      sorted.inputs?.length || 0,
      sorted.outputs?.length || 0,
      sorted.externalInputs?.length || 0,
    )
    const calcColHeight = 57 * maxNumItem + 8 + 11
    const colHeight = calcColHeight > 200 ? calcColHeight : 200

    return {
      ...el,
      data: sorted,
      groupName: name,
      colHeight,
    }
  })

  if (mode === 'location') {
    content.sort((a, b) => (a.groupName > b.groupName ? 1 : -1))
  }

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ marginTop: -14 }}
    >
      <AddConnection onOk={refreshDashboard} />
      <CloseConnections onOk={refreshDashboard} />
      <div style={{ position: 'fixed', top: 8, marginLeft: 250, zIndex: 1299 }}>
        <SimpleSwitcher
          value={mode === 'account'}
          upperTitle={t.byCompany[language]}
          lowerTitle={t.byLocation[language]}
          onChange={handleMode}
          noLable
        />
      </div>
      <DashboardHeading nodes={nodes} />
      <ResizeObserver onResize={doRefresh}>
        <div className='DashboardContent__dashboard'>
          <SvgGraph refreshDashboard={refreshDashboard} />
          {content.map((el) => (
            <div className='DashboardContent__container' key={el.id}>
              <div className='DashboardContent__owner' title={el.groupName}>
                <div style={{ width: el.colHeight + 'px' }}>
                  <p>{el.groupName}</p>
                </div>
              </div>
              <div className='DashboardContent__columnContainer'>
                <div className='DashboardContent__column'>
                  {el.data.externalOutputs?.sort(sortNodes).map((el) => {
                    return (
                      <MatrixItem
                        key={el.id}
                        item={el}
                        xmode={mode}
                        refreshDashboard={refreshDashboard}
                        presets={presets}
                      />
                    )
                  })}
                </div>
                <div className='DashboardContent__column'>
                  {el.data.inputs?.sort(sortNodes).map((el) => {
                    return (
                      <MatrixItem
                        item={el}
                        key={el.id}
                        xmode={mode}
                        refreshDashboard={refreshDashboard}
                        presets={presets}
                      />
                    )
                  })}
                </div>
                <div className='DashboardContent__column'>
                  {el.data.outputs?.sort(sortNodes).map((el) => {
                    return (
                      <MatrixItem
                        item={el}
                        key={el.id}
                        xmode={mode}
                        refreshDashboard={refreshDashboard}
                        presets={presets}
                      />
                    )
                  })}
                </div>
                <div className='DashboardContent__column'>
                  {el.data.externalInputs?.sort(sortNodes).map((el) => {
                    return (
                      <MatrixItem
                        item={el}
                        key={el.id}
                        xmode={mode}
                        refreshDashboard={refreshDashboard}
                        presets={presets}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </ResizeObserver>
    </motion.div>
  )
}

export default DashboardContent
