import { Autocomplete as AutocompleteMui } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import ListItem from '@mui/material/ListItem'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useField } from 'formik'
import { useState } from 'react'
import TextField from './SimpleTextField'
import iconSelected from '../assets/img/icons/autocomplete/selected.png'
import iconUnselected from '../assets/img/icons/autocomplete/unselected.png'
import { translations as t } from '../services/translations'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    marginBottom: 0,
    // marginBottom: '-8px',
  },
  root: {
    '& div div': {
      // backgroundColor: 'red !important',
      // display: 'none'
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  autocomplete: {
    width: '100%',
  },
  paper: {
    maxHeight: 320,
    backgroundColor: theme.palette.background.default,

    '&.MuiPaper-root': {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',

      //scroll
      '&::-webkit-scrollbar': {
        width: '7px !important',
      },
      // width
      '& *::-webkit-scrollbar': {
        width: '7px !important',
        backgroundColor: 'transparent',
      },
      // Track
      '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
        borderRadius: '5px',
        backgroundColor: 'inherit',
      },
      // Handle
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        height: '2.75em',
        borderRadius: '5px',
      },

      '& .MuiAutocomplete-listbox': {
        maxHeight: '100%',
        overflow: 'hidden',
      },
    },

    '& ul li': {
      color: theme.palette.text.primary,
      '&.MuiAutocomplete-option': {
        padding: '5px 10px 5px 35px',
        borderBottom: `1px solid ${theme.palette.background.default}`,
        borderRight: `1px solid ${theme.palette.background.default}`,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '&.MuiAutocomplete-option.Mui-focused:hover': {
        backgroundColor: `${theme.palette.primary.mainLight} !important`,
      },
    },
  },
  popper: {},
  clearIndicator: {
    marginRight: '-10px',
  },
  arrow: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.dark,
    },
  },
  arrowError: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
}))
/* eslint-disable no-use-before-define */

const AutocompleteCheckbox = (props) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)
  const error = Boolean(meta.touched && meta.error)

  const [inputValue, setInputValue] = useState('')

  const onChange = (e, v, t) => {
    let ids = [...new Set(v.map((el) => (typeof el === 'string' ? el : el?.id)))]

    if (t === 'clear') {
      helpers.setValue([])
      setInputValue('')
      return
    } else if (e.code !== 'Backspace') {
      helpers.setValue(ids)
      // setInputValue('')
      return
    }
  }

  return (
    <FormControl
      error={error}
      onBlur={() => helpers.setTouched(true)}
      fullWidth={true}
      margin='normal'
      variant='standard'
      style={props.style}
      className={clsx(classes.formControl, props.className, error && classes.error)}
    >
      <AutocompleteMui
        value={field.value}
        onChange={onChange}
        onBlur={() => setInputValue('')}
        getOptionLabel={(option) => {
          if (props.getOptionLabel) {
            return props.getOptionLabel(option)
          }
          return option?.label || option?.name || option?.title || option?.slug || ''
        }}
        options={props.options}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value
        }}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={option.id}>
            {selected ? (
              <img
                src={iconSelected}
                alt='selected icon'
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 9,
                  height: 15,
                  width: 15,
                }}
              />
            ) : (
              <img
                src={iconUnselected}
                alt='unselected icon'
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 9,
                  height: 15,
                  width: 15,
                }}
              />
            )}
            {option.label || option.name || option.title || option.slug || ''}
          </ListItem>
        )}
        renderTags={() => null}
        noOptionsText={
          props.noOptionsText || props.language ? t.noDataFound[props.language] : 'Data not found'
        }
        inputValue={inputValue}
        onInputChange={(e, v, r) => r === 'input' && setInputValue(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            sx={{
              '& .MuiInputBase-root': {
                '& input': {
                  '&::placeholder': {
                    fontSize: 17,
                  },
                },
              },
            }}
            placeholder={
              props.placeholder || props.language
                ? t.clickToSelect[props.language]
                : 'Click to select ...'
            }
            error={error}
            variant='standard'
            required={props.required}
          />
        )}
        multiple={true}
        classes={{
          root: classes.autocomplete,
          paper: classes.paper,
          listbox: classes.popper,
          clearIndicator: classes.clearIndicator,
        }}
        className={clsx(error ? classes.arrowError : classes.arrow, classes.root)}
        id={`${props.name}-id`}
        disabled={props.disabled}
        disableCloseOnSelect={true}
      />
      <FormHelperText sx={{ marginTop: '3px' }} error>
        {error ? meta?.error || meta?.error?.label || meta?.error?.name : ' '}
      </FormHelperText>
    </FormControl>
  )
}

export default AutocompleteCheckbox
