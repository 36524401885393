import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import apiService from '../../../services/api-service'
import InputTransfersTable from '../../InputTransfers/InputTransfersTable'

const InputTransfersTab = () => {
  const { contextId, requestId } = useParams()
  const [data, setData] = useState([])

  const getData = () => {
    apiService
      .get(`/virtual-matrix/transfers/input?filter[transfer_request]=${requestId}`, contextId)
      .then((res) => {
        setData(res.data)
      })
      .catch(console.log)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <InputTransfersTable account={contextId} data={data} withoutPagination status={'success'} />
  )
}

export default InputTransfersTab
