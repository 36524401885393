import clsx from 'clsx'
import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import Table from '../../components/Table/Table'
import TableBody from '../../components/Table/TableBody'
import TableHead from '../../components/Table/TableHead'
import TableCell from '../../components/Table/TableCell'
import Pagination from '../../components/Pagination/Pagination'
import useSorting from '../../services/sorting'
import NoItemsMessage from '../../components/NoItemsMessage/NoItemsMessage'
import { useAppContext } from '../../components/App/AppContext'
// import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
// import AddTransferRequest from './AddTransferRequest/AddTransferRequest'
// import EditTransferRequest from './OutboundTransferRequest/EditTransferRequest'
// import apiService from '../../services/api-service'
import Status from '../../components/Status/Status'
import ClearBtn from '../../components/ActionButtons/ClearBtn/ClearBtn'
import Connection from '../../components/Connection/Connection'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
    buttonTdContainer: {
      display: 'flex',
    },
  },
  tableContainer: {
    height: 'calc(100% - 80px)',
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
}))

const ConnectionsTable = ({
  data,
  status,
  page,
  refreshData,
  pageCount,
  handleChangePage,
  account,
  ...props
}) => {
  const classes = useStyles()

  const {
    language,
    openFullScreenModal,
    // fullScreenModal: { buffer },
  } = useAppContext()

  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')

  // const handleDeleteRequest = (requestId) => {
  //   return apiService
  //     .delete(`/virtual-matrix/transfer-requests/${requestId}`)
  //     .then((res) => {
  //       console.log(res)
  //       refreshData()
  //     })
  //     .catch(console.log)
  // }

  const headCells = [
    [
      {
        id: 'source',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.source[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      { id: 'output', numeric: false, label: `${t.output[language]}` },
      { id: 'created', numeric: false, label: `${t.created[language]}` },
      { id: 'validFrom', numeric: false, label: `${t.validFrom[language]}` },
      { id: 'validTill', numeric: false, label: `${t.validTill[language]}` },
      { id: 'status', numeric: false, label: `${t.status[language]}` },
      {
        id: 'add',
        numeric: false,
        label: <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellRight)} />,
        props: {
          align: 'right',
          width: 120,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  return (
    <>
      {/*<AddTransferRequest accountId={account} />*/}
      {/*<EditTransferRequest accountId={account} />*/}
      {/*<ConfirmationDialog*/}
      {/*  title='Warning!'*/}
      {/*  description='Are you sure you want to remove the request?'*/}
      {/*  onOk={handleDeleteRequest.bind(this, buffer?.id)}*/}
      {/*  id={`deleteTransferRequest${account}`}*/}
      {/*/>*/}
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.root} stickyHeader>
          <TableHead
            headCells={headCells}
            createSortHandler={createSortHandler}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    <Connection
                      name={row.transfer?.input?.name || row.url}
                      link={`/internal-connections/${account}/${row.id}`}
                    />
                  </TableCell>
                  <TableCell>{row.output.name}</TableCell>
                  <TableCell>{format(new Date(row.created_at), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>{format(new Date(row.start_time), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>
                    {row.stop_time
                      ? format(new Date(row.stop_time), 'dd/MM/yyyy HH:mm')
                      : 'permanent'}
                  </TableCell>
                  <TableCell>
                    <Status status={row.status} />
                  </TableCell>
                  <TableCell>
                    <ClearBtn
                      onClick={() => openFullScreenModal(`deleteTransferRequest${account}`, row)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <NoItemsMessage
          data={data}
          message={t.NoExternalConnectionsYet[language]}
          status={status}
        />
      </TableContainer>
      {!props.withoutPagination && (
        <Pagination count={pageCount} page={page} onChange={handleChangePage} />
      )}
    </>
  )
}

export default ConnectionsTable
