import { useState } from 'react'
import { useField } from 'formik'
import TextFieldMui from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import ClearBtn from '../components/ActionButtons/ClearBtn/ClearBtn'

const useStyles = makeStyles((theme) => ({
  root: {
    '& label.MuiFormLabel-root': {
      color: theme.palette.text.dark,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
    '& .MuiInputBase-multiline': {
      padding: '3px 0 7px',
    },
    '& div': {
      '&:before': {
        borderColor: theme.palette.text.dark,
      },
      '&:after': {
        borderColor: theme.palette.text.dark,
      },
      '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
        '-webkit-text-fill-color': theme.palette.text.primary,
        '-webkit-box-shadow': '0 0 0px 1000px rgba(255, 255, 255, 0.05) inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
    '& .MuiInputBase-inputMultiline': {
      lineHeight: 1.4,
    },
  },
  error: {
    '& div': {
      '&:before': {
        borderColor: theme.palette.error.main,
      },
      '&:after': {
        borderColor: theme.palette.error.main,
      },
      '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
        '-webkit-text-fill-color': theme.palette.error.main,
        '-webkit-box-shadow': '0 0 0px 1000px rgba(255, 0, 0, 0.1) inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
  margin: {
    marginBottom: 8,
  },
  clearBtn: {
    '& svg': {
      fill: theme.palette.text.dark,
      marginRight: '-6px',
    },
    position: 'absolute',
    right: (props) => (props.type ? 25 : 3),
    top: 5,
    zIndex: 1000,
    '&.MuiIconButton-root': {
      padding: 1,
    },
    '& .MuiSvgIcon-root': {
      width: '19px',
      height: '19px',
      backgroundColor: '#2a365a !important',
    },
  },
}))

export const TextField = ({ label, ...props }) => {
  const classes = useStyles(props)
  const [field, meta, helpers] = useField(props)

  const error = Boolean(meta.touched && meta.error)
  const [show, setShow] = useState(false)

  return (
    <TextFieldMui
      {...field}
      {...props}
      onChange={(e) => {
        if (props.type === 'number' && e.target.value < 0) {
          return
        } else if (props.type === 'number') {
          helpers.setValue(Number(e.target.value))
          return
        } else {
          helpers.setValue(e.target.value)
          return
        }
      }}
      classes={{ root: classes.margin }}
      className={clsx(classes.root, props.error && classes.error, props.className)}
      InputProps={{
        endAdornment: (
          <ClearBtn
            className={classes.clearBtn}
            onClick={() => {
              if (props.type === 'number') {
                helpers.setValue(0)
              } else {
                helpers.setValue('')
              }
            }}
            sx={{ visibility: show && field.value.length > 0 ? 'visible' : 'hidden' }}
          />
        ),
      }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      label={label}
      id={props.name}
      fullWidth={props.hasOwnProperty('fullWidth') ? (props.fullWidth ? true : false) : true}
      variant='standard'
      margin='normal'
      error={error}
      helperText={error ? meta.error : ' '}
      inputProps={{ readOnly: props.readOnly }}
    />
  )
}

export default TextField
