import { useRef, useState } from 'react'
import { Popover, Stack } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import IconButton from '@mui/material/IconButton'
import AppsIcon from '@mui/icons-material/Apps'
import { alpha, styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useAppContext } from '../App/AppContext'
// Icons
import cioc from '../../assets/img/cioc_logo.svg'
import playout from '../../assets/img/playout.svg'
import matrix from '../../assets/img/matrix_logo.svg'
import stream from '../../assets/img/online-streaming.svg'
import dashboard from '../../assets/img/dashboard.svg'
import { translations as t } from '../../services/translations'

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    // background: theme.palette.background.paper,
    background: '#1e2a4f', //'#364473', //'#1e2a4f !important',
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}))
const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  popperNav: {
    '& .MuiPaper-root': {
      marginTop: 5,
      marginRight: 16,
      backgroundColor: theme.palette.background.color1,
      width: 224,
      height: 280,
      padding: '8px 0',
      '&::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: '-18px',
        left: '140px',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '12px',
        borderColor: `transparent transparent ${theme.palette.background.color1} transparent`,
      },
    },
  },
  menuActive: {
    backgroundColor: theme.palette.primary.main,
  },
  menuContainer: {
    padding: '10px 16px',
    '& .companyAcc': {
      height: 75,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: 7,
      marginBottom: 6,
      textDecoration: 'none',
      color: '#fff',
      '& a': {
        textDecoration: 'none',
        color: '#fff',
      },
      '& img': {
        marginRight: 18,
        width: 36,
      },
      '& p': {
        fontSize: 14,
        width: 64,
      },
      '&:hover': {
        // backgroundColor: '#365485',
        backgroundColor: 'rgba(71, 112, 188, 0.5)',
      },
    },
    '& .appsBtns': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& a, div': {
        textDecoration: 'none',
        color: '#fff',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 4,
        fontSize: 12,
        borderRadius: 7,
        height: 75,
        width: '32%',
        minWidth: 90,
        marginBottom: 10,
        '&:hover': {
          // backgroundColor: '#365485',
          backgroundColor: theme.palette.primary.mainLight,
        },
        '& .svg': {
          width: 36,
          marginBottom: 10,
        },
      },
    },
    '& .companyAcc:hover': {
      backgroundColor: `${theme.palette.primary.mainLight} !important`,
    },
  },
  icon: {
    color: 'white !important',
  },
}))

const Apps = () => {
  const classes = useStyles()
  // const [openApps, setOpenApps] = useState(null)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { language } = useAppContext()

  // const handleClickApps = (event) => {
  //   if (openApps && openApps.contains(event.target)) {
  //     setOpenApps(null)
  //   } else {
  //     setOpenApps(event.currentTarget)
  //   }
  // }
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        // sx={{
        //   padding: 0,
        //   width: 44,
        //   height: 44,
        //   ...(open && {
        //     // bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
        //     bgcolor: 'transparent', //'rgba(0, 0, 0, 0.04)',
        //   }),
        // }}
      >
        <AppsIcon className={classes.icon} />
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            overflow: 'inherit',
            mt: 1.5,
            ml: 0.75,
            width: 230,
            height: 290,
            '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
            // padding: '4px 8px 4px 8px !important',
            backgroundColor: '#1e2a4f', //'#364473',
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <ArrowStyle className='arrow' />
        <Stack spacing={0.75}>
          <MenuList role='menu'>
            <div className={classes.menuContainer}>
              <a className='companyAcc' href={process.env.REACT_APP_COMPANY_ACCOUNT}>
                <img src={dashboard} alt='Company account' className='svg' />
                <p>{t.companyAccount[language]}</p>
              </a>
              <div className='appsBtns'>
                <a href={process.env.REACT_APP_CIOC}>
                  <img src={cioc} alt='IP Delivery App' className='svg' />
                  <p>IP Delivery</p>
                </a>
                <a href={process.env.REACT_APP_PLAYOUT}>
                  <img src={playout} alt='Playout App' className='svg' />
                  <p>PlayOut</p>
                </a>
                <a href={process.env.REACT_APP_MATRIX} className={classes.menuActive}>
                  <img src={matrix} alt='Virtual Matrix App' className='svg' />
                  <p>Virtual Matrix</p>
                </a>
                <a href={process.env.REACT_APP_STREAM}>
                  <img src={stream} alt='Stream Online App' className='svg' />
                  <p>Stream Online</p>
                </a>
              </div>
            </div>
          </MenuList>
        </Stack>
      </Popover>
    </>
  )
}

export default Apps
