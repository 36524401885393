import makeStyles from '@mui/styles/makeStyles'
import TableCell from '@mui/material/TableCell'

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    fontSize: 14,
    fontWeight: 500,
    height: 60,
    padding: '6px 16px',
    '&:first-child': {
      paddingLeft: 30,
    },
    '& a': {
      fontSize: 14,
      fontWeight: 500,
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        color: '#4770bc',
      },
    },
  },
}))

const MyTableCell = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <TableCell classes={{ root: classes.root }} {...props}>
      {children}
    </TableCell>
  )
}

export default MyTableCell
