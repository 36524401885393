import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

function ClearBtn(props) {
  return (
    <IconButton color='inherit' {...props} size='large'>
      <ClearIcon />
    </IconButton>
  )
}

export default ClearBtn
