import { useEffect, useState } from 'react'
import apiService from '../../services/api-service'
import makeStyles from '@mui/styles/makeStyles'
import Spinner from '../../components/Spinner/Spinner'
import { useAppContext } from '../../components/App/AppContext'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '200px auto 0',
    maxWidth: 600,
    padding: 25,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 16,
    // boxShadow: theme.shadows[1],
    '& ul': {
      listStyle: 'none',
      '& li': {
        cursor: 'pointer',
        fontSize: 18,
        padding: '12px 12px',
      },
      '& li:hover': {
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
      },
    },
  },
  heading: {
    fontSize: 32,
    // color: '#62c673',
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    textTransform: 'uppercase',
    paddingBottom: 10,
    marginBottom: 10,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
}))

function Auth() {
  const classes = useStyles()
  const { setStatus } = useAppContext()
  const [authData, setAuthData] = useState({})

  useEffect(() => {
    apiService
      .getMe()
      .then(({ data }) => {
        if (data.accounts.length === 1) {
          const username = data.name || data.username
          apiService.setCredential(username, data.accounts[0].name, data.accounts[0].id)
          setStatus('authorized')
        } else {
          setAuthData(data)
        }
      })
      .catch((e) => {
        console.log(e)
        setStatus('unauthenticated')
      })
    // eslint-disable-next-line
  }, [])

  const authHandler = (acc) => {
    const username = authData.name || authData.username
    apiService.setCredential(username, acc.name, acc.id)
    setStatus('authorized')
  }

  return authData.accounts ? (
    authData.accounts.length ? (
      <div className={classes.container}>
        <h1 className={classes.heading}>Enter as</h1>
        <ul>
          {authData.accounts?.map((el) => (
            <li key={el.id} onClick={authHandler.bind(this, el)}>
              {el.name}
            </li>
          ))}
        </ul>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>Sorry, you currently don't have permission to access this service.</h1>
      </div>
    )
  ) : (
    <Spinner />
  )
}

export default Auth
