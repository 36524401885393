import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import apiService from '../../services/api-service'
import Table from '../../components/Table/Table'
import TableBody from '../../components/Table/TableBody'
import TableHead from '../../components/Table/TableHead'
import TableCell from '../../components/Table/TableCell'
import Pagination from '../../components/Pagination/Pagination'
import useSorting from '../../services/sorting'
import AddBtn from '../../components/AddBtn/AddBtn'
import EditBtn from '../../components/ActionButtons/EditBtn/EditBtn'
import DeleteBtn from '../../components/ActionButtons/DeleteBtn/DeleteBtn'
import NoItemsMessage from '../../components/NoItemsMessage/NoItemsMessage'
import { useAppContext } from '../../components/App/AppContext'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import AddTransferRequest from './AddTransferRequest/AddTransferRequest'
import EditTransferRequest from './OutboundTransferRequest/EditTransferRequest'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
    buttonTdContainer: {
      display: 'flex',
    },
  },
  tableContainer: {
    height: 'calc(100% - 80px)',
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
  addBtn: {
    '& > button': {
      height: 32,
      width: 32,
      minWidth: 32,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      marginRight: 12,
    },
    justifyContent: 'flex-end',
    paddingRight: 30,
  },
  subCell: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(101, 101, 207, 0.3)',
    display: 'grid',
    placeItems: 'center',
  },
}))

const RequestsTable = ({
  data,
  status,
  page,
  refreshData,
  pageCount,
  handleChangePage,
  account,
  ...props
}) => {
  const classes = useStyles()

  const {
    language,
    openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')

  const handleDeleteRequest = (requestId) => {
    return apiService
      .delete(`/virtual-matrix/transfer-requests/${requestId}`)
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  const headCells = [
    [
      {
        id: 'title',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.title[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' }, rowSpan: 2 },
      },
      {
        id: 'created',
        numeric: false,
        label: `${t.created[language]}`,
        props: { rowSpan: 2 },
      },
      {
        id: 'recipients',
        numeric: false,
        label: `${t.recipients[language]}`,
        props: { rowSpan: 2 },
      },
      {
        id: 'events',
        numeric: false,
        label: `${t.events[language]}`,
        props: { rowSpan: 2 },
      },
      {
        id: 'transfers',
        numeric: false,
        label: `${t.transfers[language]}`,
        props: { align: 'center', colSpan: 4, style: { padding: 0, height: 30 } },
      },
      {
        id: 'add',
        numeric: false,
        label: (
          <AddBtn
            addBtnDis={props.addBtnDis}
            onClick={openFullScreenModal.bind(this, `addTransferRequest${account}`, account)}
          />
        ),
        props: {
          align: 'right',
          width: 140,
          rowSpan: 2,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
    [
      {
        id: 'accepted',
        numeric: false,
        label: (
          <span className={classes.subCell} style={{ borderRadius: '6px 0 0 6px' }}>
            {t.accepted[language]}
          </span>
        ),
        props: {
          align: 'center',
          style: { padding: 0, height: 30, top: 30, fontSize: 12, paddingBottom: 6 },
        },
      },
      {
        id: 'rejected',
        numeric: false,
        label: <span className={classes.subCell}>{t.rejected[language]}</span>,
        props: {
          align: 'center',
          style: { padding: 0, height: 30, top: 30, fontSize: 12, paddingBottom: 6 },
        },
      },
      {
        id: 'canceled',
        numeric: false,
        label: <span className={classes.subCell}>{t.canceled[language]}</span>,
        props: {
          align: 'center',
          style: { padding: 0, height: 30, top: 30, fontSize: 12, paddingBottom: 6 },
        },
      },
      {
        id: 'approved',
        numeric: false,
        label: (
          <span className={classes.subCell} style={{ borderRadius: '0 6px 6px 0' }}>
            {t.approved[language]}
          </span>
        ),
        props: {
          align: 'center',
          style: { padding: 0, height: 30, top: 30, fontSize: 12, paddingBottom: 6 },
        },
      },
    ],
  ]

  return (
    <>
      <AddTransferRequest accountId={account} />
      <EditTransferRequest accountId={account} />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheRequest[language]}
        onOk={handleDeleteRequest.bind(this, buffer?.id)}
        id={`deleteTransferRequest${account}`}
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.root} stickyHeader>
          <TableHead
            headCells={headCells}
            createSortHandler={createSortHandler}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    <Link to={`/outbound-transfer-requests/${account}/${row.id}`}>{row.title}</Link>
                  </TableCell>
                  <TableCell>{format(new Date(row.created_at), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>{row.recipients}</TableCell>
                  <TableCell>{row.events || ''}</TableCell>
                  <TableCell align='center'>{row.transfers.accepted}</TableCell>
                  <TableCell align='center'>{row.transfers.rejected}</TableCell>
                  <TableCell align='center'>{row.transfers.canceled}</TableCell>
                  <TableCell align='center'>{row.transfers.approved}</TableCell>
                  <TableCell>
                    <div className={classes.buttonTdContainer}>
                      <EditBtn
                        onClick={() => openFullScreenModal(`editTransferRequest${account}`, row)}
                      />
                      <DeleteBtn
                        onClick={() => openFullScreenModal(`deleteTransferRequest${account}`, row)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <NoItemsMessage data={data} message={t.noRequestsYet[language]} status={status} />
      </TableContainer>
      {!props.withoutPagination && (
        <Pagination count={pageCount} page={page} onChange={handleChangePage} />
      )}
    </>
  )
}

export default RequestsTable
