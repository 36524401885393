import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { object } from 'yup'

import { useAppContext } from '../../components/App/AppContext'
import Autocomplete from '../../forms/Autocomplete'
import AutocompleteList from '../../forms/AutocompleteList'
import AutocompleteSingle from '../../forms/AutocompleteSingle'
import SubmitButton from '../../forms/SubmitButton'
import TextField from '../../forms/TextField'
import apiService from '../../services/api-service'
import { defaultSortOptions, getInitialValues } from '../../services/helpers'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: '100%',
    marginTop: -32,
  },
  buttonContainer: {
    textAlign: 'left',
    marginTop: 20,
  },
  counter: {
    fontSize: '1.3rem',
  },
  secondRow: {
    marginTop: -24,
  },
  grid: {
    '&.MuiGrid-item': {
      marginTop: '2px',
      paddingTop: '0px',
    },
  },
}))

const statusesOptions = [
  { id: 'online', value: 'online', label: 'Online' },
  { id: 'offline', value: 'offline', label: 'Offline' },
  { id: 'unknown', value: 'unknown', label: 'Unknown' },
]
const outputOptions = [
  { id: 'IP', value: 'IP', label: 'IP' },
  { id: 'SDI', value: 'SDI', label: 'SDI' },
  { id: 'NDI', value: 'NDI', label: 'NDI' },
]
const typesOptions = [
  { id: 'dune', value: 'dune', label: 'Dune' },
  { id: 'general', value: 'general', label: 'General' },
  { id: 'magewell', value: 'magewell', label: 'Magewell' },
  { id: 'magewell-ultra', value: 'magewell-ultra', label: 'Magewell-ultra' },
  { id: 'ffmpeg', value: 'ffmpeg', label: 'Ffmpeg' },
]
const qualitiesOptions = [
  { id: 'HD', value: 'HD', label: 'HD' },
  { id: 'SD', value: 'SD', label: 'SD' },
]
const isLockedOptions = {
  en: [
    { id: 'true', value: 'true', label: t.locked.en },
    { id: 'false', value: 'false', label: t.unLocked.en },
  ],
  uk: [
    { id: 'true', value: 'true', label: t.locked.uk },
    { id: 'false', value: 'false', label: t.unLocked.uk },
  ],
}

const OutputsFilters = () => {
  const classes = useStyles()
  const { language } = useAppContext()
  const location = useLocation()
  const history = useHistory()
  const [locationsOptions, setLocationsOptions] = useState([])

  const defaultValues = {
    name: '',
    statuses: [],
    types: [],
    output_types: [],
    qualities: [],
    address: '',
    locations: [],
    is_locked: '',
    sort: [],
  }

  const initialValues = useMemo(
    () => getInitialValues(location, defaultValues),
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    Promise.all([apiService.get(`/virtual-matrix/outputs`)]).then(([res2]) => {
      // got locations options
      const locationsOnly = res2.data
        .map((loc) => ({
          id: loc.location.id,
          value: loc.location.id,
          label: loc.location.name,
        }))
        .filter((loc) => loc.id !== '')
      const key = 'id'
      const locationsUniqueByKey = [
        ...new Map(locationsOnly.map((item) => [item[key], item])).values(),
      ]
      setLocationsOptions(locationsUniqueByKey)
    })
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const searchParams = new URLSearchParams(location.search)
        values.name.length
          ? searchParams.set('filter[name]', values.name)
          : searchParams.delete('filter[name]')
        values.statuses.length
          ? searchParams.set('filter[statuses]', values.statuses.join(','))
          : searchParams.delete('filter[statuses]')
        values.types.length
          ? searchParams.set('filter[types]', values.types.join(','))
          : searchParams.delete('filter[types]')
        values.output_types.length
          ? searchParams.set('filter[output_types]', values.output_types.join(','))
          : searchParams.delete('filter[output_types]')
        values.qualities.length
          ? searchParams.set('filter[qualities]', values.qualities.join(','))
          : searchParams.delete('filter[qualities]')
        values.address.length
          ? searchParams.set('filter[address]', values.address)
          : searchParams.delete('filter[address]')
        values.locations.length
          ? searchParams.set('filter[locations]', values.locations)
          : searchParams.delete('filter[locations]')
        values.is_locked
          ? searchParams.set('filter[is_locked]', values.is_locked)
          : searchParams.delete('filter[is_locked]')
        values.sort.length
          ? searchParams.set('sort', values.sort.join(','))
          : searchParams.delete('sort')

        searchParams.set('page[offset]', '0')
        const search = searchParams.toString()
        history.push({
          search,
        })

        return Promise.resolve().then(() => {}, console.log)
      }}
      validationSchema={object({
        // title: string().required(),
        // owner: string().required(),
      })}
    >
      {({ isSubmitting, setValues }) => (
        <Form noValidate className={classes.formContainer}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.grid}>
              <TextField label={t.name[language]} name='name' />
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <Autocomplete
                name='output_types'
                options={outputOptions.sort(defaultSortOptions)}
                label={t.type[language]}
                language={language}
              />
              <AutocompleteList
                name='output_types'
                listOfValues={outputOptions}
                multiple
                withoutIcon
                tags
              />
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <Autocomplete
                name='types'
                options={typesOptions.sort(defaultSortOptions)}
                label={t.device[language]}
                language={language}
              />
              <AutocompleteList
                name='types'
                listOfValues={typesOptions}
                multiple
                withoutIcon
                tags
              />
            </Grid>
            <Grid item xs={3} className={clsx(classes.secondRow, classes.grid)}>
              <Autocomplete
                name='locations'
                options={locationsOptions.sort(defaultSortOptions)}
                label={t.location[language]}
                language={language}
              />
              <AutocompleteList
                name='locations'
                listOfValues={locationsOptions}
                multiple
                withoutIcon
                tags
              />
            </Grid>
            <Grid item xs={3} className={clsx(classes.secondRow, classes.grid)}>
              <TextField label={t.address[language]} name='address' />
            </Grid>
            <Grid item xs={3} className={clsx(classes.secondRow, classes.grid)}>
              <Autocomplete
                name='qualities'
                label={t.quality[language]}
                options={qualitiesOptions.sort(defaultSortOptions)}
                language={language}
              />
              <AutocompleteList
                name='qualities'
                listOfValues={qualitiesOptions}
                multiple
                withoutIcon
                tags
              />
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <Autocomplete
                name='statuses'
                label={t.status[language]}
                options={statusesOptions.sort(defaultSortOptions)}
                language={language}
              />
              <AutocompleteList
                name='statuses'
                listOfValues={statusesOptions}
                multiple
                withoutIcon
                tags
              />
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <AutocompleteSingle
                name='is_locked'
                label={t.locked[language]}
                options={isLockedOptions[language].sort(defaultSortOptions)}
                language={language}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <SubmitButton disabled={isSubmitting} color='primary'>
              {t.apply[language]}
            </SubmitButton>
            <SubmitButton
              disabled={isSubmitting}
              onClick={() => {
                setValues(defaultValues)
                history.push(location.pathname)
              }}
              type='button'
              color='primary'
              style={{ marginLeft: 20 }}
            >
              {t.reset[language]}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OutputsFilters
