import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'

import apiService from '../../../services/api-service'
import { useAppContext } from '../../../components/App/AppContext'
import { translations as t } from '../../../services/translations'
import Pagination from '../../../components/Pagination/Pagination'
import usePagination from '../../../services/usePagination'
import DragAndDrop from '../../../components/DragAndDropTable/DragAndDrop'
import NoItemsMessage from '../../../components/NoItemsMessage/NoItemsMessage'
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog'
import Header from './Header'
import Row from './Row'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: (props) => (props.withoutPagination ? '100%' : 'calc(100% - 80px)'),
    paddingRight: 36,
    overflowY: 'scroll',
    width: '100%',
    marginTop: -60,
    paddingTop: 60,
  },
  dragndrop: {
    '& > div:nth-child(odd)': {
      backgroundColor: theme.palette.background.dimmer,
    },
    '& > div:nth-child(even)': {
      backgroundColor: theme.palette.background.bleacher,
    },
  },
}))

const OutputsTable = ({ contextAccountId, ...props }) => {
  const classes = useStyles(props)
  const location = useLocation()
  const {
    language,
    // openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()

  const getOutputs = (accountId, offset, limit) =>
    apiService.get(
      `/virtual-matrix/outputs${
        location.search ? location.search + '&' : '?'
      }page[limit]=${limit}&page[offset]=${offset}`,
      accountId,
    )

  const { status, data, setData, page, pageCount, refreshData, handleChangePage } = usePagination(
    getOutputs,
    contextAccountId,
  )

  useEffect(() => {
    apiService.get(`/virtual-matrix/outputs${location.search}`, contextAccountId).then((res) => {
      setData(res.data)
    })
  }, [location, contextAccountId, setData])

  function onDragEnd(result) {
    const { destination, source } = result

    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const movedChannel = data[source.index]
    const reorderChannels = Array.from(data)
    reorderChannels.splice(source.index, 1)
    reorderChannels.splice(destination.index, 0, movedChannel)

    setData(reorderChannels)
    const promises = reorderChannels
      .map((el, index) => {
        if (el.sort !== index) {
          return apiService
            .patch(
              `/virtual-matrix/outputs/${el.id}`,
              {
                sort: index,
              },
              contextAccountId,
            )
            .catch((e) => {
              console.log(e)
              // enqueueSnackbar(e.response?.data.error || e.message, {
              //   variant: 'error',
              // })
            })
        } else {
          return null
        }
      })
      .filter((el) => el)
    Promise.all(promises).then(console.log).catch(console.log).finally(refreshData)
  }

  const handleSwitchQuality = (item) => {
    return apiService
      .patch(
        `/virtual-matrix/outputs/${item.id}`,
        { quality: item.quality === 'HD' ? 'SD' : 'HD' },
        contextAccountId,
      )
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  const handleLock = (item) => {
    return apiService
      .patch(`/virtual-matrix/outputs/${item.id}`, { is_locked: !item.is_locked }, contextAccountId)
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  const handleReboot = (item) => {
    return apiService
      .post(`/virtual-matrix/outputs/${item.id}/reboot`, null, contextAccountId)
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  return (
    <>
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToSwitchTheQuality[language]}
        onOk={handleSwitchQuality.bind(this, buffer)}
        id={`switchQuality${contextAccountId}`}
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={
          buffer?.is_locked
            ? t.areYouSureYouWantToUnlockIt[language]
            : t.areYouSureYouWantToLockIt[language]
        }
        onOk={handleLock.bind(this, buffer)}
        id={`lock${contextAccountId}`}
      />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRebootIt[language]}
        onOk={handleReboot.bind(this, buffer)}
        id={`reboot${contextAccountId}`}
      />
      <Header />
      <div className={classes.root}>
        <DragAndDrop onDragEnd={onDragEnd} className={classes.dragndrop}>
          {data.map((el, index) => (
            <Row item={el} index={index} key={el.id} contextAccountId={contextAccountId} />
          ))}
        </DragAndDrop>
        <NoItemsMessage data={data} message={t.noOutputsYet[language]} status={status} />
      </div>
      {!props.withoutPagination && (
        <Pagination count={pageCount} page={page} onChange={handleChangePage} />
      )}
    </>
  )
}

export default OutputsTable
