import { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
// Icons
import MoreVert from '@mui/icons-material/MoreVert'
import MenuIcon from '@mui/icons-material/Menu'
// Logo
import matrix from '../../assets/img/matrix_logo.svg'

import { useAppContext } from '../App/AppContext'
import SideMenu from './SideMenu'
import Notifications from './Notifications'
import LanguageSwitcher from './LanguageSwitcher'
import Person from './Person'
import Apps from './Apps'
// import SwitchMode from './SwitchMode'

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // backgroundImage: theme.palette.layout.main,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // minHeight: '100vh',
    height: '100vh',
    overflowY: 'scroll',
    overflowX: 'auto',
    // width
    '&::-webkit-scrollbar': {
      width: '0.50em',
      height: '0.75em',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // width
    '& *::-webkit-scrollbar': {
      width: '0.40em',
      height: '0.75em',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // Track
    '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
      // box-shadow: inset 0 0 5px grey
      borderRadius: '5px',
      // backgroundColor: '#34426C',
      backgroundColor: 'inherit',
    },
    // Handle
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      // backgroundColor: '#6565cf',
      borderRadius: '5px',
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
    },
    // Button
    // '&::-webkit-scrollbar-button, & *::-webkit-scrollbar-button': {
    //   // backgroundColor: '#34426C',
    //   backgroundColor: 'inherit',
    // },
    // Handle on hover
    // '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    //   backgroundColor: theme.palette.primary.main,
    // },
    // the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#34426C',
      backgroundColor: 'inherit',
    },
  },
  appBar: {
    width: `calc(100% - 73px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.divider}`,
    '& .MuiToolbar-root': {
      minHeight: 72,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 12,
    marginLeft: 27,
    '&.MuiIconButton-root': {
      padding: 5,
      borderRadius: '5px',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // backgroundColor: 'yellow',
  },
  drawerPaper: {
    backgroundColor: '#32406C',
    // backgroundImage: theme.palette.layout.sideMenu,
    '&.MuiDrawer-paperAnchorDockedLeft': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
    justifyContent: 'space-between',
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'block',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '68px',
  },
  toolbar: {
    // height: 74,
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
    fontSize: 15,
    fontWeight: 500,
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    '& .MuiIconButton-root': {
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(7),
    paddingRight: 44,
    position: 'relative',
  },
  username: {
    paddingLeft: 8,
    height: 27,
    // width: '105px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  switchContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 8,
  },
  usernameBorderLeft: {
    borderLeft: '1px solid #fff',
  },
}))

const Layout = (props) => {
  const classes = useStyles()
  const { currentPage /*role*/ } = useAppContext()
  const [open, setOpen] = useState(true)
  const handleDrawerToggle = () => {
    setOpen((open) => !open)
  }

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size='large'
          >
            {open ? <MoreVert /> : <MenuIcon />}
          </IconButton>
          <Typography variant='h5' noWrap>
            {currentPage}
          </Typography>
          <div className={classes.switchContainer}>
            {/*{role.isRightsHolder && role.isProvider && <SwitchMode />}*/}
            <div
              className={clsx(
                classes.username,
                // role.isRightsHolder && role.isProvider && classes.usernameBorderLeft,
              )}
            >
              <Notifications />
              <LanguageSwitcher />
              <Apps />
              <Person />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        transitionDuration={660}
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
            classes.drawerPaper,
          ),
        }}
        open={open}
      >
        {/* Logo */}
        <div
          className={classes.toolbar}
          style={{ height: 74, padding: 0, cursor: 'pointer' }}
          onClick={handleDrawerToggle}
        >
          <img src={matrix} alt='Logo' style={{ width: 32 }} />
          {open && <span style={{ marginLeft: 16, fontSize: 18 }}>Virtual Matrix</span>}
        </div>

        {/* Side Menu */}
        <SideMenu />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}

export default Layout
