import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    border: '1px solid #fff',
    borderRadius: 4,
    display: 'flex',
    width: 32,
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    '&:hover': {
      width: 60,
      '& .active': {
        backgroundColor: '#4770bc',
      },
    },
    justifyContent: (props) => (props.quality === 'SD' ? 'flex-start' : 'flex-end'),
    '& > span': {
      display: 'block',
      padding: '0 5px 0 5px',
      fontSize: 14,
      lineHeight: '20px',
    },
  },
}))

const Quality = ({ onChange, ...props }) => {
  const classes = useStyles(props)

  return (
    <div className={classes.root} onClick={onChange}>
      <span className={clsx(props.quality === 'SD' && 'active')}>SD</span>
      <span className={clsx(props.quality === 'HD' && 'active')}>HD</span>
    </div>
  )
}

export default Quality
