import { useContext, useState, createContext, useEffect } from 'react'
import apiService from '../../services/api-service'

const AppCtx = createContext(null)

export default function AppContext({ children }) {
  // STATES
  const [language, setLanguage] = useState('uk')
  const [currentPage, setCurrentPage] = useState('')
  const [status, setStatus] = useState('loading')
  const [isFullScreenLoader, setIsFullScreenLoader] = useState(false)
  const [fullScreenModal, setFullScreenModal] = useState({ open: false, id: '', buffer: null })
  // METHODS
  const closeFullScreenModal = () => setFullScreenModal({ open: false, id: '', buffer: null })
  const openFullScreenModal = (id, buffer) => setFullScreenModal({ open: true, id, buffer })
  const openFullScreenLoader = () => setIsFullScreenLoader(true)
  const closeFullScreenLoader = () => setIsFullScreenLoader(false)

  useEffect(() => {
    if (apiService.getTokenData()) {
      if (apiService.getCredential()) {
        apiService
          .getMe()
          .then((res) => {
            const { cin } = apiService.getCredential()
            const currentAccount = res.data.accounts.find((el) => el.id === cin)
            if (currentAccount) {
              apiService.setCredential(res.data.name || res.data.username, currentAccount.name, cin)
              setStatus('authorized')
            } else {
              setStatus('unauthorized')
            }
          })
          .catch((e) => {
            console.log(e)
            setStatus('unauthenticated')
          })
      } else {
        setStatus('unauthorized')
      }
    } else {
      setStatus('unauthenticated')
    }

    if (localStorage.getItem('language') == null) {
      localStorage.setItem('language', 'uk')
      setLanguage('uk')
    } else {
      setLanguage(localStorage.getItem('language'))
    }
  }, [])

  return (
    <AppCtx.Provider
      value={{
        currentPage,
        setCurrentPage,
        status,
        setStatus,
        isFullScreenLoader,
        openFullScreenLoader,
        closeFullScreenLoader,
        fullScreenModal,
        openFullScreenModal,
        closeFullScreenModal,
        language,
        setLanguage,
      }}
    >
      {children}
    </AppCtx.Provider>
  )
}

export const useAppContext = () => useContext(AppCtx)
