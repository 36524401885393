import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { object } from 'yup'
// import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useAppContext } from '../App/AppContext'
import FullScreenModal from '../FullScreenModal/FullScreenModal'
import NoItemsMessage from '../NoItemsMessage/NoItemsMessage'
import TableCell from '../Table/TableCell'

import SimpleCheckBox from '../../forms/SimpleCheckBox'
import SubmitButton from '../../forms/SubmitButton'
import apiService from '../../services/api-service'
import useSorting from '../../services/sorting'
import { translations as t } from '../../services/translations'
import Connections from '../Connection/Connection'
import Table from '../Table/Table'
import TableBody from '../Table/TableBody'
import TableHead from '../Table/TableHead'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 1320,
    height: 'calc(100vh - 90px)',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: '30px 0 0',
  },
  addChannel: {
    width: 1100,
    margin: '0 auto',
    padding: '48px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 20,
  },

  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#344168',
    },
    '& .doubleLine > p': {
      lineHeight: '18px',
      fontWeight: 500,
      whiteSpace: 'nowrap,',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // paddingRight: 12,
  },
  tableContainer: {
    height: 100,
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    '&::-webkit-scrollbar': {
      width: '7px !important',
      height: '0.75em',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // width
    '& *::-webkit-scrollbar': {
      width: '7px !important',
      height: '0.75em',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // Track
    '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
      // box-shadow: inset 0 0 5px grey
      borderRadius: '5px',
      // backgroundColor: '#34426C',
      backgroundColor: 'inherit',
    },
    // Handle
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      // backgroundColor: '#6565cf',
      borderRadius: '5px',
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
    },
  },
  borderRadiusCell: {
    backgroundColor: '#344168',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
  subCell: {
    width: '100%',
    height: '100%',
    //  backgroundColor: 'rgba(101, 101, 207, 0.3)',
    display: 'grid',
    placeItems: 'center',
  },
  accordion: {
    marginBottom: 18,
    paddingRight: 42,
  },
}))

const CloseConnections = ({ onOk }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    language,
    openFullScreenLoader,
    closeFullScreenLoader,
    closeFullScreenModal,
    fullScreenModal: { open, id },
  } = useAppContext()
  const { order, orderBy, createSortHandler } = useSorting('')

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [selected, setSelected] = useState([])
  const [titleTotal, setTitleTotal] = useState('')

  const handleSelectOne = (output) => {
    const found = selected.find((el) => el.id === output.id)
    let newSelected = null
    if (found) {
      newSelected = selected.filter((el) => el.id !== output.id)
    } else {
      newSelected = [...selected, output]
    }
    setSelected(newSelected)
  }

  const handleAllSelected = () => {
    if (selected.length === data.length) {
      setSelected([])
    } else {
      setSelected(data)
    }
  }

  const getDashboard = () => {
    apiService.get('/virtual-matrix/dashboard').then((res) => {
      const data = res.data

      const external_inputs = data.map((el) => el.data.external_inputs).flat()
      // const external_outputs = data.map((el) => el.data.external_outputs).flat()
      const inputs = data
        .map((el) => el.data.inputs)
        .flat()
        .filter((el) => el.connections.length)
      // const outputs = data.map((el) => el.data.outputs).flat()
      // const presets = data.map((el) => el.data.presets).flat()
      const typesOfConnections = {
        external_input: external_inputs,
        // external_output: external_outputs,
        input: inputs,
        // output: outputs,
        // preset: presets,
      }

      const filteredConnections = data
        .map((el) => {
          const account = el.account
          return el.data.outputs.map((outputs) => ({
            account: account,
            ...outputs,
          }))
        })
        .flat()
        .filter((el) => el.connection && !el.is_locked)
        .map((el) => {
          const typeOfConnection = el.connection.type

          if (typeOfConnection === 'input' || typeOfConnection === 'external_input') {
            //external_input - не был создан, этот тип не тестил
            const inputConnection = typesOfConnections[typeOfConnection].find((el2) =>
              el2.connections.find((el3) => el3.id === el.connection.id),
            )
            return {
              ...el,
              connection: {
                ...el.connection,
                [typeOfConnection]: inputConnection,
              },
            }
          } else {
            return {
              ...el,
              connection: {
                ...el.connection,
              },
            }
          }
        })

      setData(filteredConnections)
      setTotalItems(filteredConnections.length)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (open && id === `closeConnections`) {
      getDashboard()
    }

    return () => {
      setData([])
      setSelected([])
      setIsLoading(true)
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (!isLoading) {
      const total = selected.length ? (
        <>
          <strong>{totalItems}</strong> (<strong>{selected.length}</strong>{' '}
          <span style={{ textTransform: 'lowercase' }}>{t.selected_o[language]}</span>)
        </>
      ) : (
        <strong>{totalItems}</strong>
      )
      setTitleTotal(total)
    }

    return () => setTitleTotal('')
  }, [totalItems, selected, language, isLoading])

  const headCells = [
    [
      {
        id: 'allCheckBox',
        numeric: false,
        label: (
          <div className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            <SimpleCheckBox
              checked={selected.length === data.length}
              onChange={handleAllSelected}
            />
          </div>
        ),
        props: { style: { padding: 0, backgroundColor: '#2E3B63' }, width: 30 },
      },
      {
        id: 'source',
        numeric: false,
        label: `${t.source[language]}`,
      },
      { id: 'output', numeric: false, label: `${t.output[language]}` },
      { id: 'validFrom', numeric: false, label: `${t.validFrom[language]}` },
      { id: 'validTill', numeric: false, label: `${t.validTill[language]}` },
      {
        id: 'add',
        numeric: false,
        label: <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellRight)} />,
        props: {
          align: 'right',
          width: 120,
          style: { padding: 0, backgroundColor: '#2E3B63' },
        },
      },
    ],
  ]

  return (
    <FullScreenModal id='closeConnections' title={t.closeConnections[language]}>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          openFullScreenLoader()

          return Promise.all(
            selected.map((con) => {
              return apiService
                .delete(`/virtual-matrix/connections/${con.connection.id}`, con.account.id)
                .then((res) => res)
                .catch((e) => {
                  console.log(e)
                  enqueueSnackbar(e.response?.data.error || e.message, {
                    variant: 'error',
                  })
                })
            }),
          )
            .then((res) => {
              if (res.every((el) => el.status >= 200)) {
                if (onOk) {
                  onOk()
                }
                closeFullScreenModal()
                closeFullScreenLoader()
              }

              return res.filter((el) => !el.status)
            })
            .catch((e) => {
              closeFullScreenLoader()
              getDashboard()
              setSelected([])
              console.error(e)
            })
        }}
        validationSchema={object({})}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form noValidate className={classes.addChannel}>
            {/* <Grid container spacing={5} style={{ height: '600px' }}> */}
            <Typography
              style={{
                display: 'inline-flex',
                color: 'rgba(255,255,255, 0.6)',
                fontSize: '1.5rem',
                marginLeft: 21,
                marginBottom: 14,
              }}
            >
              {`${t.total[language]}:`} <span style={{ marginLeft: 12 }}>{titleTotal}</span>
            </Typography>
            <TableContainer className={classes.tableContainer} style={{ height: '600px' }}>
              <Table className={classes.root} stickyHeader>
                <TableHead
                  headCells={headCells}
                  createSortHandler={createSortHandler}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {data.map((row, i) => {
                    const isSelected = !!selected.find((el) => el.id === row.id)
                    return (
                      <TableRow key={row.id}>
                        <TableCell component='th' scope='row' width={30}>
                          <SimpleCheckBox
                            checked={isSelected}
                            onChange={handleSelectOne.bind(this, row)}
                          />
                        </TableCell>
                        <TableCell>
                          <Connections
                            name={row.connection.url || row.connection[row.connection.type]?.name}
                            type={row.connection.type}
                            // link=''
                          />
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {format(new Date(row.connection.start_time), 'dd/MM/yyyy HH:mm')}
                        </TableCell>
                        <TableCell>
                          {row.connection.end_time === null
                            ? t.permanent[language]
                            : format(new Date(row.connection.end_time), 'dd/MM/yyyy HH:mm')}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <NoItemsMessage
                data={data}
                message={t.noDataFound[language]}
                status={isLoading ? 'loading' : 'success'}
              />
            </TableContainer>
            {/* </Grid> */}
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={!selected.length || isSubmitting}>
                {t.confirm[language]}
              </SubmitButton>
            </div>
            {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default CloseConnections
