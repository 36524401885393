import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { array, mixed, object, string } from 'yup'

import { useSnackbar } from 'notistack'
import AutocompleteCheckbox from '../../forms/AutocompleteCheckbox'
import AutocompleteSingleCheckbox from '../../forms/AutocompleteSingleCheckbox'
import DateTimePicker from '../../forms/DateTimePicker'
import Select from '../../forms/Select'
import SelectList from '../../forms/SelectList'
import SubmitButton from '../../forms/SubmitButton'
import Switcher from '../../forms/Switcher'
import TextField from '../../forms/TextField'
import apiService from '../../services/api-service'
import { sourceTypes, defaultSortOptions } from '../../services/helpers'
import { translations as t } from '../../services/translations'
import { useAppContext } from '../App/AppContext'
import FullScreenModal from '../FullScreenModal/FullScreenModal'

const useStyles = makeStyles(() => ({
  addChannel: {
    width: 1100,
    margin: '0 auto',
    padding: '48px 0 24px',
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: 20,
  },
}))

const getItems = (response, field) =>
  response.data.reduce((acc, el) => {
    const t = el.data ? el.data[field].map((f) => ({ ...f, owner: el.account })) : []
    acc = [...acc, ...t]
    return acc
  }, [])

const AddConnection = ({ accountId, onOk }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    language,
    openFullScreenLoader,
    closeFullScreenLoader,
    closeFullScreenModal,
    fullScreenModal: { open, id, buffer },
  } = useAppContext()
  const [inputs, setInputs] = useState([])
  const [outputsList, setOutputsList] = useState([])
  const [externalInputsList, setExternalInputsList] = useState([])
  const [presets, setPresets] = useState([])

  const initialValues = {
    sourceType:
      buffer?.group === 'inputs' || buffer?.group === 'outputs'
        ? 'input'
        : buffer?.group === 'externalInputs'
        ? 'externalInput'
        : 'input',
    source: buffer?.group === 'inputs' || buffer?.group === 'externalInputs' ? buffer.id : '',
    isPermanent: true,
    validTill: null,
    outputs: buffer?.group === 'outputs' ? [buffer.id] : [],
  }

  useEffect(() => {
    if (open && id === `addConnection${accountId || ''}`) {
      apiService
        .get('/virtual-matrix/dashboard')
        .then((res) => {
          const i = getItems(res, 'inputs')
          const p = getItems(res, 'presets').sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
          const o = getItems(res, 'outputs').filter((el) => !el.is_locked && el.status === 'online')
          const ei = getItems(res, 'external_inputs')
          setInputs(i)
          setOutputsList(o)
          setPresets(p)
          setExternalInputsList(ei)
        })
        .catch(console.log)
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <FullScreenModal id={`addConnection${accountId || ''}`} title={t.addConnection[language]}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, helpers) => {
          openFullScreenLoader()

          let promises
          const end_time = values.isPermanent ? null : values.validTill
          if (values.sourceType === 'input') {
            const data = {
              input: values.source,
              end_time,
            }
            promises = values.outputs.map((el) =>
              apiService.post(
                `/virtual-matrix/connections/input`,
                {
                  ...data,
                  output: el,
                },
                outputsList.find((e) => e.id === el).owner.id,
              ),
            )
          } else if (values.sourceType === 'externalInput') {
            const data = {
              transfer: values.source,
              end_time,
            }
            promises = values.outputs.map((el) =>
              apiService.post(
                `/virtual-matrix/connections/transfer`,
                {
                  ...data,
                  output: el,
                },
                outputsList.find((e) => e.id === el).owner.id,
              ),
            )
          } else if (values.sourceType === 'preset') {
            const data = {
              preset: values.source,
              end_time,
            }
            promises = values.outputs.map((el) =>
              apiService.post(
                `/virtual-matrix/connections/preset`,
                {
                  ...data,
                  output: el,
                },
                outputsList.find((e) => e.id === el).owner.id,
              ),
            )
          } else if (values.sourceType === 'link') {
            const data = {
              url: values.source,
              end_time,
            }
            promises = values.outputs.map((el) =>
              apiService.post(
                `/virtual-matrix/connections/custom`,
                {
                  ...data,
                  output: el,
                },
                outputsList.find((e) => e.id === el).owner.id,
              ),
            )
          }
          return Promise.all(promises)
            .then((res) => {
              onOk()
              closeFullScreenModal()
              closeFullScreenLoader()
            })
            .catch((err) => {
              closeFullScreenLoader()

              console.log(err)
              enqueueSnackbar(err.response?.data.error || err.message, {
                variant: 'error',
              })
            })
        }}
        validationSchema={object({
          sourceType: string().required(t.fieldIsRequired[language]),
          source: string().required(t.fieldIsRequired[language]),
          outputs: array()
            .min(1, t.fieldIsRequired[language])
            .required(t.fieldIsRequired[language]),
          validTill: mixed().when('isPermanent', {
            is: false,
            then: string().nullable().required(t.fieldIsRequired[language]),
          }),
        })}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form noValidate className={classes.addChannel}>
            <Grid container spacing={5} style={{ height: '600px' }}>
              {/* первая колонка*/}
              <Grid item xs={4}>
                <Select
                  label={t.sourceType[language]}
                  name='sourceType'
                  onChange={() => {
                    setFieldValue('source', '')
                    // setFieldValue('outputs', [])
                  }}
                  required
                  fullWidth
                >
                  {sourceTypes[language]}
                </Select>
                {values.sourceType ? (
                  values.sourceType === 'link' ? (
                    <TextField label={t.source[language]} name='source' required />
                  ) : values.sourceType === 'preset' ? (
                    <AutocompleteSingleCheckbox
                      name='source'
                      label={t.preset[language]}
                      options={presets
                        .map((el) => ({
                          ...el,
                          label: el.name,
                        }))
                        .sort(defaultSortOptions)}
                      language={language}
                      required
                    />
                  ) : (
                    <AutocompleteSingleCheckbox
                      name='source'
                      label={t.source[language]}
                      options={
                        values.sourceType === 'input'
                          ? inputs
                              .map((el) => ({
                                ...el,
                                label: el.name,
                              }))
                              .sort(defaultSortOptions)
                          : externalInputsList
                              .map((el) => ({
                                ...el,
                                label: el.transfer_request_event?.name,
                              }))
                              .sort(defaultSortOptions)
                      }
                      language={language}
                      required
                    />
                  )
                ) : null}
                <Switcher
                  label={t.connectionType[language]}
                  upperTitle={t.permanent[language]}
                  lowerTitle={t.limitedTime[language]}
                  name='isPermanent'
                />
                {!values.isPermanent && (
                  <DateTimePicker
                    name='validTill'
                    label={t.validTill[language]}
                    clean
                    required={!values.isPermanent}
                  />
                )}
              </Grid>
              {/*END - первая колонка*/}

              {/* Средняя колонка*/}
              <Grid item xs={4}>
                <AutocompleteCheckbox
                  name='outputs'
                  label={t.outputs[language]}
                  options={outputsList
                    .map((el) => ({ ...el, label: el.name }))
                    .sort((a, b) =>
                      // sort by item.owner.name and then sort by item.sort
                      a.owner.name.toLowerCase() > b.owner.name.toLowerCase()
                        ? 1
                        : a.owner.name.toLowerCase() < b.owner.name.toLowerCase()
                        ? -1
                        : a.sort > b.sort
                        ? 1
                        : -1,
                    )}
                  language={language}
                  required
                />
                <SelectList name='outputs' listOfValues={outputsList} />
              </Grid>
              {/* END - Средняя колонка*/}

              {/* Третья колонка*/}
              <Grid item xs={4} />
              {/* END - Третья колонка*/}
            </Grid>
            <div className={classes.buttonContainer}>
              <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
            </div>
            {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
          </Form>
        )}
      </Formik>
    </FullScreenModal>
  )
}

export default AddConnection
