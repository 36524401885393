import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import makeStyles from '@mui/styles/makeStyles'

import { useAppContext } from '../../components/App/AppContext'
import Accordion from '../../components/Accordion/Accordion'
import Tabs from '../../components/Tabs/Tabs'
import ConnectionsTab from './ConnectionsTab'
import apiService from '../../services/api-service'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 132px)',
  },
  accordion: {
    width: '100%',
    marginBottom: 30,
  },
  tabsContainer: {
    flexGrow: 1,
    position: 'relative',
    marginLeft: -56,
    marginRight: -42,
    marginBottom: -10,
  },
}))

function ExternalConnections() {
  const classes = useStyles()
  const { setCurrentPage, language } = useAppContext()

  const [tabs, setTabs] = useState([])

  useEffect(() => setCurrentPage(t.externalConnections[language]))

  useEffect(() => {
    apiService
      .get('/virtual-matrix/partner-accounts')
      .then((res) => {
        let accounts = res.data
        // для того что бы owner был на первой вкладке
        const ownerId = apiService.getCredential().cin
        const owner = accounts.find((el) => el.id === ownerId)
        accounts = accounts.filter((el) => el.id !== ownerId)
        accounts = [owner, ...accounts]

        const tabs = accounts.map((el) => ({
          label: el.name,
          contentBody: <ConnectionsTab account={el.id} />,
        }))
        setTabs(tabs)
      })
      .catch(console.log)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <motion.div
        className={classes.root}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className={classes.accordion}>
          <Accordion></Accordion>
        </div>
        <div className={classes.tabsContainer}>
          <Tabs resizable data={tabs} />
        </div>
      </motion.div>
    </>
  )
}

export default ExternalConnections
