import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  field: {
    padding: '2px 7px 2px 7px',
    minHeight: '22px',
    borderRadius: '5px',
    backgroundColor: '#333F65',
    fontWeight: 500,
    lineHeight: '1.43',
    fontSize: 13,
  },
  between: {
    margin: '7px 0',
  },
  null: {
    color: 'transparent',
    userSelect: 'none',
  },
}))

const TableCellField = ({ children, ...props }) => {
  const classes = useStyles()

  if (children !== '' && children !== undefined) {
    return <div className={classes.field}>{children}</div>
  } else {
    return <div className={clsx(classes.field, classes.null)}>null</div>
  }
}

export default TableCellField
