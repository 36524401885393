const LockIcon = () => (
  <svg width='10px' height='13px' viewBox='0 0 10 13'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Matrix-/-Output-tab-+-icon-Copy-2' transform='translate(-123.000000, -22.000000)'>
        <g id='Group-3' transform='translate(120.000000, 7.000000)'>
          <g id='outline-perm_identity-24px' transform='translate(1.000000, 15.000000)'>
            <polygon id='Path' points='0 0 14 0 14 14 0 14' />
            <path
              d='M10.5,4.66666667 L9.91666667,4.66666667 L9.91666667,3.5 C9.91666667,1.89 8.61,0.583333333 7,0.583333333 C5.39,0.583333333 4.08333333,1.89 4.08333333,3.5 L4.08333333,4.66666667 L3.5,4.66666667 C2.85833333,4.66666667 2.33333333,5.19166667 2.33333333,5.83333333 L2.33333333,11.6666667 C2.33333333,12.3083333 2.85833333,12.8333333 3.5,12.8333333 L10.5,12.8333333 C11.1416667,12.8333333 11.6666667,12.3083333 11.6666667,11.6666667 L11.6666667,5.83333333 C11.6666667,5.19166667 11.1416667,4.66666667 10.5,4.66666667 Z M5.25,3.5 C5.25,2.53166667 6.03166667,1.75 7,1.75 C7.96833333,1.75 8.75,2.53166667 8.75,3.5 L8.75,4.66666667 L5.25,4.66666667 L5.25,3.5 Z M10.5,11.6666667 L3.5,11.6666667 L3.5,5.83333333 L10.5,5.83333333 L10.5,11.6666667 Z M7,9.91666667 C7.64166667,9.91666667 8.16666667,9.39166667 8.16666667,8.75 C8.16666667,8.10833333 7.64166667,7.58333333 7,7.58333333 C6.35833333,7.58333333 5.83333333,8.10833333 5.83333333,8.75 C5.83333333,9.39166667 6.35833333,9.91666667 7,9.91666667 Z'
              id='Shape'
              fill='rgba(255, 75, 117, 0.7)'
              fillRule='nonzero'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LockIcon
