import makeStyles from '@mui/styles/makeStyles'
import TableBody from '@mui/material/TableBody'

const useStyles = makeStyles((theme) => ({
  root: {
    '& tr:nth-child(odd)': {
      backgroundColor: theme.palette.background.dimmer,
    },
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.background.bleacher,
    },
  },
}))

const MyTableBodyCell = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <TableBody classes={{ root: classes.root }} {...props}>
      {children}
    </TableBody>
  )
}

export default MyTableBodyCell
