import makeStyles from '@mui/styles/makeStyles'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles(() => ({
  noItems: {
    display: 'grid',
    placeItems: 'center',
    flexGrow: 1,
    fontSize: 36,
    fontWeight: 500,
    color: 'rgba(255,255,255,0.2)',
  },
}))

const NoItemsMessage = ({ data, message, status }) => {
  const classes = useStyles()

  return !data.length && status === 'success' ? (
    <h4 className={classes.noItems}>{message}</h4>
  ) : !data.length && status === 'loading' ? (
    <div className={classes.noItems}>
      <CircularProgress color='primary' />
    </div>
  ) : null
}

export default NoItemsMessage
