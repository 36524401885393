import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import apiService from '../../../../services/api-service'
import RequestsTable from '../../../OutboundTransferRequests/RequestsTable'

const OutboundTransferRequestsTab = () => {
  const { contextId, inputId } = useParams()
  const [data, setData] = useState([])

  const getData = () => {
    apiService
      .get(`/virtual-matrix/transfer-requests/outbound?filter[input]=${inputId}`, contextId)
      .then((res) => {
        setData(res.data)
      })
      .catch(console.log)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <RequestsTable
      account={contextId}
      data={data}
      withoutPagination
      status={'success'}
      addBtnDis={true}
    />
  )
}

export default OutboundTransferRequestsTab
