import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from 'notistack'

import AppRouter from './AppRouter'
import AppThemeProvider from './AppThemeProvider/AppThemeProvider'
import AppContext from './AppContext'

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <AppThemeProvider>
        <AppContext>
          <CssBaseline />
          <AppRouter />
        </AppContext>
      </AppThemeProvider>
    </SnackbarProvider>
  )
}

export default App
