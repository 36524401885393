import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    '& > p': {
      lineHeight: '18px',
      fontWeight: 500,
      whiteSpace: 'nowrap,',
    },
  },
}))

const Dates = ({ upperDate, lowerDate }) => {
  const classes = useStyles()
  return upperDate || lowerDate ? (
    <div className={classes.root}>
      <p>{upperDate ? format(new Date(upperDate), 'dd/MM/yyyy HH:mm') : '-'}</p>
      <p>{lowerDate ? format(new Date(lowerDate), 'dd/MM/yyyy HH:mm') : '-'}</p>
    </div>
  ) : null
}

export default Dates
