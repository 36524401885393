import axios from 'axios'
import decode from 'jwt-decode'

const APP_ID = process.env.REACT_APP_ID
const APP_SECRET = process.env.REACT_APP_SECRET
const AUTH_URL = process.env.REACT_APP_AUTH_URL
const BASE_API_URL = process.env.REACT_APP_API
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI

class ApiService {
  constructor() {
    this.permitRefresh = true
    this.waitRefresh = null
    this.tokenData = JSON.parse(localStorage.getItem('tokenData'))
    this.credential = JSON.parse(localStorage.getItem('auth'))
  }

  getTokenData = () => this.tokenData

  _saveTokenData = (tokenData) => {
    localStorage.setItem('tokenData', JSON.stringify(tokenData))
  }

  _needRefresh = () => {
    return this.tokenData.exp < Date.now()
  }

  login = (code, remember = true) => {
    const data = `grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URI}`
    return axios
      .post(AUTH_URL + '/oauth/token', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: APP_ID,
          password: APP_SECRET,
        },
      })
      .then((response) => {
        const tokenData = response.data

        // console.log(tokenData)

        const decodeTokenData = decode(tokenData.access_token)

        // console.log(decodeTokenData)

        tokenData.user = decodeTokenData.usr
        tokenData.cid = decodeTokenData.cid
        tokenData.uid = decodeTokenData.uid
        tokenData.exp = Date.now() + tokenData.expires_in * 1000 - 5 * 60 * 1000
        // tokenData.remember = remember

        // if (!tokenData.roles.includes('ROLE_SERVICE')) {
        //   throw new Error('Access is denied')
        // }

        this.tokenData = tokenData
        // if (remember) {
        this._saveTokenData(tokenData)
        // }
        return tokenData
      })
  }

  _refresh = () => {
    if (!this.permitRefresh) return this.waitRefresh
    this.permitRefresh = false
    const data = `grant_type=refresh_token&refresh_token=${this.tokenData.refresh_token}`
    return (this.waitRefresh = axios
      .post(AUTH_URL + '/oauth/token', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: APP_ID,
          password: APP_SECRET,
        },
      })
      .then((response) => {
        const tokenData = response.data
        const decodeTokenData = decode(tokenData.access_token)
        tokenData.user = decodeTokenData.usr
        tokenData.roles = decodeTokenData.roles
        tokenData.exp = Date.now() + tokenData.expires_in * 1000 - 5 * 60 * 1000
        // tokenData.remember = this.tokenData.remember

        // if (!tokenData.roles.includes('ROLE_SERVICE')) {
        //   throw new Error('Access is denied')
        // }

        this.tokenData = tokenData
        // if (this.tokenData.remember) {
        this._saveTokenData(tokenData)
        // }
        console.log('Токен удачно обновлен!')
        this.permitRefresh = true
      })
      .catch((e) => {
        this.permitRefresh = true
        console.log('Ошибка обновления токена!', e)
        localStorage.clear()
        window.location.reload()
        throw Error(e)
      }))
  }

  verifyToken = () => {
    return axios.post(AUTH_URL + '/token/verify', { token: this.tokenData.access_token })
  }

  getMe = () => {
    if (this._needRefresh()) {
      return this._refresh().then(() =>
        axios.get(AUTH_URL + '/api/users/me', {
          headers: {
            Authorization: `Bearer ${this.tokenData.access_token}`,
          },
        }),
      )
    }
    return axios.get(AUTH_URL + '/api/users/me', {
      headers: {
        Authorization: `Bearer ${this.tokenData.access_token}`,
      },
    })
  }

  setCredential = (username, companyName, cin) => {
    this.credential = {
      username,
      companyName,
      cin,
    }
    localStorage.setItem('auth', JSON.stringify(this.credential))
  }

  getCredential = () => this.credential

  get = (url, contentAccount, source) => {
    if (this._needRefresh()) {
      return this._refresh().then(() =>
        axios.get(BASE_API_URL + url, {
          cancelToken: source?.token,
          headers: contentAccount
            ? {
                Authorization: `Bearer ${this.tokenData.access_token}`,
                'X-Account': this.credential?.cin,
                'X-Context-Account': contentAccount,
              }
            : {
                Authorization: `Bearer ${this.tokenData.access_token}`,
                'X-Account': this.credential?.cin,
              },
        }),
      )
      // .catch(function (thrown) {
      //     if (axios.isCancel(thrown)) {
      //         console.log('Request canceled', thrown.message)
      //     } else {
      //         throw thrown
      //     }
      // })
    }
    return axios.get(BASE_API_URL + url, {
      cancelToken: source?.token,
      headers: contentAccount
        ? {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
            'X-Context-Account': contentAccount,
          }
        : {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
          },
    })
    // .catch(function (thrown) {
    //     if (axios.isCancel(thrown)) {
    //         console.log('Request canceled', thrown.message)
    //     } else {
    //         throw thrown
    //     }
    // })
  }

  post = (url, data, contentAccount) => {
    return axios.post(BASE_API_URL + url, data, {
      headers: contentAccount
        ? {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
            'X-Context-Account': contentAccount,
          }
        : {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
          },
    })
  }

  put = (url, data, contentAccount) => {
    return axios.put(BASE_API_URL + url, data, {
      // headers: { Authorization: `Bearer ${this.tokenData.access_token}` },
      headers: contentAccount
        ? {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
            'X-Context-Account': contentAccount,
          }
        : {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
          },
    })
  }

  patch = (url, data, contentAccount) => {
    return axios.patch(BASE_API_URL + url, data, {
      headers: contentAccount
        ? {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
            'X-Context-Account': contentAccount,
          }
        : {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
          },
    })
  }

  delete = (url, contentAccount) => {
    return axios.delete(BASE_API_URL + url, {
      headers: contentAccount
        ? {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
            'X-Context-Account': contentAccount,
          }
        : {
            Authorization: `Bearer ${this.tokenData.access_token}`,
            'X-Account': this.credential?.cin,
          },
    })
  }
}
// eslint-disable-next-line
export default new ApiService()
