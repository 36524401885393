import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { format } from 'date-fns'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import Table from '../../components/Table/Table'
import TableBody from '../../components/Table/TableBody'
import TableHead from '../../components/Table/TableHead'
import TableCell from '../../components/Table/TableCell'
import Pagination from '../../components/Pagination/Pagination'
import useSorting from '../../services/sorting'
import { useAppContext } from '../../components/App/AppContext'
import AddBtn from '../../components/AddBtn/AddBtn'
import EditBtn from '../../components/ActionButtons/EditBtn/EditBtn'
import DeleteBtn from '../../components/ActionButtons/DeleteBtn/DeleteBtn'
import apiService from '../../services/api-service'
import NoItemsMessage from '../../components/NoItemsMessage/NoItemsMessage'
import usePagination from '../../services/usePagination'
import AddPreset from './AddPreset'
import Status from '../../components/Status/Status'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import { translations as t } from '../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 12,
    alignItems: 'center',
  },
  tableContainer: {
    height: 'calc(100% - 80px)',
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
}))

const PresetsTable = ({ account }) => {
  const classes = useStyles()
  const {
    language,
    openFullScreenModal,
    fullScreenModal: { buffer },
  } = useAppContext()
  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')

  const getPresets = (accountId, offset, limit) =>
    apiService.get(`/virtual-matrix/presets?page[limit]=${limit}&page[offset]=${offset}`, accountId)

  const { data, page, pageCount, refreshData, handleChangePage } = usePagination(
    getPresets,
    account,
  )

  const handleDeletePreset = (presetId) => {
    return apiService
      .delete(`/virtual-matrix/presets/${presetId}`, account)
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  const headCells = [
    [
      {
        id: 'name',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.name[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      { id: 'link', numeric: false, label: `${t.link[language]}` },
      { id: 'created', numeric: false, label: `${t.created[language]}` },
      { id: 'description', numeric: false, label: `${t.description[language]}` },
      {
        id: 'add',
        numeric: false,
        label: <AddBtn onClick={openFullScreenModal.bind(this, `addPreset${account}`, null)} />,
        props: {
          align: 'right',
          width: 200,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  return (
    <>
      <AddPreset accountId={account} onOk={refreshData} />
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveThePreset[language]}
        onOk={handleDeletePreset.bind(this, buffer?.id)}
        id={`deletePreset${account}`}
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.root} stickyHeader>
          <TableHead
            headCells={headCells}
            createSortHandler={createSortHandler}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row, i) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {/*<Link to={`/presets/${account}/${row.id}`}>{row.name}</Link>*/}
                    {row.name}
                  </TableCell>
                  <TableCell>{row.url}</TableCell>
                  <TableCell>{format(new Date(row.created_at), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    <div className={classes.buttonTdContainer}>
                      {!!row.active_connections && (
                        <Status
                          status={row.active_connections.toString()}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <EditBtn
                        onClick={openFullScreenModal.bind(this, `addPreset${account}`, row)}
                      />
                      <DeleteBtn
                        onClick={openFullScreenModal.bind(this, `deletePreset${account}`, row)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <NoItemsMessage data={data} message={t.noPresetsYet[language]} status={'success'} />
      </TableContainer>
      <Pagination count={pageCount} page={page} onChange={handleChangePage} />
    </>
  )
}

export default PresetsTable
