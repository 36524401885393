import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, TextField } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { DateTimePicker as DateTimePickerMui } from '@mui/x-date-pickers'
import clsx from 'clsx'
import { useField } from 'formik'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 0,
  },
  root: {
    marginBottom: '0',
    // marginTop: 16,
    // marginBottom: 8,
    //
    '& label.MuiFormLabel-root': {
      color: theme.palette.text.dark,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
    '& div': {
      '&:before': {
        borderColor: theme.palette.text.dark,
      },
      '&:after': {
        borderColor: theme.palette.text.dark,
      },
      '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
        '-webkit-text-fill-color': theme.palette.text.primary,
        '-webkit-box-shadow': '0 0 0px 1000px rgba(255, 255, 255, 0.05) inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
      //
    },
    '& svg': {
      fill: theme.palette.text.dark,
    },
    '& .Mui-error svg': {
      fill: theme.palette.error.main,
    },
    '& .MuiIconButton-root': {
      padding: 3,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  error: {
    '& .MuiInputBase-root.MuiInput-root': {
      '& .MuiInputAdornment-root': {
        '& button > svg > path': {
          fill: theme.palette.error.main,
        },
      },
    },
    '& input::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'red',
    },
    '& label.MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& div': {
      '&:before': {
        borderColor: theme.palette.error.main,
      },
      '&:after': {
        borderColor: theme.palette.error.main,
      },
      '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus': {
        '-webkit-text-fill-color': theme.palette.error.main,
        '-webkit-box-shadow': '0 0 0px 1000px rgba(255, 0, 0, 0.1) inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
  margin: {
    marginBottom: 8,
  },
  select: {
    backgroundColor: theme.palette.background.default,
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: `${theme.palette.background.default}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.text.dark,
    },
  },
}))
const sxStyles = {
  '& .MuiPaper-root': {
    backgroundColor: '#35436E',
  },
  '& .MuiPickersDay-root': {
    fontWeight: 700,
    border: '1px solid inherit',
    backgroundColor: '#35436E',
    '&:hover': {
      backgroundColor: 'rgba(101,101,207,0.51) !important',
    },
  },
  '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin': {
    backgroundColor: '#6565cf !important',
  },
  '& .PrivatePickersYear-yearButton.Mui-selected': {
    backgroundColor: '#6565cf !important',
  },
  '& .PrivatePickersYear-yearButton:hover': {
    backgroundColor: 'rgba(101,101,207,0.51) ',
  },
  '& .MuiButtonBase-root': {
    '& svg': {
      color: 'white',
    },
  },
  '& .MuiButtonBase-root:hover': {
    backgroundColor: 'rgba(101,101,207,0.51) !important',
  },
  '& .MuiIconButton-root': {
    '& svg': {
      color: 'white',
    },
  },
  '& .MuiIconButton-root:hover': {
    backgroundColor: 'rgba(101,101,207,0.51) !important',
  },
}

function DateTimePicker({ clean, ...props }) {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)
  const [show, setShow] = useState(false)

  const error = Boolean(meta.touched && meta.error)

  const handleDateChange = (date) => {
    helpers.setValue(date)
    helpers.setTouched(true)
  }

  const handleClear = () => helpers.setValue(null)

  return (
    // <div className={classes.formControl} style={props.style}>
    <FormControl
      margin='normal'
      variant='standard'
      error={error}
      onBlur={() => helpers.setTouched(true)}
      fullWidth={props.fullWidth}
      style={props.style}
      className={clsx(classes.formControl, props.className, error && classes.error)}
    >
      <DateTimePickerMui
        {...field}
        {...props}
        margin='normal'
        className={clsx(classes.root, props.error && classes.error, props.className)}
        onChange={handleDateChange}
        error={error}
        fullWidth
        variant='inline'
        ampm={false}
        PopperProps={{
          sx: { ...sxStyles },
        }}
        renderInput={(propsInput) => (
          <TextField
            {...propsInput}
            inputProps={{
              ...propsInput.inputProps,
              placeholder: '__.___.____ __:__',
            }}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            type='search'
            variant='standard'
            InputLabelProps={{
              required: props.required,
            }}
            // helperText={error ? 'meta.error' : ' '}
            fullWidth
            className={clsx(classes.root, propsInput.error && classes.error, propsInput.className)}
          />
        )}
        InputProps={
          clean
            ? {
                startAdornment: (
                  <IconButton
                    onClick={handleClear}
                    disabled={!field.value}
                    style={{
                      order: 1,
                      padding: '6px 3px 6px 8px',
                      width: '5px',
                      height: '5px',
                      marginRight: '4px',
                      marginBottom: '1px',
                    }}
                    size='large'
                  >
                    {field.value && show && <ClearIcon color='disabled' fontSize='small' />}
                  </IconButton>
                ),
              }
            : null
        }
        InputAdornmentProps={
          clean
            ? {
                position: 'end',
                style: { order: 2, marginLeft: 0, paddingRight: '6px' },
              }
            : null
        }
        InputLabelProps={clean ? { shrink: Boolean(field.value) } : null}
      />
      <FormHelperText error>
        {error ? meta.error /* || props.labelError*/ || ' ' : ' '}
      </FormHelperText>
    </FormControl>
  )
}

export default DateTimePicker
