import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    color: '#fff',
    // margin: spacing(2, 0, 2),
    // marginLeft: spacing(3),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    '& .MuiButton-label': {
      fontWeight: 500,
    },
    // '&.MuiButton-root.Mui-disabled': {
    // 	backgroundColor: darken(palette.secondary.main, 0.1),
    // },
  },
}))

const SubmitButton = (props) => {
  const classes = useStyles()
  return (
    <Button
      variant='contained'
      color='secondary'
      type='submit'
      data-cy='submitForm'
      className={classes.root}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default SubmitButton
