const HDIcon = () => (
  <svg width='15px' height='11px' viewBox='0 0 15 11'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <rect id='Rectangle' stroke='#FFFFFF' x='0.5' y='0.5' width='14' height='9.8' rx='3.6' />
      <path
        d='M4.09741211,7.7 L4.09741211,5.48857422 L6.32250977,5.48857422 L6.32250977,7.7 L7.18383789,7.7 L7.18383789,2.7234375 L6.32250977,2.7234375 L6.32250977,4.79472656 L4.09741211,4.79472656 L4.09741211,2.7234375 L3.23266602,2.7234375 L3.23266602,7.7 L4.09741211,7.7 Z M9.63793945,7.7 C10.0868327,7.7 10.485026,7.60315755 10.8325195,7.40947266 C11.180013,7.21578776 11.4477539,6.94007161 11.6357422,6.58232422 C11.8237305,6.22457682 11.9177246,5.80986328 11.9177246,5.33818359 L11.9177246,5.33818359 L11.9177246,5.08867187 C11.9177246,4.62382812 11.8243001,4.21139323 11.6374512,3.85136719 C11.4506022,3.49134115 11.186849,3.21334635 10.8461914,3.01738281 C10.5055339,2.82141927 10.1153158,2.7234375 9.67553711,2.7234375 L9.67553711,2.7234375 L8.20581055,2.7234375 L8.20581055,7.7 L9.63793945,7.7 Z M9.63452148,7.00957031 L9.07055664,7.00957031 L9.07055664,3.42070312 L9.67553711,3.42070312 C10.1221517,3.42070312 10.4628092,3.56311849 10.6975098,3.84794922 C10.9322103,4.13277995 11.0495605,4.54521484 11.0495605,5.08525391 L11.0495605,5.08525391 L11.0495605,5.36210937 C11.0450033,5.89303385 10.9213867,6.3003418 10.6787109,6.5840332 C10.4360352,6.86772461 10.087972,7.00957031 9.63452148,7.00957031 L9.63452148,7.00957031 Z'
        id='HD'
        fill='#FFFFFF'
        fillRule='nonzero'
      />
    </g>
  </svg>
)

export default HDIcon
