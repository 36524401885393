import { useState } from 'react'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

import { useAppContext } from '../App/AppContext'
import { translations as t } from '../../services/translations'

function ConfirmationDialogRaw(props) {
  const { onClose, open, onOk, title, description, successMsg, ...other } = props
  const { enqueueSnackbar } = useSnackbar()
  const [disabled /*, setDisabled */] = useState(false)
  const { openFullScreenLoader, closeFullScreenLoader, language } = useAppContext()

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    // setDisabled(true)
    onClose()
    openFullScreenLoader()
    onOk()
      .then(() => {
        // setDisabled(false)
        // onClose()
        enqueueSnackbar(successMsg || 'SUCCESS', {
          variant: 'success',
        })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err.response?.data.error || err.message, {
          variant: 'error',
        })
      })
      .finally(() => closeFullScreenLoader())
  }

  return (
    <Dialog
      // disableBackdropClick
      // disableEscapeKeyDown
      onEscapeKeyDown={handleCancel}
      maxWidth='xs'
      // onEntering={() => console.log('onEntering!')}
      aria-labelledby='confirmation-dialog-title'
      open={open}
      {...other}
    >
      <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          // autoFocus
          onClick={handleCancel}
          color='primary'
          style={{ border: '1px solid rgba(255,255,255,0.1)', paddingLeft: 20, paddingRight: 20 }}
        >
          {t.cancel[language]}
        </Button>
        <Button
          disabled={disabled}
          onClick={handleOk}
          color='secondary'
          style={{ border: '1px solid rgba(255,255,255,0.1)', paddingLeft: 20, paddingRight: 20 }}
        >
          {t.ok[language]}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
    backgroundColor: theme.palette.background.default,
  },
}))

export default function ConfirmationDialog({
  onOk,
  id,
  title,
  description,
  optionalCallbackOnHandleClose = null,
  successMsg,
}) {
  const classes = useStyles()
  const { fullScreenModal, closeFullScreenModal } = useAppContext()

  const handleClose = () => {
    closeFullScreenModal()

    if (optionalCallbackOnHandleClose) {
      optionalCallbackOnHandleClose()
    }
  }

  return (
    <ConfirmationDialogRaw
      classes={{
        paper: classes.paper,
      }}
      id='delete-connection'
      // keepMounted
      open={fullScreenModal.open && fullScreenModal.id === id}
      onClose={handleClose}
      onOk={onOk}
      title={title}
      description={description}
      successMsg={successMsg}
    />
  )
}
