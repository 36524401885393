import IconButton from '@mui/material/IconButton'
import TaskAltIcon from '@mui/icons-material/CheckCircleOutline'

function AcceptBtn(props) {
  return (
    <IconButton color='inherit' {...props} size='large'>
      <TaskAltIcon />
    </IconButton>
  )
}

export default AcceptBtn
