import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '10px 26px',
  },
}))

function CardBody({ children, ...props }) {
  const classes = useStyles()

  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  )
}

export default CardBody
