import makeStyles from '@mui/styles/makeStyles'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    '& .MuiTableCell-root,.MuiTableSortLabel-root': {
      height: 60,
      fontSize: 14,
      fontWeight: 500,
      border: 'none',
      '&:first-of-type': {
        paddingLeft: 30,
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const MyTableHead = ({ headCells, order, orderBy, createSortHandler }) => {
  const classes = useStyles()

  return (
    <TableHead classes={{ root: classes.root }}>
      {headCells.map((row, index) => (
        <TableRow key={index}>
          {row.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              {...row.props}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.sort ? (
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={orderBy === row.id ? order : 'asc'}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                  {orderBy === row.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                row.label
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  )
}

export default MyTableHead
