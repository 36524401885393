import { useState } from 'react'
import { useField } from 'formik'
import FormControl from '@mui/material/FormControl'
import { Autocomplete as AutocompleteMui } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { translations as t } from '../services/translations'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '16px',
    marginBottom: '31px',
  },
  autocomplete: {
    '& .MuiAutocomplete-input': {
      // padding: '10px !important',
    },
    '& svg': {
      fill: theme.palette.text.dark,
    },
    '& label': {
      color: theme.palette.text.dark,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.dark,
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderColor: theme.palette.text.dark,
      },
      '&:hover:not(.Mui-disabled):before, &:after': {
        borderColor: theme.palette.text.dark,
      },
    },
  },
  chip: {
    display: 'none !important',
    // position: 'relative',
    top: '37px',
    fontSize: '0.7rem',
    // display: 'flex',
    // alignItems: 'center',
    marginTop: '2px',
    marginBottom: '2px',
    listStyle: 'none',
    // '&.item--withLogo': {
    //     width: '100%',
    //     fontSize: 18,
    //     '& .item__name': {
    //         fontWeight: 500,
    //     },
    // },
    // '&.item--withLogo:first-child': {
    //   marginTop: 0,
    // },
    '&:not(.item--withLogo)': {
      marginRight: '2px',
      // padding: '5px 6px 5px 12px',
      borderRadius: '5px',
      border: 'solid 2px #6565cf',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      '& .MuiChip-label': {
        paddingLeft: '5px',
        paddingRight: '2px',
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '15px',
        opacity: '0.6',
      },
    },
    // '& .item__icon': {
    //     marginRight: '15px',
    // },
    // '& .item__name': {
    //     marginRight: '4px',
    // },
    '& .MuiChip-deleteIcon': {
      marginLeft: 'auto',
      fontSize: '18px',
      cursor: 'pointer',
    },
  },
  paper: {
    '& .MuiListSubheader-root': {
      backgroundColor: '#364473',
    },
    backgroundColor: theme.palette.background.default,
    maxHeight: 650,
    '& [aria-selected="true"]': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: `${theme.palette.primary.mainLight} !important`,
    },
    '& .MuiListItem-root': {
      padding: (props) => props.padding || '5px 10px',
      // padding: '0px',
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    '&::-webkit-scrollbar': {
      // width: '0.75em',
      // height: '0.75em',
      width: '8px',
      height: '26px',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // width
    '& *::-webkit-scrollbar': {
      width: '8px',
      height: '26px',
      // backgroundColor: '#34426C',
      backgroundColor: 'transparent',
    },
    // Track
    '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
      // box-shadow: inset 0 0 5px grey
      width: '8px',
      height: '26px',
      borderRadius: '5px',
      // backgroundColor: '#34426C',
      backgroundColor: 'inherit',
    },
    // Handle
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      // backgroundColor: '#6565cf',
      width: '8px',
      height: '26px',
      borderRadius: '5px',
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
    },
  },
  clearIndicator: {
    marginRight: '-10px',
  },
}))

const Autocomplete = (props) => {
  const classes = useStyles(props)
  const [field, meta, helpers] = useField(props)
  const error = Boolean(meta.touched && meta.error)
  const [inputValue, setInputValue] = useState('')

  const onChangeHandler = (e, v) => helpers.setValue(v.map((item) => (item.id ? item.id : item)))

  return (
    <FormControl variant='standard' error={error} fullWidth={true} className={classes.formControl}>
      <AutocompleteMui
        value={field.value}
        onChange={props.onChange || onChangeHandler}
        options={props.options}
        inputValue={inputValue}
        onInputChange={(e, v, r) => r === 'input' && setInputValue(v)}
        filterOptions={createFilterOptions({
          matchFrom: 'any',
          stringify: (option) => option.name || option.value,
        })}
        getOptionLabel={(option) => (Array.isArray(option) ? option[0] : option.id)}
        isOptionEqualToValue={(option, value) => option.id === value.id || option.id === value}
        noOptionsText={
          props.noOptionsText || props.language ? t.noDataFound[props.language] : 'Data not found'
        }
        multiple
        className={clsx(classes.autocomplete)}
        classes={{
          paper: classes.paper,
          tag: classes.chip,
          clearIndicator: classes.clearIndicator,
        }}
        fullWidth={props.fullWidth}
        id={`${props.name}-id`}
        disableCloseOnSelect={true}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={Math.random()}>
            {option.label}
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name={field.name}
            onBlur={() => setInputValue('')}
            label={props.label}
            placeholder={
              props.placeholder || props.language
                ? t.clickToSelect[props.language]
                : 'Click to select ...'
            }
            variant='standard'
          />
        )}
      />
    </FormControl>
  )
}

export default Autocomplete
