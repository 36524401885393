import { useContext, useState, createContext, useRef } from 'react'
import DashboardContent from './DashboardContent/DashboardContent'

const DashboardContext = createContext()

export default function Dashboard() {
  const coords = useRef({})
  const [nodes, setNodes] = useState([])
  const [relations, setRelations] = useState([])
  const [refresh, setRefresh] = useState(false)

  const doRefresh = () => setRefresh((refresh) => !refresh)

  return (
    <DashboardContext.Provider
      value={{
        nodes,
        setNodes,
        coords,
        relations,
        setRelations,
        refresh,
        doRefresh,
      }}
    >
      <DashboardContent />
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => useContext(DashboardContext)
