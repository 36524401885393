import { useState } from 'react'

const useSorting = (field) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(field)

  const descendingComparator = (a, b, orderBy) => {
    const _a = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy]
    const _b = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy]
    if (_b < _a) {
      return -1
    }
    if (_b > _a) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  return {
    order,
    orderBy,
    stableSort,
    getComparator,
    createSortHandler,
  }
}

export default useSorting
