import { Form, Formik } from 'formik'
import { array, mixed, object, string } from 'yup'

import makeStyles from '@mui/styles/makeStyles'
import Select from '../../../forms/Select'
import Switcher from '../../../forms/Switcher'
import TextField from '../../../forms/TextField'
import SelectList from '../../../forms/SelectList'
import CheckBox from '../../../forms/CheckBox'
import SubmitButton from '../../../forms/SubmitButton'
import { useAppContext } from '../../../components/App/AppContext'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  buttonContainer: {
    position: 'absolute',
    right: 30,
    bottom: -40,
    textAlign: 'right',
  },
}))

const RequestForm = ({ addFormData, isSubmitting, recipients }) => {
  const classes = useStyles()
  const { language } = useAppContext()

  const initialValues = {
    title: '',
    description: '',
    isConfirmation: true,
    isAll: true,
    recipients: [],
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        addFormData(values)
      }}
      validationSchema={object({
        title: string().required(t.fieldIsRequired[language]),
        recipients: mixed().when('isAll', {
          is: false,
          then: array().min(1, t.fieldIsRequired[language]),
        }),
      })}
    >
      {({ values }) => (
        <Form noValidate>
          <TextField label={t.title[language]} name='title' required />
          <TextField multiline label={t.description[language]} name='description' />
          <CheckBox name='isConfirmation' label={t.confirmationRequired[language]} />
          <Switcher
            label={t.recipients[language]}
            upperTitle={t.all[language]}
            lowerTitle={t.selected[language]}
            name='isAll'
          />
          <Select
            label={values.isAll ? t.excludedRecipients[language] : t.selectedRecipients[language]}
            name='recipients'
            required={!values.isAll}
            fullWidth
            multiple
          >
            {recipients.map((el) => ({
              value: el.id,
              label: el.name,
            }))}
          </Select>
          <SelectList name='recipients' withoutIcon listOfValues={recipients} />
          <div className={classes.buttonContainer}>
            <SubmitButton disabled={isSubmitting}>{t.confirm[language]}</SubmitButton>
          </div>
          {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
          {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
        </Form>
      )}
    </Formik>
  )
}

export default RequestForm
