import IconButton from '@mui/material/IconButton'
import UnlockIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/LockOutlined'

function LockBtn({ isLocked, ...props }) {
  return (
    <IconButton color='inherit' {...props} size='large'>
      {isLocked ? <LockIcon /> : <UnlockIcon style={{ color: 'rgba(255,255,255, 0.2' }} />}
    </IconButton>
  )
}

export default LockBtn
