import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckboxMui from '@mui/material/Checkbox'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
    '&>span.MuiFormControlLabel-label': {
      marginTop: '10px',
      fontSize: 14,
      // color: 'rgba(255,255,255,.6)',
    },
    '& .MuiCheckbox-root': {
      // color: 'red',
    },
  },
}))

const SimpleCheckBox = ({ className, style, ...props }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      className={clsx(classes.root, className)}
      control={<CheckboxMui color='primary' {...props} />}
      label={props.label}
      style={style}
    >
      <FormHelperText error style={{ marginTop: -8 }}>
        {props.error || ' '}
      </FormHelperText>
    </FormControlLabel>
  )
}

export default SimpleCheckBox
