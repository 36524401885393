import usePagination from '../../services/usePagination'
import ConnectionsTable from './ConnectionsTable'
import apiService from '../../services/api-service'

const ConnectionsTab = ({ account }) => {
  const getInternalConnections = (accountId, offset, limit) =>
    apiService.get(
      `/virtual-matrix/internal-connections?page[limit]=${limit}&page[offset]=${offset}`,
      accountId,
    )

  const { status, data, page, pageCount, refreshData, handleChangePage } = usePagination(
    getInternalConnections,
    account,
  )

  return (
    <ConnectionsTable
      account={account}
      data={data}
      page={page}
      pageCount={pageCount}
      handleChangePage={handleChangePage}
      refreshData={refreshData}
      status={status}
    />
  )
}

export default ConnectionsTab
