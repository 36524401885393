import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ConnectionsTable from '../../../InternalConnections/ConnectionsTable'
import apiService from '../../../../services/api-service'

const InternalConnectionsTab = () => {
  const { contextId, transferID } = useParams()
  const [data, setData] = useState([])

  const getData = () => {
    apiService
      .get(`/virtual-matrix/internal-connections?filter[input]=${transferID}`, contextId)
      .then((res) => {
        setData(res.data)
      })
      .catch(console.log)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return <ConnectionsTable account={contextId} data={data} withoutPagination status={'success'} />
}

export default InternalConnectionsTab
