import { useEffect } from 'react'
import Dashboard from '../../components/Dashboard/Dashboard'
import { useAppContext } from '../../components/App/AppContext'
import { translations as t } from '../../services/translations'

function DashboardView() {
  const { setCurrentPage, language } = useAppContext()

  useEffect(() => {
    setCurrentPage(t.dashboard[language])
    // eslint-disable-next-line
  })

  return <Dashboard />
}

export default DashboardView
