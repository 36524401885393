import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import theme from './app-theme'

const AppThemeProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

export default AppThemeProvider
