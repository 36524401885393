import makeStyles from '@mui/styles/makeStyles'
import { useField } from 'formik'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'

const useStyles = makeStyles((theme) => ({
  // Switch
  root: {
    margin: '8px 0 14px -20px',
    display: 'flex',
    alignItems: 'center',
  },
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#fff',
    },
    '&$checked + $track': {
      backgroundColor: '#fff',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#fff',
  },
  switchRoot: {
    transform: 'rotate(-90deg)',
  },
  bottomMargin: {
    marginBottom: 4,
  },
}))

const Switcher = ({ label, upperTitle, lowerTitle, ...props }) => {
  const classes = useStyles()

  const [field] = useField({
    ...props,
    name: props.name,
    type: 'checkbox',
    value: props.value,
  })

  return (
    <>
      <Typography variant='h6' style={{ fontSize: 18, marginTop: 24, textAlign: 'left' }}>
        {label}
      </Typography>
      <div className={classes.root}>
        <Switch
          {...props}
          {...field}
          checked={field.value}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          classes={{
            root: classes.switchRoot,
            switchBase: classes.switchBase,
            checked: classes.checked,
            track: classes.track,
          }}
        />
        <div style={{ marginLeft: '-10px' }}>
          <Typography align='left' variant='subtitle2' style={{ fontWeight: 'normal' }}>
            {upperTitle}
          </Typography>
          <Typography align='right' variant='subtitle2' style={{ fontWeight: 'normal' }}>
            {lowerTitle}
          </Typography>
        </div>
      </div>
    </>
  )
}

export default Switcher
