import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 900,
    borderRadius: '16px 16px 0 0',
    // overflow: 'hidden',
  },
}))

const MyTable = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Table classes={{ root: classes.root }} {...props}>
      {children}
    </Table>
  )
}

export default MyTable
