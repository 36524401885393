import { Tooltip, IconButton } from '@mui/material'

function UniversalBtn(props) {
  return (
    <Tooltip title={props.alt || "Don't press this button, please!"} arrow>
      <IconButton color='inherit' {...props} size='large'>
        <props.icon />
      </IconButton>
    </Tooltip>
  )
}

export default UniversalBtn
