import { format } from 'date-fns'
import './LinePopper.css'
import { useAppContext } from '../../App/AppContext'
import { translations as t } from '../../../services/translations'

function LinePopper({ show, content }) {
  const { language } = useAppContext()

  return show ? (
    <div className='LinePopper__root' style={{ top: content.y + 5, left: content.x + 8 }}>
      <div className='LinePopper__panel left'>
        <p className='title'>{t.from[language]}</p>
        <p>{format(new Date(content.from), 'HH:mm')}</p>
        <p>{format(new Date(content.from), 'dd.MM.yyyy')}</p>
      </div>
      <div className='LinePopper__panel right'>
        <p className='title'>{t.till[language]}</p>
        <p>{content.till ? format(new Date(content.till), 'HH:mm') : null}</p>
        <p>{content.till ? format(new Date(content.till), 'dd.MM.yyyy') : null}</p>
      </div>
    </div>
  ) : null
}

export default LinePopper
