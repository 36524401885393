import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const DragAndDrop = ({ onDragEnd, children, ...rest }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='column-1988-2021'>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...rest}
            style={{ top: 0, left: 0 }}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragAndDrop
