import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    height: 50,
    padding: '0 26px',
    borderBottom: '1px solid #222a4c',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    '& div': {
      marginLeft: 10,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
  },
}))

function CardTitle({ title, startActionButton, children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>{title}</p>
        {startActionButton}
      </div>
      {children}
    </div>
  )
}

export default CardTitle
