import makeStyles from '@mui/styles/makeStyles'
import Pagination from '@mui/material/Pagination'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 48,
  },
  root: {
    '& .MuiPagination-ul li:first-child  .MuiPaginationItem-root': {
      width: 65,
    },
    '& .MuiPagination-ul li:last-child  .MuiPaginationItem-root': {
      width: 65,
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: '#4770bc',
    },
    '& .MuiPaginationItem-root': {
      backgroundColor: 'rgba(250, 251, 252, 0.1)',
      fontSize: 10,
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: '#4770bc',
    },
    '& .MuiPaginationItem-ellipsis': {
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

function MyPagination(props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Pagination classes={{ root: classes.root }} {...props} />
    </div>
  )
}

export default MyPagination
