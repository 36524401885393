import SensorsOffIcon from '@mui/icons-material/SensorsOff'
import SensorsIcon from '@mui/icons-material/Sensors'
import { translations as t } from '../../../services/translations'
import UniversalBtn from '../../ActionButtons/UniversalBtn'
import { useAppContext } from '../../App/AppContext'
import './DashboardHeading.css'

const DashboardHeading = ({ nodes }) => {
  const { language, openFullScreenModal } = useAppContext()

  const qtyOutputs = nodes.filter(
    (el) => el.group === 'outputs' && !el.is_locked && el.status === 'online',
  ).length
  const qtyCons = nodes.filter((el) => el.connection && !el.is_locked).length

  return (
    <div className='DashboardContent__heading'>
      <div className='DashboardContent__blank' />
      <p className='DashboardContent__columnTitle'>{t.externalOutputs[language]}</p>
      <p className='DashboardContent__columnTitle'>
        {t.inputs[language]}
        <UniversalBtn
          onClick={() => openFullScreenModal('addConnection')}
          icon={SensorsIcon}
          alt={t.createConnection[language]}
          sx={{ color: 'rgba(98, 198, 115, 0.7)' }}
          disabled={qtyOutputs === 0}
        />
      </p>
      <p className='DashboardContent__columnTitle'>
        {t.outputs[language]}
        <UniversalBtn
          onClick={() => openFullScreenModal('closeConnections')}
          icon={SensorsOffIcon}
          alt={t.closeConnections[language]}
          sx={{ color: 'rgba(255, 75, 117, 0.7)' }}
          disabled={qtyCons === 0}
        />
      </p>
      <p className='DashboardContent__columnTitle'>{t.externalInputs[language]}</p>
    </div>
  )
}

export default DashboardHeading
