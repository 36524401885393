import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'
import { TableContainer } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import Table from '../../../components/Table/Table'
import TableBody from '../../../components/Table/TableBody'
import TableHead from '../../../components/Table/TableHead'
import TableCell from '../../../components/Table/TableCell'
import TableCellField from '../../../components/Table/TableCellField'
import Pagination from '../../../components/Pagination/Pagination'
import useSorting from '../../../services/sorting'
import NoItemsMessage from '../../../components/NoItemsMessage/NoItemsMessage'
import { useAppContext } from '../../../components/App/AppContext'
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog'
import usePagination from '../../../services/usePagination'
import apiService from '../../../services/api-service'
import ClearBtn from '../../../components/ActionButtons/ClearBtn/ClearBtn'
import Status from '../../../components/Status/Status'
import { capitalizeFirstLetter } from '../../../services/helpers'
import { translations as t } from '../../../services/translations'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#303B5F',
    },
    '& .doubleLine > p': {
      lineHeight: '18px',
      fontWeight: 500,
      whiteSpace: 'nowrap,',
    },
    '& .doubleLineWider': {
      minWidth: '80px',
    },
  },
  buttonTdContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  tableContainer: {
    height: 'calc(100% - 80px)',
    paddingRight: 36,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  borderRadiusCell: {
    backgroundColor: '#303B5F',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 30,
    fontWeight: 500,
  },
  borderRadiusCellLeft: {
    borderTopLeftRadius: 16,
  },
  borderRadiusCellRight: {
    borderTopRightRadius: 16,
  },
  addBtn: {
    '& > button': {
      height: 32,
      width: 32,
      minWidth: 32,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      marginRight: 12,
    },
    justifyContent: 'flex-end',
    paddingRight: 30,
  },
  subCell: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(101, 101, 207, 0.3)',
    display: 'grid',
    placeItems: 'center',
  },
  blankTop: {
    backgroundColor: 'rgba(255,255,255,0.3)',
    minWidth: '15px',
    width: '100%',
    height: '15px',
    marginBottom: '5px',
    opacity: '0.1',
    borderRadius: '5px',
  },
  blankBottom: {
    backgroundColor: 'rgba(255,255,255,0.3)',
    minWidth: '15px',
    width: '100%',
    height: '15px',
    marginTop: '5px',
    opacity: '0.1',
    borderRadius: '5px',
  },
  silverLine: {
    margin: '1px 0',
    backgroundColor: 'rgba(255,255,255,0.2)',
    height: '1px',
    opacity: '0.5',
    borderRadius: '5px',
  },
  doubleField: {
    minWidth: '140px',
    '& > div:nth-child(1)': {
      marginBottom: '5px',
    },
  },
  doubleFieldSmall: {
    minWidth: '70px',
    '& > div:nth-child(1)': {
      marginBottom: '5px',
    },
  },
}))

const ExternalInputsTable = ({ account }) => {
  const classes = useStyles()

  const {
    language,
    fullScreenModal: { buffer },
  } = useAppContext()

  const { order, orderBy, getComparator, createSortHandler, stableSort } = useSorting('')

  const getExternalInputs = (accountId, offset, limit) =>
    apiService.get(
      `/virtual-matrix/external-inputs?page[limit]=${limit}&page[offset]=${offset}`,
      accountId,
    )

  const { status, data, page, pageCount, refreshData, handleChangePage } = usePagination(
    getExternalInputs,
    account,
  )

  const handleDeleteRequest = (requestId) => {
    return apiService
      .delete(`/virtual-matrix/transfer-requests/${requestId}`)
      .then((res) => {
        refreshData()
      })
      .catch(console.log)
  }

  const inputOwner = (
    <div className='doubleLine doubleLineWider'>
      <p>{t.input[language]}</p>
      <p>{t.owner[language]}</p>
    </div>
  )

  const validFromValidTill = (
    <div className='doubleLine doubleLineWider'>
      <p>{t.validFrom[language]}</p>
      <p>{t.validTill[language]}</p>
    </div>
  )

  const locationAddress = (
    <div className='doubleLine doubleLineWider'>
      <p>{t.location[language]}</p>
      <p>{t.address[language]}</p>
    </div>
  )

  const headCells = [
    [
      {
        id: 'name',
        numeric: false,
        label: (
          <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellLeft)}>
            {t.name[language]}
          </span>
        ),
        props: { style: { padding: 0, backgroundColor: '#29355A' } },
      },
      { id: 'request', numeric: false, label: `${t.request[language]}` },
      { id: 'inputOwner', numeric: false, label: inputOwner },
      { id: 'validFromValidTill', numeric: false, label: validFromValidTill },
      { id: 'type', numeric: false, label: `${t.type[language]}` },
      { id: 'device', numeric: false, label: `${t.device[language]}` },
      { id: 'formatType', numeric: false, label: `${t.formatType[language]}` },
      { id: 'locationAddress', numeric: false, label: locationAddress },
      {
        id: 'buttons',
        numeric: false,
        label: <span className={clsx(classes.borderRadiusCell, classes.borderRadiusCellRight)} />,
        props: {
          width: 140,
          style: { padding: 0, backgroundColor: '#29355A' },
        },
      },
    ],
  ]

  return (
    <>
      <ConfirmationDialog
        title={t.warning[language]}
        description={t.areYouSureYouWantToRemoveTheRequest[language]}
        onOk={handleDeleteRequest.bind(this, buffer?.id)}
        id={`deleteTransferRequest${account}`}
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.root} stickyHeader>
          <TableHead
            headCells={headCells}
            createSortHandler={createSortHandler}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row) => {
              const connected = row.input.connections?.filter((el) => el.status === 'connected')
                .length
              return (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    <Link to={`/external-inputs/${account}/${row.id}`}>
                      {row.transfer_request_event?.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row?.transfer_request?.title}</TableCell>
                  <TableCell>
                    <div className={classes.doubleField}>
                      <TableCellField>{row.input?.name}</TableCellField>
                      <TableCellField>{row.owner?.name}</TableCellField>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.doubleFieldSmall}>
                      <TableCellField>
                        {row.start_time ? format(new Date(row.start_time), 'dd/MM/yyyy HH:mm') : ''}
                      </TableCellField>
                      <TableCellField>
                        {row.end_time ? format(new Date(row.end_time), 'dd/MM/yyyy HH:mm') : ''}
                      </TableCellField>
                    </div>
                  </TableCell>
                  <TableCell>{capitalizeFirstLetter(row?.input?.input_type)}</TableCell>
                  <TableCell>{capitalizeFirstLetter(row.input?.type)}</TableCell>
                  <TableCell>{row?.input?.stream_type}</TableCell>
                  <TableCell>
                    <div className={classes.doubleField}>
                      <TableCellField>{row?.input?.location?.name}</TableCellField>
                      <TableCellField>{row?.input?.address}</TableCellField>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.buttonTdContainer}>
                      {!!connected && (
                        <Status status={connected.toString()} style={{ marginRight: 10 }} />
                      )}
                      <ClearBtn />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <NoItemsMessage data={data} message={t.noExternalInputsYet[language]} status={status} />
      </TableContainer>
      <Pagination count={pageCount} page={page} onChange={handleChangePage} />
    </>
  )
}

export default ExternalInputsTable
