import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    '& p': {
      padding: '4px 0',
      fontSize: 16,
    },
  },
  label: {
    flexGrow: 1,
    color: 'rgba(255, 255, 255, 0.6)',
  },
}))

function CardItem({ label, value, vertical }) {
  const classes = useStyles()

  return (
    <div className={classes.root} style={vertical && { flexDirection: 'column' }}>
      <p className={classes.label} style={vertical && { paddingBottom: 0 }}>
        {label}
      </p>
      <p className={classes.value} style={vertical && { paddingBottom: 0 }}>
        {value}
      </p>
    </div>
  )
}

export default CardItem
